import { ObjectMapper } from "json-object-mapper";
import { IModel } from "./IModel";
import { Model } from "./model";

export class FeatureFlagModel extends Model implements IModel<FeatureFlagModel> {
  showCardPeople: boolean = false;
  jobTitleManagement: boolean = false;
  hourlyBasisLimitDate: boolean = false;

  static create(input: any): FeatureFlagModel {
    return ObjectMapper.deserialize<FeatureFlagModel>(FeatureFlagModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  public hasEnabled(flagName: string): boolean {
    return flagName in this && (this as any)[flagName] === true;
  }  
}

import { DocumentTypeModel } from './document-type.model';
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './../IModel';
import { Model } from './../model';

export class StandardDocumentModel
  extends Model
  implements IModel<StandardDocumentModel>
{
  companyGroupId: string | undefined = undefined;
  documentTypeId: string | undefined = undefined;
  required: boolean = true;
  forDependents: boolean = false;
  sideType: number = 1 | 2 | 3;
  twoSides: boolean = false;
  order: number = 99;
  documentType: DocumentTypeModel = new DocumentTypeModel();

  static create(input: any): StandardDocumentModel {
    return ObjectMapper.deserialize<StandardDocumentModel>(
      StandardDocumentModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { UserModel } from "services/models/corporate/user.model";

@Injectable()
export class UserService extends ServiceBase<UserModel> {
  constructor(http: HttpService) {
    super("user", http, environment.apiUrl);
  }

  createEntity(input: any): UserModel {
    return UserModel.create(input);
  }
}

import { BaseEnum } from "./baseEnum"

const listWorkRegimeEnum: Array<WorkRegimeType> = [];

export class WorkRegimeType extends BaseEnum {
  static CLT = new WorkRegimeType(1, 'CLT', 'CLT', 'success');
  static PJ = new WorkRegimeType(2, 'PJ', 'PJ', 'info');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listWorkRegimeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listWorkRegimeEnum;
  }
}
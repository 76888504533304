import { DomainService } from "./../../../../services/domain.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { AuthService } from "services/auth.service";
import { UserRoleType } from "services/enums/userRoleType.enum";

interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: "app-navbar-notification",
  templateUrl: "./navbar-notification.component.html",
})
export class NavbarNotificationComponent implements OnInit {
  public notifications: notification;

  constructor(
    private _notificationsService: NotificationsService,
    private _router: Router,
    private _domainService: DomainService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    if (this.canViewNotification()) {
      this._notificationsService.onApiDataChange.subscribe((res) => {
        this.notifications = res;
      });
    }
  }

  canViewNotification(): boolean {
    return this._authService.userContainsRoles(
      UserRoleType.company_adm.value,
      UserRoleType.company_group_adm.value,
      UserRoleType.personnel_department.value,
      UserRoleType.human_resources.value,
      UserRoleType.work_safety.value,
    );
  }

  notiticationAction(not: any) {
    this._router.navigate(
      [`/${this._domainService.getDomain().domainName}/hiring/applicants`],
      { queryParams: { status: not.type } }
    );
  }

  get notificationLenghts(): number {
    if (this.notifications) {
      return (
        this.notifications.messages?.length +
        this.notifications.systemMessages?.length
      );
    }
    return 0;
  }

  isLoged(): boolean {
    if (this._authService.currentUserValue) {
      return true;
    } else {
      return false;
    }
  }
}

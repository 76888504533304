import { ObjectMapper } from "json-object-mapper";
import { IModel } from "./IModel";

export class WeatherModel implements IModel<WeatherModel> {
  city: string = '';
  state: string = '';
  latitude: string = '';
  longitude: string = '';
  temperature: string = '';
  climaticCondition: string = '';
  icon: number = 1;

  static create(input: any): WeatherModel {
    return ObjectMapper.deserialize<WeatherModel>(WeatherModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

<!-- <ng-container *ngIf="canCheckable">
	<div class="actionrequired-list-header">
		<input type="checkbox" class="custom-checkbox-input" />

		<i data-feather="trash"></i>

		<i data-feather="check"></i>

		<div>
			<i data-feather="refresh-cw" class="mr-1"></i>

			<i data-feather="more-vertical"></i>
		</div>
	</div>
</ng-container> -->

<div class="scrollable" *ngIf="data.length" [perfectScrollbar]>
	<div *ngFor="let action of data; let index = index; isLast as isLast" class="actionrequired-listactions-item {{ className }} {{ index === data.length - 1 ? '' : 'has-border' }}">
		<div class="flex-1 user">
			<strong  [ngbTooltip]="action.sourceData.label">{{ action.title }}</strong>
			<span>{{ action.description }}</span>
		</div>

		<span>{{ action.creationDate | date: 'dd/MM/yyy HH:mm' }}</span>

		<div class="actionrequired-item-buttons">
			<button class="btn btn-link btn-link-secondary" (click)="handleShowDetails(index, action)">
				Visualizar <i data-feather="arrow-right"></i>
			</button>
		</div>
	</div>
</div>

<div *ngIf="!data.length" class="empty-state">
	<span>Sem registros para exibir.</span>
</div>

<div *ngIf="isLoadingDrawerData" class="spinner-card-container">
	<span>Aguarde um momento ...</span>
	<span class="spinner-border text-primary spinner-border-lg mr-1"></span>
</div>
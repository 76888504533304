import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { DynamicPipe } from './dynamic-pipe';
import { DatePipe } from '@angular/common';
import { CpfPipe } from './cpf.pipe';
import { ControlsFilterPipe } from './controlsFilter.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, DynamicPipe, CpfPipe, ControlsFilterPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, DynamicPipe, CpfPipe, ControlsFilterPipe],
  providers: [
    // list all pipes you would like to use
    DatePipe,
    ControlsFilterPipe
  ],
})
export class CorePipesModule {}

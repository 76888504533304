import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";
import { WorkforcePlanAmountModel } from "services/models/workforceplan/workforceplan-amount.model";

export class WorkforcePlanWorkScaleGroupModel extends Model implements IModel<WorkforcePlanWorkScaleGroupModel> {
	workforcePlanId: string = "";
	workScaleGroup: string = "";
	amounts: WorkforcePlanAmountModel[] = [];

	action?: "added" |  "removed" | "unchanged" | "updated";

	static create(input: any): WorkforcePlanWorkScaleGroupModel {
		return ObjectMapper.deserialize<WorkforcePlanWorkScaleGroupModel>(
			WorkforcePlanWorkScaleGroupModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

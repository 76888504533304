import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model } from './model';
import { VacationLimitType } from 'services/enums/VacationLimitType.enum';
import { FileContentModel } from './fileContent';

export class CompanygroupSettingsModel extends Model implements IModel<CompanygroupSettingsModel> {
  vacationLimitDateType: any = VacationLimitType.CLT.value;
  vacationCutDate: number = 0;
  requiredStrongPassword: boolean = false;
  requiredResetPassword: boolean = false;
  emailAdminNotifications: string = "";
  employeeBirthdayMessage: string = "";
  integratedReportsParameters: string = "";
  companyGroupId: string = "";
  employeeBirthdayImage: FileContentModel = new FileContentModel();
  logo: FileContentModel = new FileContentModel();

  static create(input: any): CompanygroupSettingsModel {
    return ObjectMapper.deserialize<CompanygroupSettingsModel>(CompanygroupSettingsModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { SummaryExitInverviewModel } from "services/models/offboarding/summaryExitInterview.model";
import { ExitInterviewModel } from "services/models/offboarding/exitInterview.model";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { ItensGroupExitInterviewModel } from "services/models/offboarding/itensGroupExitInterview.model";
import { ExitInterviewAnswerModel } from "services/models/offboarding/exitInterviewAnswer.model";
import { ExitInterviewEventModel } from "services/models/offboarding/exitInterviewEvent.model";
import { ExitInterviewDashModel } from "services/models/hiring/exitInterviewDash.model";

@Injectable()
export class ExitInterviewService extends ServiceBase<ExitInterviewModel> {
  constructor(http: HttpService) {
    super("exitInterview", http, environment.apiUrl);
  }

  listExitInverviewsByStatus(
    params: QueryParams = null,
    status: number = 0
  ): Observable<Response<ExitInterviewModel[]>> {
    return this.customList<ExitInterviewModel>(
      params,
      `list-by-status/${status}`,
      ExitInterviewModel
    );
  }

  createEntity(input: any): ExitInterviewModel {
    return ExitInterviewModel.create(input);
  }

  getStructure(
    typeId: string
  ): Observable<Response<ItensGroupExitInterviewModel[]>> {
    return this.http
      .get(`${this.endpoint}/structure/${typeId}`, undefined, this.baseUrl)
      .pipe(
        map((res) => {
          const response = new Response<ItensGroupExitInterviewModel[]>();
          response.data = res.body.map((pp: any) => {
            return ItensGroupExitInterviewModel.create(pp);
          });
          return response;
        })
      );
  }

  getInterviewAnswers(
    interviewId: string
  ): Observable<Response<ExitInterviewAnswerModel[]>> {
    return this.http
      .get(`${this.endpoint}/seek-interview-answers/${interviewId}`, undefined, this.baseUrl)
      .pipe(
        map((res) => {
          const response = new Response<ExitInterviewAnswerModel[]>();
          response.data = res.body.map((pp: any) => {
            return ExitInterviewAnswerModel.create(pp);
          });
          return response;
        })
      );
  }

  getSummary(): Observable<Response<SummaryExitInverviewModel>> {
    return this.http
      .get(`${this.endpoint}/summary`, undefined, this.baseUrl)
      .pipe(
        map((res) => {
          const response = new Response<SummaryExitInverviewModel>();
          response.data = SummaryExitInverviewModel.create(res.body);
          return response;
        })
      );
  }

  fillForm(itens: ExitInterviewAnswerModel[], isLastGroup: boolean): Observable<any> {
    return new Observable<any>((observer) => {
      this.http.post(`${this.endpoint}/fillForm/${isLastGroup}`, itens, null, null, true).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

  terminateInterview(interview: ExitInterviewModel): Observable<any> {
    return this.http.post(`${this.endpoint}/terminate`, interview).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }

  getEvents(
    exitInterviewId: string
  ): Observable<Response<ExitInterviewEventModel[]>> {
    return this.http
      .get(`${this.endpoint}/events/${exitInterviewId}`, undefined, this.baseUrl)
      .pipe(
        map((res) => {
          const response = new Response<ExitInterviewEventModel[]>();
          response.data = res.body.map((pp: any) => {
            return ExitInterviewEventModel.create(pp);
          });
          return response;
        })
      );
  }

  getDash(params: URLSearchParams = null): Observable<Response<ExitInterviewDashModel>> {
    const queryParams = params !== null ? `?${params.toString()}` : '';
    return this.http
      .get(`${this.endpoint}/dash${queryParams}`, undefined, this.baseUrl, undefined, true)
      .pipe(
        map((res) => {
          const response = new Response<ExitInterviewDashModel>();
          response.data = ExitInterviewDashModel.create(res.body);

          return response;
        })
      );
  }
}

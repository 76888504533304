import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class ExitInterviewAnswerModel
  extends Model
  implements IModel<ExitInterviewAnswerModel>
{
  exitInterviewId: string | undefined = undefined;
  exitInterviewItemId: string | undefined = undefined;
  value: string = "";

  static create(input: any): ExitInterviewAnswerModel {
    return ObjectMapper.deserialize<ExitInterviewAnswerModel>(
      ExitInterviewAnswerModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { BaseEnum } from "./baseEnum"

const listWorkRegimeEnum: Array<WorkforceplanMovementType> = [];

export class WorkforceplanMovementType extends BaseEnum {
	static WORK_FORCEPLAN = new WorkforceplanMovementType(1, 'WorkforcePlanning', 'Planejamento de Orgânico');
	static TRAINNING = new WorkforceplanMovementType(2, 'InternalTraining', 'Treinamento interno');
	static DISMISSAL = new WorkforceplanMovementType(3, 'Dismissal', 'Demissional');
	static SHIFT_CHANGE = new WorkforceplanMovementType(4, 'ShiftChange', 'Troca de turno');
	static NEW_HIRING_REPLACEMENT = new WorkforceplanMovementType(5, 'NewHiringReplacement', 'Nova Contratação (Substituição)');
	static COST_CENTER_CHANGE = new WorkforceplanMovementType(6, 'CostCenterChange', 'Troca de centro de custo');
	static JOB_TITLE_CHANGE = new WorkforceplanMovementType(7, 'JobTitleChange', 'Troca de cargo');
	static NEW_HIRING_INCREASE = new WorkforceplanMovementType(8, 'NewHiringIncrease', 'Nova Contratação');

	constructor(public id: number, public value: string, public label: string) {
		super(id, value, label);

		listWorkRegimeEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listWorkRegimeEnum;
	}

	public static getByEnumerator(value: string) {
		switch (value) {
			case 'REQUEST_DISMISSAL':
				return this.getBy('Dismissal');
			case 'REQUEST_TRAINING':
				return this.getBy('InternalTraining');
			case 'SHIFT_CHANGE':
				return this.getBy('ShiftChange');
			case 'REQUEST_HIRING_REPLACEMENT':
				return this.getBy('NewHiringReplacement');
			case 'CENTER_COST_CHANGE':
				return this.getBy('CostCenterChange');
			case 'JOBTITLE_CHANGE':
				return this.getBy('JobTitleChange');
			case 'REQUEST_HIRING_EXPANSION':
				return this.getBy('NewHiringIncrease');
			case 'REQUEST_WORK_FORCEPLAN':
				return WorkforceplanMovementType.getBy('WorkforcePlanning');
			case 'REQUEST_HIRING_REPLACEMENT_POSITION':
				return this.getBy('NewHiringReplacement');
		}
	}
}

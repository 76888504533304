import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OptionModel } from "services/models/model";

import { FileService } from "@core/services/file.service";
import { AFDModel } from "../models/clock/afd.model";

import { environment } from "environments/environment";
import { HttpService } from "../http.service";
import { CompanyModel } from "../models/company.model";
import { TimeShiftModel } from "../models/hiring/timeShift.model";
import { Filter } from "../models/odata/filter";
import { QueryParams } from "../models/odata/queryParams";
import { Response } from "../models/response";
import { ServiceBase } from "../servicebase";
import { CompanyRootModel } from "services/models/companyRoot.model";

@Injectable()
export class CompanyService extends ServiceBase<CompanyModel> {
  constructor(http: HttpService, private file: FileService) {
    super("company", http, environment.apiUrl);
  }

  createEntity(input: any): CompanyModel {
    return CompanyModel.create(input);
  }

  getCompaniesOptions(disableLoading = false): Observable<OptionModel[]> {
    return new Observable<OptionModel[]>((observer) => {
      this.list({
        select: ["name", "id", "headOfficeId", "companyGroupId"],
        orderBy: "name",
        orderByOrder: "asc",
      } as QueryParams, disableLoading).subscribe((res) => {
        observer.next(
          res.data.filter((x) => x.enable).map((c) => c.toOption())
        );
        observer.complete();
      });
    });
  }

  getCompaniesRootOptions(companyGroupId: string): Observable<CompanyRootModel[]> {
    return new Observable<any>((observer) => {
      this.customList<CompanyRootModel>(
        {
          select: ["name", "id", "rootCNPJ", "companyGroupId"],
          orderBy: "name",
          orderByOrder: "asc",
          filter: Filter.equalToGuid("companyGroupId", companyGroupId),
        } as QueryParams,
        "list-companyroots",
        CompanyRootModel
      ).subscribe((res) => {
        observer.next(res.data.filter((x) => x.enable));
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  downloadAFD(afd: AFDModel): Observable<Blob> {
    return this.http.post(`${this.endpoint}/create.afd`, afd).pipe(
      map((res) => {
        const blobData = res["fileContents"];
        const blob = this.file.base64ToBlob(blobData);
        const tipo = "txt";
        this.file.saveFile(blob, `${res["fileDownloadName"]}.${tipo}`);
        return blob;
      })
    );
  }

  loadTimeShifts(companyId: string): Observable<TimeShiftModel[]> {
    return new Observable<any>((observer) => {
      this.customList<TimeShiftModel>(
        {
          select: ["code", "title"],
          orderBy: "code",
          orderByOrder: "asc",
          filter: Filter.equalToGuid("companyId", companyId),
        } as QueryParams,
        "list.timeshitfs",
        TimeShiftModel, true
      ).subscribe((res) => {
        observer.next(res.data.filter((x) => x.enable));
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  listByHierarchy(): Observable<Response<CompanyModel[]>> {
    return this.http
      .get(
        `${this.endpoint}/list-by-hierarchy`,
        undefined,
        undefined,
        undefined,
        true
      )
      .pipe(
        map((res) => {
          const response = new Response<CompanyModel[]>();
          response.data = res.body
            .filter((c) => c.enable)
            .map((pp) => CompanyModel.create(pp));
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  listSimpleCompanies(): Observable<Response<CompanyModel[]>> {
    const params = {
      select: ["name", "id", "headOfficeId", "cnpj"],
      orderBy: "name",
      orderByOrder: "asc",
    } as QueryParams;
    const defaultSelect = ["id", "enable", "creationDate"];
    params.select = [
      ...params.select.filter((field) => !defaultSelect.includes(field)),
      ...defaultSelect,
    ];

    const query = this.createBuildQuery(params);
    return this.http
      .get(
        `${this.endpoint}/odata${query}`,
        undefined,
        this.baseUrl,
        undefined,
        true
      )
      .pipe(
        map((res) => {
          const response = new Response<CompanyModel[]>();
          response.data = res.body
            .filter((c) => c.enable)
            .map((pp) => this.createEntity(pp) as CompanyModel);
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }
}

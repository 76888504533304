import * as moment from "moment";
import { Component, OnInit } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";

import { DomainService } from "services/domain.service";
import { CompanyService } from "services/corporate/company.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { FormValidations } from "utils/form-validation";
import { DomainModel } from "services/models/domain.model";

@Component({
  selector: "app-afd",
  templateUrl: "./afd.component.html",
  styleUrls: ["./afd.component.scss"],
})
export class AfdComponent implements OnInit {
  domainObj: DomainModel = null;
  domainImageUrl = "";
  companyOptions: any;
  dateRangeForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private _coreConfigService: CoreConfigService,
    private _domainService: DomainService,
    private _CompanyService: CompanyService
  ) {
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: false,
        },
        menu: {
          hidden: false,
        },
        footer: {
          hidden: false,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit() {
    const domain = this._domainService.getDomain();
    if (domain) {
      this.domainObj = domain;
      this.domainImageUrl = this._domainService.CompanyImageUrl;
    }
    this.initForm();
    this.getCompanies();
  }

  downloadAFD() {
    if (this.dateRangeForm.invalid) return;

    this._CompanyService.downloadAFD(this.dateRangeForm.value).subscribe();
  }

  initForm() {
    this.dateRangeForm = this.fb.group({
      startDate: [new Date(), [Validators.required]],
      endDate: [new Date(), Validators.required],
      company: [
        "00000000-0000-0000-0000-000000000000",
        [FormValidations.guidRequired],
      ],
    });
  }

  getCompanies() {
    this._CompanyService.getCompaniesOptions().subscribe((res) => {
      this.companyOptions = res;
    });
  }
}

import { BaseEnum } from "./baseEnum";

const listBenefitTypeEnum: Array<BenefitTypeEnum> = [];

export type BenefitType =
	| "MealVoucher"
	| "FoodVoucher"
	| "DentalPlan"
	| "TransportationAllowance"
	| "LifeInsurance"
	| "DaycareAssistance";

export class BenefitTypeEnum extends BaseEnum {
	static TransportationAllowance = new BenefitTypeEnum(
		1,
		"TransportationAllowance",
		"Vale-Transporte",
		"bus"
	);
	static MealVoucher = new BenefitTypeEnum(2, "MealVoucher", "Vale-Refeição", "tools-kitchen-2");
	static FoodVoucher = new BenefitTypeEnum(
		3,
		"FoodVoucher",
		"Vale-Alimentação",
		"tools-kitchen-2"
	);
	static HealthPlan = new BenefitTypeEnum(4, "HealthPlan", "Plano de Saúde", "heart");
	static DentalPlan = new BenefitTypeEnum(
		5,
		"DentalPlan",
		"Plano Odontológico",
		"emojie-face"
	);
	static DaycareAssistance = new BenefitTypeEnum(
		6,
		"DaycareAssistance",
		"Auxilio Creche",
		"bus"
	);
	static LifeInsurance = new BenefitTypeEnum(
		7,
		"LifeInsurance",
		"Seguro de Vida",
		"heart"
	);

	constructor(public id: number, public value: string, public label: string, icon: string) {
		super(id, value, label, icon);
		listBenefitTypeEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listBenefitTypeEnum;
	}
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { HiringStatusType } from "services/enums/HiringStatusType.enum";

export class HiringEventsModel
  extends Model
  implements IModel<HiringEventsModel>
{
  eventDate: string = '';
  applicantId: string = '';
  status: HiringStatusType = HiringStatusType.WaitingSesmt;
  id: string = '';
  enable: boolean = true;
  creationDate: string = '';
  changeDate: string = '';
  authorId: string | undefined = undefined;
  authorName: string = '';

  static create(input: any): HiringEventsModel {
    const model = ObjectMapper.deserialize<HiringEventsModel>(HiringEventsModel, input);
    model.status = HiringStatusType.findByValue(input.status) || HiringStatusType.WaitingSesmt;
    return model;
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
import { Injectable } from "@angular/core";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { TimeShiftHistoryModel } from "services/models/corporate/timeShiftHistory.model";

@Injectable()
export class TimeShiftHistoryService extends ServiceBase<TimeShiftHistoryModel> {
  constructor(http: HttpService) {
    super("TimeShiftHistory", http, environment.apiUrl);
  }

  createEntity(input: any): TimeShiftHistoryModel {
    return TimeShiftHistoryModel.create(input);
  }

}
import { Model } from "services/models/model";
import { IModel } from "../IModel";
import { ObjectMapper } from "json-object-mapper";

export class BenefitPetitionDependentModel
  extends Model
  implements IModel<BenefitPetitionDependentModel>
{
  employeeId: string | undefined = undefined;
  petitionId: string | undefined = undefined;
  dependentId: string | undefined = undefined;
  benefitPlanId: string = "";
  dependentName: string = "";
  dependentCpf: string = "";
  benefitDescription: string = "";
  benefitEmployeeId: string = "";

  static create(input: any): BenefitPetitionDependentModel {
    return ObjectMapper.deserialize<BenefitPetitionDependentModel>(
      BenefitPetitionDependentModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lgpd-modal',
  templateUrl: './lpgd-modal.component.html',
  styleUrls: ['./lgpd-modal.component.scss'],
})
export class LgpdModalComponent implements OnInit {
  title: string;
  type: number;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.title = this.type == 1 ? 'Aviso de Privacidade' : 'Termos e Condições';
  }

  onDismiss(): void {
    this.activeModal.close(false);
  }
}

<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body card " tabindex="0" ngbAutofocus>

  <h5>📑 Escolher impressão</h5>

  <p class="card-text font-small-3 applicant-info"> Você mais de um relatório disponivel, escolha qual deseja
    exibir:
  </p>
  <div>
    <ul>
      <li *ngFor="let report of availablesReports">
        <a type="button" (click)="choose(report.index)" class="btn btn-outline-info btn-infos" rippleEffect>
          Abrir relatório: {{report.label}}
        </a>
      </li>
    </ul>
  </div>

  <!-- <div class="container flexButton">
    <div class="row row-buttons">

      <a type="button" href="" target="_blank" class="btn btn-outline-info btn-infos" rippleEffect>Clique
        aqui para ver o vídeo
        passo-a-passo</a>
    </div>
  </div> -->
</div>
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { UserModel } from "services/models/corporate/user.model";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UserType} from "services/enums/userType.enum";

@Injectable()
export class UserService extends ServiceBase<UserModel> {
  constructor(http: HttpService) {
    super("user", http, environment.apiUrl);
  }

  createEntity(input: any): UserModel {
    return UserModel.create(input);
  }

	getList(): Observable<UserModel[]> {
		const userType = UserType.OutsourcedLiable.value;

		const queryParams = `$filter=(userType eq '${userType}')&$select=name,email,id,enable,creationDate,userType&$top=10&$orderby=id%20asc`;

		return this.http.get(`${this.endpoint}/odata?${queryParams}`, null, null, null, true).pipe(
			map((res) => {
				return res.body.map((pp) => UserModel.create(pp));
			})
		);
	}
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkforcePlanAmountModel extends Model implements IModel<WorkforcePlanAmountModel> {
	id: string = "";
	workScaleGroupId: string = "";
	jobTitleGroupId: string = "";
	jobTitleGroupName: string = "";
	jobTitleId: string = "";
	jobTitleName: string = "";
	amount: number = 0;

	previousAmount?: number = 0;
	newAmount?: number = 0;
	action?: "added" |  "removed" | "unchanged" | "updated";
	changeDirection?: "increase" | "decrease" | "unchanged";

	static create(input: any): WorkforcePlanAmountModel {
		return ObjectMapper.deserialize<WorkforcePlanAmountModel>(
			WorkforcePlanAmountModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}
import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { DomainService } from "services/domain.service";
import { AuthService } from '../../app/services/auth.service';

@Injectable()
export class DomainGuard implements CanActivate {
  constructor(private router: Router, private domainService: DomainService, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const routeParam = route.params.domainName;
    return new Promise((resolve, reject) => {
      this.domainService.checkDomainName(routeParam).subscribe(
        (domain) => {
          this.validateDomainName(domain.domainName);
          if (domain) {
            this.domainService.setDomain(domain);
            resolve(true);
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status === 511) {
            this.router.navigate(["/error"], {
              state: { httpErrorStatus: err.status },
            });
          } else {
            this.gotInvalidDomain();
          }

          const message = err.error?.Message || err.error;
          console.error(message);

          resolve(false);
        }
      );
    });
  }

  validateDomainName(domainName: string) {
    const centralDomain = this.authService.getDomainName();
    if(centralDomain && centralDomain !== domainName) {
      this.authService.logout();
      this.router.navigate([`${domainName}/auth/login`]);
    }
  }

  gotInvalidDomain() {
    this.domainService.setDomain(null);
    this.router.navigateByUrl("/error");
  }
}

import { ObjectMapper } from 'json-object-mapper';
import { IModel } from '../IModel';
import { Model } from '../model';
import { DocumentTypeModel } from './document-type.model';

export class ApplicantDocumentModel
  extends Model
  implements IModel<ApplicantDocumentModel>
{
  public fileName: string = '';
  public filePath: string = '';
  public extension: string = '';
  public note: string = '';
  public status: number | string = 1;
  public documentTypeId: string | undefined = undefined;
  public encodedBase64: ArrayBuffer | undefined = undefined;
  documentType: DocumentTypeModel = new DocumentTypeModel();
  public standardDocumentId: string | undefined = undefined;
  public applicantDependentId: string | undefined = undefined;
  public rotationAngle: number = 0;
  public scale: number = 1

  static create(input: any): ApplicantDocumentModel {
    return ObjectMapper.deserialize<ApplicantDocumentModel>(
      ApplicantDocumentModel,
      input
    );
  }

  get documentStatusLabel(): string {
    //@ts-ignore
    switch (this.status as ApplicantDocumentStatus) {
      case ApplicantDocumentStatus.Ok:
        return 'Aprovado!';
      case ApplicantDocumentStatus.Wait:
        return 'Aguardando...';
      case ApplicantDocumentStatus.Invalid:
        return 'Inválido.';
      default:
        return 'Novo';
    }
  }
}

export enum ApplicantDocumentStatus {
  Ok = 'Okay',
  Wait = 'Waiting',
  Invalid = 'Invalid',
}

/* eslint-disable no-shadow */
enum ClaimTypes {
  aud = 'aud',
  exp = 'exp',
  iss = 'iss',
  role = 'role',
  action = 'action',
  user_id = 'user_id',
  user_type = 'user_type',
  domainName = 'domainName',
  user_levels = 'user_levels',
}
export default ClaimTypes;


import { Injectable } from "@angular/core";
import {environment} from "environments/environment";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpService} from "services/http.service";
import {ActionRequiredModel} from "services/models/action-required";
import {ServiceBase} from "services/servicebase";

@Injectable()
export class ActionRequiredService extends ServiceBase<ActionRequiredModel> {
	constructor(http: HttpService) {
		super("actionRequired", http, environment.apiUrl);
	}

	createEntity(input: any): ActionRequiredModel {
		return ActionRequiredModel.create(input);
	}

	getMyPendings(): Observable<ActionRequiredModel[]> {
		return this.http.get(`${this.endpoint}/my/pendings?$orderBy=creationDate desc`, null, null, null, true).pipe(
			map((data: any) => data.body.map(item => ActionRequiredModel.create(item)))
		);
	}

	getMyFinisheds(): Observable<ActionRequiredModel[]> {
		return this.http.get(`${this.endpoint}/my/finished?$orderBy=creationDate desc`, null, null, null, true).pipe(
			map((data: any) => data.body.map(item => ActionRequiredModel.create(item)))
		);
	}

	markActionRequiredForFinished(id: string) {
		return this.http.put(`${this.endpoint}/${id}/mark-as-finished`, {});
	}
}

import { BaseEnum } from "./baseEnum";

const listEligibilityVerificationStatus: Array<EligibilityVerificationStatusEnum> =
  [];

export class EligibilityVerificationStatusEnum extends BaseEnum {
  static Pending = new EligibilityVerificationStatusEnum(
    0,
    "Pending",
    "Arq. Pendente",
    "secondary"
  );
  static Requested = new EligibilityVerificationStatusEnum(
    1,
    "Requested",
    "Solicitado",
    "primary"
  );
  static Success = new EligibilityVerificationStatusEnum(
    2,
    "Success",
    "Sucesso",
    "success"
  );
  static Rejected = new EligibilityVerificationStatusEnum(
    3,
    "Rejected",
    "Rejeitado",
    "danger"
  );

  constructor(
    public id: number,
    public value: string,
    public label: string,
    public className: string
  ) {
    super(id, value, label, className);
    listEligibilityVerificationStatus.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEligibilityVerificationStatus;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class TimeShiftHistoryModel extends Model implements IModel<TimeShiftHistoryModel> {
  employeeId: string | undefined = undefined;
  date: string = '';
  timeShiftId: string | undefined = undefined;
  sequenceTimeShiftId: string | undefined = undefined;
  note: string = '';

  sequence: string = '';
  timeShiftCode: string = '';
  timeShiftTitle: string = '';
  timeShiftTable: string = '';

  static create(input: any): TimeShiftHistoryModel {
    return ObjectMapper.deserialize<TimeShiftHistoryModel>(TimeShiftHistoryModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="comprovante-ponto">
    <p>{{ message }}</p>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn" [ngClass]="neutralStyle ? 'btn-outline-secondary' : 'btn-outline-danger'"
    (click)="onDismiss()" rippleEffect>
    {{ messageCancel ? messageCancel : "Cancelar (Esc)" }}
  </button>
  <button type="button" class="btn" [ngClass]="neutralStyle ? 'btn-outline-secondary' : 'btn-success'"
    *ngIf="focusConfirm" (click)="onConfirm()" rippleEffect #btnConfirm>
    {{ messageConfirm ? messageConfirm : "Confirmar (Enter)" }}
  </button>
  <button type="button" class="btn" [ngClass]="neutralStyle ? 'btn-outline-secondary' : 'btn-success'"
    *ngIf="!focusConfirm" (click)="onConfirm()" rippleEffect #btnConfirm>
    {{ messageConfirm ? messageConfirm : "Confirmar" }}
  </button>
</div>
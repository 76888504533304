import { IModel } from "../IModel";
import { Model } from "../model";
import { ObjectMapper } from "json-object-mapper";

export class ActiveBenefitDetailsModel
	extends Model
	implements IModel<ActiveBenefitDetailsModel> {
	benefitEmployeeId: string = "";
	benefitPlanId: string = "";
	employeeId: string = "";
	benefitType: string = "";
	benefitDescription: string = "";
	providerName: string = "";
	providerLogo: string = "";
	providerId: string = "";
	benefitDependents: BenefitDependentDetailsModel[] = [];
	employeePetitions: BenefitPetitionEmployeeDetailsModel[] = [];
	dependentPetitions: BenefitPetitionDependentDetailsModel[] = [];

	get benefitTypeBadge(): string {
		return `<span class="badge badge-light-primary text-wrap">${this.benefitDescription}</span>`;
	}

	static create(input: any): ActiveBenefitDetailsModel {
		if ("benefitDependents" in input) {
			input.benefitDependents = input.benefitDependents.map(
				BenefitDependentDetailsModel.create
			);
		}
		if ("employeePetitions" in input) {
			input.employeePetitions = input.employeePetitions.map(
				BenefitPetitionEmployeeDetailsModel.create
			);
		}

		if ("dependentPetitions" in input) {
			input.dependentPetitions = input.dependentPetitions.map(
				BenefitPetitionDependentDetailsModel.create
			);
		}

		return ObjectMapper.deserialize<ActiveBenefitDetailsModel>(
			ActiveBenefitDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class BenefitDependentDetailsModel
	extends Model
	implements IModel<BenefitDependentDetailsModel> {
	benefitDependentId: string = "";
	benefitPlanId: string = "";
	dependentId: string = "";

	static create(input: any): BenefitDependentDetailsModel {
		return ObjectMapper.deserialize<BenefitDependentDetailsModel>(
			BenefitDependentDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class BenefitPetitionEmployeeDetailsModel
	extends Model
	implements IModel<BenefitPetitionEmployeeDetailsModel> {
	petitionId: string = "";
	requestType: string = "";
	newBenefitPlanId: string = "";

	static create(input: any): BenefitPetitionEmployeeDetailsModel {
		return ObjectMapper.deserialize<BenefitPetitionEmployeeDetailsModel>(
			BenefitPetitionEmployeeDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class BenefitPetitionDependentDetailsModel
	extends Model
	implements IModel<BenefitPetitionDependentDetailsModel> {
	petitionId: string = "";
	requestType: string = "";
	benefitPlanId: string = "";
	dependentId: string = "";

	static create(input: any): BenefitPetitionDependentDetailsModel {
		return ObjectMapper.deserialize<BenefitPetitionDependentDetailsModel>(
			BenefitPetitionDependentDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

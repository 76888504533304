import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class HiringConfigModel
  extends Model
  implements IModel<HiringConfigModel>
{
  smsCongratsMessage: string = "";
  smsFinishedMessage: string = "";
  offerLetterMessage: string = "";
  offerLetterEmail: string = "";
  wppCongratsMessage: string = "";
  wppCongratsImage: string = "";
  wppFinishedMessage: string = "";
  wppFinishedImage: string = "";
  wppInvalidDocsMessage: string = "";
  wppInvalidDocsImage: string = "";
  completionDeadline: number = 0;
  instructionsMp4Url: string = "";
  instructionsPdfUrl: string = "";
  setAdmissionForm: string = "";
  setApplicantForm: string = "";
  bankNumbers: string[] = [];
  sesmtAlertEmail: string = "";
  dpAlertEmail: string = "";
  companyGroupId: string = "";
  hasOfferLetter: boolean = false;
  hasAdmissionExams: boolean = false;

  static create(input: any): HiringConfigModel {
    return ObjectMapper.deserialize<HiringConfigModel>(
      HiringConfigModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

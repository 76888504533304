import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class AccessClaimModel
  extends Model
  implements IModel<AccessClaimModel>
{
  name: string = "";
  description: string = "";
  title: string = "";
  type: string = "";

  static create(input: any): AccessClaimModel {
    return ObjectMapper.deserialize<AccessClaimModel>(AccessClaimModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

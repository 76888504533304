import { BaseEnum } from "./baseEnum";

const listWorkforceplanEmployeeStatusEnum: Array<WorkforceplanEmployeeStatus> = [];

export class WorkforceplanEmployeeStatus extends BaseEnum {
    static None = new WorkforceplanEmployeeStatus(0, 'None', 'Nenhum');
    static OnLeave = new WorkforceplanEmployeeStatus(1, 'OnLeave', 'Afastados');
    static InResignation = new WorkforceplanEmployeeStatus(2, 'InResignation', 'Em Treinamento');
    static FinishedTraining = new WorkforceplanEmployeeStatus(3, 'FinishedTraining', 'Saiu Treinamento');
    static InTraining = new WorkforceplanEmployeeStatus(4, 'InTraining', 'Entrou Treinamento');
    static InPlanning = new WorkforceplanEmployeeStatus(5, 'InPlanning', 'Planejados');
    static Allocated = new WorkforceplanEmployeeStatus(6, 'Allocated', 'Alocados');

    constructor(public id: number, public value: string, public label: string) {
        super(id, value, label);

        listWorkforceplanEmployeeStatusEnum.push(this);
    }

    public static buildListEnum(): Array<BaseEnum> {
        return listWorkforceplanEmployeeStatusEnum;
    }
}

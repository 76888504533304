import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { DocumentSignatureGroupModel } from "services/models/documents/document-signature-group.model";
import { DocumentSignatureModel } from "services/models/documents/document-signature.model";
import { QueryParams } from 'services/models/odata/queryParams';
import { Response } from 'services/models/response';
import { ServiceBase } from "services/servicebase";

type ValidateCodeData = {
	token: string;
	employeeId: string;
	documentSignatureId: string;
};

type SignDocumentData = {
	token: string;
	employeeId: string;
	rubricBaseSvg: string;
	documentSignatureId: string;
};

type StateDropzoneFilesSubjectOutput = {
	state: 'default' | 'attaching' | 'submitting'
}

@Injectable()
export class DocumentSignatureService extends ServiceBase<DocumentSignatureModel> {
	private stateDropzoneFilesSubject = new Subject<StateDropzoneFilesSubjectOutput>();
	public stateDropzoneFiles$ = this.stateDropzoneFilesSubject.asObservable();

	constructor(http: HttpService) {
		super("digitalsignature", http, environment.apiUrl);
	}

	createEntity(input: any): DocumentSignatureModel {
		return DocumentSignatureModel.create(input);
	}

	emitEventChangeModeVisualizationForm(data: StateDropzoneFilesSubjectOutput) {
		this.stateDropzoneFilesSubject.next(data);
	}

	list(params: QueryParams, disableLoading: boolean = false): Observable<Response<DocumentSignatureModel[]>> {
		const query = this.createBuildQuery(params);

		query.replace("$select", "")

		return this.http
			.get(`${this.endpoint}/odata/groups?${query}`, undefined, this.baseUrl, null, disableLoading)
			.pipe(
				map((res) => {
					const response = new Response<DocumentSignatureModel[]>();
					response.data = res.body.map((pp) => this.createEntity(pp) as DocumentSignatureModel);
					response.count = res.headers.get('x-count');
					return response;
				})
			);
	}

	getGroupDetails(groupId: string): Observable<DocumentSignatureGroupModel> {
		return new Observable<DocumentSignatureGroupModel>((observer) => {
			this.http.get(`${this.endpoint}/group/${groupId}`).subscribe(
				(res) => {
					observer.next(DocumentSignatureGroupModel.create(res.body));

					observer.complete();
				},
				(err) => observer.error(err)
			);
		});
	}

	saveSendDocument(data: DocumentSignatureModel) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}`, data).subscribe((response) => {
				observer.next(response);
				observer.complete();
			});
		});
	}

	saveSendMultiplesDocument(data: DocumentSignatureModel) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}/group`, data, null, null, true).subscribe((response) => {
				observer.next(response);
				observer.complete();
			});
		});
	}

	public getFileForSign(processId: string): Observable<Blob> {
		const requestUrl = `${this.endpoint}/document-for-sign?processId=${processId}`;

		return new Observable<any>((observer) => {
			this.http
				.get(requestUrl, { responseType: "blob" }, null, null, true)
				.subscribe(
					({ body }) => {
						observer.next(body);
						observer.complete();
					},
					(error) => {
						observer.error(error);
						observer.complete();
					}
				);
		});
	}

	public getFileSigned(processId: string): Observable<Blob> {
		const requestUrl = `${this.endpoint}/signed-document?processId=${processId}`;

		return new Observable<any>((observer) => {
			this.http
				.get(requestUrl, { responseType: "arraybuffer" }, null, null, true)
				.subscribe(({ body }) => {
					observer.next(body);

					observer.complete();
				});
		});
	}

	getEvents(processId: string): Observable<any> {
		return this.http
			.get(
				`${this.endpoint}/events`,
				{ params: { processId } },
				null,
				null,
				true
			)
			.pipe(
				map((res) => {
					return res.body;
				})
			);
	}

	validateCode(data: ValidateCodeData) {
		return new Observable<any>((observer) => {
			this.http
				.post(`${this.endpoint}/validate-token`, data, null, null, true)
				.subscribe(
					(response) => {
						observer.next(response);
						observer.complete();
					},
					(error) => {
						observer.error(error);
						observer.complete();
					}
				);
		});
	}

	signDocument(data: SignDocumentData) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}/sign`, data, null, null, true).subscribe(
				(response) => {
					observer.next(response);
					observer.complete();
				},
				(error) => {
					observer.error(error);
					observer.complete();
				}
			);
		});
	}

	downloadDocument(url: string, fileName: string) {
		const link = document.createElement("a");

		link.href = url;
		link.download = `RHGestao-${fileName}`;

		link.click();
	}

	resendCode(documentId: string, employeeId: string) {
		const url = `${this.endpoint}/${documentId}/resend-token/${employeeId}`;

		return this.http.post(url, {}, null, null, true);
	}

	public processTemporaryFile(data: any, options: any) {
		const requestUrl = `${this.endpoint}/upload-temp-file`;

		return this.http.post(requestUrl, data, options, null, true);
	}
}

import { Injectable } from "@angular/core";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { PointTimePeriodModel } from "services/models/corporate/pointTimePeriod.model";
import { Observable } from "rxjs";

@Injectable()
export class PointTimePeriodService extends ServiceBase<PointTimePeriodModel> {
  constructor(http: HttpService) {
    super("pointtimeperiod", http, environment.apiUrl);
  }

  createEntity(input: any): PointTimePeriodModel {
    return PointTimePeriodModel.create(input);
  }

  changePeriodStatus(periodId: string) {
    return this.http
      .put(`${this.endpoint}/${periodId}/toggle-status`, '');
  }

  checkPeriodAvailableByCompany(companyId: string, date: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.http.get(`${this.endpoint}/check-period-available-by-company/${companyId}/${date}`).subscribe(
        (res) => {
          observer.next(res.body);
          observer.complete();
        },
        (err) => observer.error(err)
      );
    });
  }

}
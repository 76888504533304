import { ObjectMapper } from "json-object-mapper";
import { DigitalSignatureStatus } from "services/enums/digitalSignatureStatus.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import type { DocumentSignatureSignerModel } from "./document-signature-signer.model";

export class DocumentSignatureModel
  extends Model
  implements IModel<DocumentSignatureModel>
{
  originalFileS3Path: string = "";
  originalFileExtension: string = "";
  signedFileS3Path: string = "";
  signedFileExtension: string = "";
  hashChecksumSHA256: string = "";
  title: string = "";
  status: DigitalSignatureStatus | string = "";
  limitDate: string = "";
  companyGroupId: string = "";
  creatorEmployeeId: string = "";
  creatorEmployeeName: string = "";
  signers?: DocumentSignatureSignerModel[] = [];

  static create(input): DocumentSignatureModel {
    return ObjectMapper.deserialize<DocumentSignatureModel>(
      DocumentSignatureModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get statusIcon() {
    switch (this.status) {
      case "SIGNED": {
        return {
          icon: "check",
          tooltip: "Completamente assinado",
        };
      }
      case "CANCELLED": {
        return {
          icon: "clock",
          tooltip: "Cancelado",
        };
      }
      case "PARTIAL_SIGNED": {
        return {
          icon: "clock",
          tooltip: "Parcialmente assinado",
        };
      }
      default:
        return {
          icon: "clock",
          tooltip: "Pendente",
        };
    }
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CoreLoadingScreenService } from '@core/services/loading-screen.service';

export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  constructor(private loadingService: CoreLoadingScreenService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_LOG) === false) {
      this.loadingService.show();
      return next
        .handle(request)
        .pipe(finalize(() => this.loadingService.hide()));
    }else{
      return next.handle(request);
    }
  }
}

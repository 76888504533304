import { BaseEnum } from "./baseEnum"

const listUserRoleEnum: Array<UserRoleType> = [];

export class UserRoleType extends BaseEnum {
  static root_adm = new UserRoleType(1, 'root_adm', 'ROOT', 'primary');
  static company_adm = new UserRoleType(2, 'company_adm', 'Empresa', 'primary');
  static company_group_adm = new UserRoleType(3, 'company_group_adm', 'Grupo de Empresa', 'primary');
  static employee = new UserRoleType(4, 'employee', 'Funcionário', 'success');
  static personnel_department = new UserRoleType(5, 'personnel_department', 'DP', 'info');
  static work_safety = new UserRoleType(6, 'work_safety', 'Segurança do Trabalho', 'warning');
  static human_resources = new UserRoleType(7, 'human_resources', 'RH', 'danger');
  static manager = new UserRoleType(8, 'manager', 'Gerente', 'secondary');
  static vacation_manager = new UserRoleType(9, 'vacation_manager', 'Gerente de Férias', 'warning');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listUserRoleEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listUserRoleEnum;
  }
}
import { BaseEnum } from "./baseEnum";

const listWorkforceplanStatusEnum: Array<WorkforceplanEmployeeStatusType> = [];

export class WorkforceplanEmployeeStatusType extends BaseEnum {
	static PENDING = new WorkforceplanEmployeeStatusType(0, 'PENDING', 'Pendente', 'light-secondary');
	static ACTIVE = new WorkforceplanEmployeeStatusType(1, 'ACTIVE', 'Ativo', 'light-secondary');
	static EXPERIENCE = new WorkforceplanEmployeeStatusType(2, 'EXPERIENCE', 'Experiência', 'light-info');
	static WORK_LEAVE = new WorkforceplanEmployeeStatusType(3, 'WORK_LEAVE', 'Afastado', 'light-danger');

	constructor(public id: number, public value: string, public label: string, public className: string) {
		super(id, value, label, className);

		listWorkforceplanStatusEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listWorkforceplanStatusEnum;
	}
}

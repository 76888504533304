import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model } from './model';

export class ResetPasswordModel extends Model implements IModel<ResetPasswordModel> {
  password: string = '';
  registration: string = '';
  message: string = '';

  static create(input: any): ResetPasswordModel {
    return ObjectMapper.deserialize<ResetPasswordModel>(ResetPasswordModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
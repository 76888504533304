import { Injectable } from "@angular/core";

export type FileContentResponse = {
	error?: string;
	details?: Error;
	fileName: string,
	extension: string;
	encodedBase64: string;
}

@Injectable({
	providedIn: "root",
})
export class FileService {
	constructor() { }

	public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
		b64Data = b64Data.replace(/\s/g, "");
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	public saveFile(blob: Blob, fileName: string) {
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = `RHGestao-${fileName}`;
			link.click();
		}
	}

	public saveZipFile(blob: Blob, fileName: string) {
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.download = `RHGestao-${fileName}.zip`;
			link.click();
			window.URL.revokeObjectURL(url);
		}
	}

	public openFile(res: Blob) {
		const downloadURL = window.URL.createObjectURL(res);
		window.open(downloadURL);
	}

	public async fileToBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	public downloadBase64File(base64: string, contentType: string, fileName: string): void {
		const blob = this.base64ToBlob(base64, contentType);
		this.saveFile(blob, fileName);
	}

	public getFileContentByInputFile(file: File, allowedExtensions: string[]): Promise<FileContentResponse> {
		return new Promise((resolve, reject) => {
			if (!file) {
				reject({ error: "Nenhum arquivo fornecido." });

				return;
			}

			const reader = new FileReader();

			reader.onloadend = () => {
				const encodedBase64 = reader.result as string;

				const extension = file.name.split('.').pop();

				if (!allowedExtensions.includes(extension.toLowerCase())) {
					reject({ error: "O formato do arquivo inserido não é permitido." });

					return;
				}

				const base64String = encodedBase64.split("base64,")[1];

				resolve({
					extension,
					fileName: file.name,
					encodedBase64: base64String,
				});
			};

			reader.onerror = (error) => {
				reject({ error: "Erro ao ler o arquivo.", details: error });
			};

			reader.readAsDataURL(file);
		});
	}
}

<div class="modal-header ">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" (click)="onDismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="comprovante-ponto">
      <p>{{message}}</p>
    </div>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="onDismiss()" rippleEffect>
      Cancelar (Esc)
    </button>
    <button *ngIf="!focusConfirm"  type="button" (click)="onConfirm()" #btnConfirm class="btn btn-success" rippleEffect>
      Confirmar
    </button>
    <button type="button"  *ngIf="focusConfirm" (click)="onConfirm()" #btnConfirm class="btn btn-success" rippleEffect>
      Confirmar (Enter)
    </button>
  </div>
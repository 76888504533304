import { Injectable } from "@angular/core";
import { FileService } from "@core/services/file.service";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { DocumentMockupModel } from "services/models/documents/documentmockup.model";
import { DocumentMockupVariableGroupModel, DocumentMockupVariableModel } from "services/models/documents/documentmockupVariable.model";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";

type VariableTableSubjectType = {
	variables: DocumentMockupVariableModel[]
}

type VariableTableSubjectOutput = {
	htmlTable: string;
	variables: DocumentMockupVariableModel[]
}

type ModeVisualizationFormSubjectType = {
	state: 'enabled' | 'disabled'
}

type ModeVisualizationFormSubjectOutput = {
	state: 'enabled' | 'disabled'
}

@Injectable()
export class DocumentMockupService extends ServiceBase<DocumentMockupModel> {
	private variablesTableSubject = new Subject<VariableTableSubjectOutput>();
	public variablesTable$ = this.variablesTableSubject.asObservable();

	private stateFormSubject = new Subject<ModeVisualizationFormSubjectOutput>();
	public stateForm$ = this.stateFormSubject.asObservable();

	constructor(http: HttpService, private file: FileService) {
		super("documentmockups", http, environment.apiUrl);
	}

	createEntity(input: any): DocumentMockupModel {
		return DocumentMockupModel.create(input);
	}

	getVariables(): Observable<Response<DocumentMockupVariableGroupModel[]>> {
		return this.http
			.get('variables/list-grouped', null, null, null, true)
			.pipe(
				map((res) => {
					const response = new Response<DocumentMockupVariableGroupModel[]>();

					response.data = res.body.map((pp) => DocumentMockupVariableGroupModel.create(pp) as DocumentMockupVariableGroupModel);
					response.count = res.headers.get('x-count');

					return response;
				})
			);
	}

	saveTemplate(data: any) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}`, data).subscribe(response => {
				observer.next(response);
				observer.complete()
			})
		})
	}

	handleOpenFilePdf(id: string) {
		this.http.get(`${this.endpoint}/${id}/preview`).subscribe(({ body }) => {
			const type = "pdf";
			const blob = this.file.base64ToBlob(body.fileContents);

			this.file.saveFile(blob, `${body.fileDownloadName}.${type}`);

			return blob;
		}, (error) => {
			console.log('error service', error)
		})
	}

	emitEventChangeModeVisualizationForm(data: ModeVisualizationFormSubjectType) {
		this.stateFormSubject.next(data);
	}

	emitEventToSaveVariablesTable(data: VariableTableSubjectType) {
		const htmlTable = this.generateTableByVariables(data.variables);

		this.variablesTableSubject.next({ htmlTable, ...data });
	}

	private generateTableByVariables(variables: DocumentMockupVariableModel[]): string {
		const numberOfColumns = variables.length;

		let table = '<table border="1" cellpadding="0" cellspacing="0" style="width:100%"><thead><tr>';

		for (let i = 0; i < numberOfColumns; i++) {
			table += `<th>${variables[i].title}</th>`;
		}

		table += '</tr></thead><caption></caption><tbody><tr>';

		for (let i = 0; i < numberOfColumns; i++) {
			table += `<td>${variables[i].key}</td>`;
			// table += `<td></td>`;
		}

		table += '</tr></tbody></table>';

		return table;
	}
}

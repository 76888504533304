/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionModel } from 'services/models/model';
import { QueryParams } from 'services/models/odata/queryParams';
import { TableHeaderFilterComponentService } from './table-header-filter.service';
import { isNotNumber, isNullOrEmpty } from 'utils/helps';

@Component({
  selector: 'th[header-filter]',
  templateUrl: './table-header-filter.component.html',
  styleUrls: ['./table-header-filter.component.scss'],
})
export class TableHeaderFilterComponent implements OnInit {

  @Output() filterEvent: EventEmitter<string> = new EventEmitter();
  @Output() isOpenChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() text = '';
  @Input() order = '';
  @Input() model: any;
  @Input() defaultValue: any;
  @Input() property = '';
  @Input() isFiltred: boolean = false;
  @Input() optionalFilter = false;
  @Input() queryParams: QueryParams = new QueryParams();
  @Input() lista: string[] = [];
  @Input() listSource: OptionModel[] = [];

  classValue = 'filter-icon';

  constructor(private _service: TableHeaderFilterComponentService) { }

  ngOnInit() { 
    setTimeout(() => {
      if (this.defaultValue !== undefined || this.defaultValue != null) {
        this.model = this.defaultValue;
        this.filterFunction();
      }
      if (this.property) {
        this._service.addSubject(this.property);
        this._service.getSubClearByName(this.property).subscribe((x) => {
          this.model = "";
        });
      }
    }, 300);
  }

  openChange() {
    this.isOpenChangeEvent.emit();
  }

  filterFunction = () => {
    this.model = this.validateFilterValue();
    this.changeIconColor();
    this.filterEvent.emit(this.model);
  };

  listChange(event: any) {
    this.model = event.target.value;
    this.filterFunction();
  }

  validateFilterValue() {
    if (
      this.model === "" ||
      typeof this.model === "boolean" ||
      this.model instanceof Boolean
    ) {
      return this.model;
    } else {
      return isNotNumber(this.model)
        ? this.model
        : +String(this.model).replace(",", ".");
    }
  }

  modelIsNullOrEmpty() {
    return isNullOrEmpty(this.model);
  }

  iconArrow(): string {
    if (this.queryParams?.orderByOrder === 'asc') {
      return 'arrow-up';
    }
    return 'arrow-down';
  }

  changeIconColor() {
    this.classValue = this.model ? "filter-icon-filtered" : "filter-icon";
  }

  get hasFilter(): boolean {
    return this.model !== "" && this.model != null && this.model.length > 0;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { BenefitTypeEnum } from "services/enums/benefitsType.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import { PetitionsRequestTypeEnum } from "services/enums/petitionsRequestType.enum";

export class BenefitEnableModel extends Model implements IModel<BenefitEnableModel> {
	benefitOfferedId: string = "";
	petitionId: string | undefined = undefined;
	benefitType: string = "";
	description: string = "";
	entryMode: string = "";
	logo: string = "";
	name: string = "";
	providerId: string = "";
	requestType: string = "";
	benefitEmployeeId: string = "";

	get benefitTypeLabel(): string {
		return BenefitTypeEnum.getBy(this.benefitType).label;
	}

	get benefitTypeBadge(): string {
		const type = BenefitTypeEnum.getBy(this.benefitType);
		return `<span class="badge badge-light-primary text-wrap">${type?.label}</span>`;
	}

	get requestTypeBadge(): string {
		const status = PetitionsRequestTypeEnum.getBy(this.requestType);
		return `<span class="badge badge-light-${status?.className} text-wrap">${status?.label}</span>`;
	}

	static create(input: any): BenefitEnableModel {
		return ObjectMapper.deserialize<BenefitEnableModel>(BenefitEnableModel, input);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

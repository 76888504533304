import { BaseEnum } from "./baseEnum";

const listEnum: Array<ExitInterviewStatusType> = [];

export class ExitInterviewStatusType extends BaseEnum {
  static CREATED = new ExitInterviewStatusType(0, 'CREATED', 'Cadastrada', 'primary');
  static PENDING = new ExitInterviewStatusType(1, 'PENDING', 'Pendente', 'warning');
  static LATE = new ExitInterviewStatusType(2, 'LATE', 'Atrasada', 'danger');
  static REALIZED = new ExitInterviewStatusType(3, 'REALIZED', 'Realizada', 'success');
  static FINISHED = new ExitInterviewStatusType(4, 'FINISHED', 'Encerrada', 'dark');

  constructor(
    public id: number,
    public value: string,
    public label: string,
    public className: string
  ) {
    super(id, value, label, className);
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}

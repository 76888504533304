import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@core/components/toasts/toasts.service";
import { FormAbstact } from "app/views/pages/abstract/form-abstract";
import moment from "moment-business-days";
import { CustomValidators } from "ng2-validation";
import { CostCenterService } from "services/corporate/costcenter.service";
import { EmployeeService } from "services/corporate/employee.service";
import { WorkforceplanMovementType } from "services/enums/WorkforceplanMovementType.enum";
import { TimeShiftService } from "services/hiring/timeShift.service";
import { TimeShiftModel } from "services/models/hiring/timeShift.model";
import { OptionModel } from "services/models/model";
import {
	WorkforcePlanTeamEmployeeModel,
} from "services/models/workforceplan";
import { WorkforcePlanDrawerEmployeeService } from "services/workforceplan/workforceplan-employee.service";
import {
	DrawerStatus,
	EmployeeResignation,
	FormCostCenterValues,
	FormCostCenterPayload,
	RequestTrainingValues,
	RequestTrainingPayload,
	FormJobTitleChangeValues,
	FormJobTitleChangePayload,
	FormRequestDismissalValues,
	FormRequestDismissalPayload,
	FormRequestHiringIncreaseValues,
	FormRequestHiringIncreasePayload,
	FormRequestHiringReplacementValues,
	FormRequestHiringReplacementPayload,
	DrawerDataInput,
} from './form-types'
import { WorkforcePlanSolicitationStepModel } from "services/models/workforceplan/workforceplan-solicitation.model";
import { WorkforceplanService } from "services/workforceplan/workforceplan-main.service";
import { MovementRequestRaiseType } from "services/enums/MovementRequestRaiseType.enum";
import { JobTitleService } from "services/hiring/jobTitle.service";
import { WorkforceplanEmployeeStatusType } from "services/enums/WorkforceplanEmployeeStatusType.enum";

type ListsOptions = {
	jobTitleOptions?: OptionModel[];
	hiringTypeOptions?: OptionModel[];
	timeShiftsOptions?: TimeShiftModel[];
	centerCostsOptions?: OptionModel[];
	jobTitleGroupOptions?: OptionModel[];
	resignationTypesOptions?: OptionModel[];
	employeesResignationsOptions?: EmployeeResignation[];
}

type SolicitationUpdateStatusData = {
	movementRequestId: string;
	requesterId: string;
	approverId: string;
	approvalScopeItemId: string;
	status: number;
	effectiveDate: string;
	note: string;
}

enum MovementStatusType {
	NONE = 0,
	PENDING = 1,
	APPROVED = 2,
	REJECTED = 3
}

@Component({
	selector: "app-shared-drawer-workforceplan-solicitation",
	templateUrl: "./workforce-plan-drawer-team-solicitation.component.html",
	styleUrls: ["./workforce-plan-drawer-team-solicitation.component.scss"],
})
export class AppSharedDrawerWorkforceplanSolicitationComponent extends FormAbstact<WorkforcePlanTeamEmployeeModel, WorkforcePlanDrawerEmployeeService> implements OnInit {
	@Input() data!: DrawerDataInput;

	@Input() source: "action-required" | null = null;

	@Output() onSuccess = new EventEmitter();

	@Output() handleClose = new EventEmitter();

	public lists: ListsOptions = {};

	public drawerTitle = "";

	public drawerStatus: DrawerStatus = "loading";

	public MovementType = WorkforceplanMovementType

	public isSubmittingForm = false;

	public justification = "";

	public DrawerTypes = {
		SHIFT_CHANGE: "SHIFT_CHANGE",
		JOBTITLE_CHANGE: "JOBTITLE_CHANGE",
		REQUEST_TRAINING: "REQUEST_TRAINING",
		EMPLOYEE_DETAILS: "EMPLOYEE_DETAILS",
		REQUEST_DISMISSAL: "REQUEST_DISMISSAL",
		CENTER_COST_CHANGE: "CENTER_COST_CHANGE",
		REQUEST_HIRING_EXPANSION: "REQUEST_HIRING_EXPANSION",
		REQUEST_HIRING_REPLACEMENT: "REQUEST_HIRING_REPLACEMENT",
		REQUEST_HIRING_REPLACEMENT_POSITION: "REQUEST_HIRING_REPLACEMENT_POSITION",
		SOLICITATION_DETAILS: 'SOLICITATION_DETAILS',
		REQUEST_WORK_FORCEPLAN: 'REQUEST_WORK_FORCEPLAN'
	};

	private minDate = moment().add(1, "d").format("yyyy-MM-DD");

	constructor(
		router: Router,
		service: WorkforcePlanDrawerEmployeeService,
		activeRoute: ActivatedRoute,
		toasterService: ToastService,
		private _formBuilder: FormBuilder,
		private _employeeService: EmployeeService,
		private _jobTitleService: JobTitleService,
		private _timeShiftService: TimeShiftService,
		private _costCenterService: CostCenterService,
		private _workforceplanService: WorkforceplanService,
	) {
		super(service, activeRoute, router, toasterService);
	}

	ngOnInit() {
		this.initializeForm();
	}

	ngOnDestroy() { }

	public handleCancel() {
		this.handleClose.emit();
	}

	public handleSubmit() {
		if (!this.validaForm()) {
			return;
		}

		try {
			this.isSubmittingForm = true;

			const payload = this._getPayload();

			if (this.data.mode === 'details') {
				payload.id = this.data.solicitation.id;
			}

			const method = payload.id ? 'updateMovementRequest' : 'createMovementRequest'

			this._service[method](this.data.workforcePlanId, payload).subscribe(
				() => {
					this.toasterAbstract.showSuccess("Solicitação realizada com sucesso.");

					this.isSubmittingForm = false;

					this.onSuccess.emit();
				},
				() => {
					this.isSubmittingForm = false;
				}
			);
		} catch (error) {
			console.error(error);

			this.toasterAbstract.showError("Ocorreu um erro inesperado");

			this.isSubmittingForm = false;
		}
	}

	public handleSubmitDecline() {
		this.isSubmittingForm = true;

		const payload = this._getPayloadUpdateStatusSolicitation(MovementStatusType.REJECTED);

		this._workforceplanService.postWorkforcePlanSolicitationUpdateStatus(payload.movementRequestId, payload).subscribe(
			() => {
				this.toasterAbstract.showSuccess("Solicitação de movimentação realizada com sucesso.");

				this.isSubmittingForm = false;

				this.onSuccess.emit({ shouldRefetch: true });
			}, (error) => {
				console.error(error);

				this.isSubmittingForm = false;
			})
	}

	public handleSubmitApprove() {
		this.isSubmittingForm = true;

		const payload = this._getPayloadUpdateStatusSolicitation(MovementStatusType.APPROVED);

		this._workforceplanService.postWorkforcePlanSolicitationUpdateStatus(payload.movementRequestId, payload).subscribe(
			() => {
				this.toasterAbstract.showSuccess("Solicitação de movimentação realizada com sucesso.");

				this.isSubmittingForm = false;

				this.onSuccess.emit({ shouldRefetch: true });
			}, (error) => {
				console.error(error);

				this.isSubmittingForm = false;
			})
	}

	public onEmplooyeeChanged(employee: EmployeeResignation) {
		this.form.patchValue({
			employee: employee,
			jobTitleId: employee.jobTitleId,
			timeShiftId: employee.timeshiftId,
			deadline: employee.resignationDate.substring(0, 10)
		});
	}

	public onCenterCostChanged(data: OptionModel) {
		this._updateFieldValidatorByValue('newJobTitleId', data)
	}

	public onJobTitleChange(data: OptionModel) {
		this._updateFieldValidatorByValue('newCostCenterId', data)
	}

	public getEmployeeJoTitleName() {
		return this.data.employee?.jobTitleName ? this.data?.employee?.jobTitleName : this.data?.employee?.jobTitleGroupName
	}

	public onEffectiveDateChange(ev: any) {
		this.minDate = moment(ev.target.value).add(1, "d").format("YYYY-MM-DD");

		this.form.get("endTrainingDate").setValidators([CustomValidators.minDate(this.minDate)]);

		this.form.get("endTrainingDate").updateValueAndValidity();
	}

	public onJobTitleGroupChanged(option: OptionModel) {
		if (!option) {
			this.lists.jobTitleOptions = [];

			return;
		}

		this._jobTitleService.getJobTitlesByTitleAndGroup(this.data.employee.companyId, option.value).subscribe(data => {
			this.lists.jobTitleOptions = data.map(item => ({ label: `${item.code} - ${item.title}`, value: item.id } as OptionModel))
		});
	}

	async initializeForm() {
		if (this.data?.mode === 'details') {
			await this._getSolicitationDetails();
		}

		this.drawerTitle = this.data?.solicitation?.movementTypeLabel;
		if (this.data?.mode === "add") {
			this.drawerTitle = WorkforceplanMovementType.getByEnumerator(this.data.type)?.label;
		}

		switch (this.data.type) {
			case "EMPLOYEE_DETAILS": {
				this.drawerTitle = 'Detalhe de colaborador';
				await this._loadEmployeeData();

				this.drawerStatus = "fetched";

				break;
			}
			case "REQUEST_TRAINING": {
				await this._loadFormRequestTraining();

				this.drawerStatus = "fetched";

				break;
			}
			case "JOBTITLE_CHANGE": {
				await this._loadFormChangeJobTitle();

				this.drawerStatus = "fetched";

				break;
			}
			case "CENTER_COST_CHANGE": {
				await this._loadFormChangeCenterCost();

				this.drawerStatus = "fetched";

				break;
			}
			case "REQUEST_DISMISSAL": {
				await this._loadFormRequestDismissal();

				break;
			}
			case "REQUEST_HIRING_EXPANSION": {
				await this._loadFormRequestHiringExpansion();

				this.drawerStatus = "fetched";

				break;
			}
			case "REQUEST_HIRING_REPLACEMENT": {
				await this._loadFormRequestHiringReplacement();

				this.drawerStatus = "fetched";

				break;
			}
			case "REQUEST_HIRING_REPLACEMENT_POSITION": {
				await this._loadFormRequestHiringReplacementPosition();

				this.drawerStatus = "fetched";

				break;
			}
			case "REQUEST_WORK_FORCEPLAN": {
				await this._loadFormRequestWorkForceplan();

				this.drawerStatus = "fetched";

				break;
			}
		}

		if (this.data?.mode === 'details') {
			this._loadFormSolicitationDetails();
		}
	}

	public get isDrawerOpened() {
		return true;
	}

	private async _loadFormRequestTraining() {
		await this._loadEmployeeData();

		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			movementType: [WorkforceplanMovementType.TRAINNING.id],
			employeeId: [this.data.employee.id],
			jobTitleId: [null, Validators.required],
			trainingType: [null, Validators.required],
			costCenterId: [null, Validators.required],
			effectiveDate: [
				this.minDate,
				[Validators.required, CustomValidators.minDate(this.minDate)],
			],
			workScaleGroup: [this.data?.workScaleGroup],
			jobTitleGroupId: [null, Validators.required],
			workforcePlanId: [this.data.workforcePlanId],
			endTrainingDate: [
				this.minDate,
				[Validators.required, CustomValidators.minDate(this.minDate)],
			],
		});

		this._loadCostCentersOptions();

		this._loadJobTitleGroupsByCompany();
	}

	private async _loadFormChangeJobTitle() {
		await this._loadEmployeeData();

		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			employeeId: [this.data.employee.id],
			movementType: [WorkforceplanMovementType.JOB_TITLE_CHANGE.id],
			newJobTitleId: [null, Validators.required],
			workScaleGroup: [this.data?.workScaleGroup],
			workforcePlanId: [this.data.workforcePlanId],
			currentJobTitleId: [this.data.employee.jobTitleId],
		});

		this._loadJobTitleOfPlanWorkScaleGroups();
	}

	private async _loadFormChangeCenterCost() {
		await this._loadEmployeeData();

		this.form = this._formBuilder.group({
			note: ["", Validators.required],
			employeeId: [this.data.employee.id],
			movementType: [WorkforceplanMovementType.COST_CENTER_CHANGE.id],
			newJobTitleId: [null, Validators.required],
			workScaleGroup: [this.data?.workScaleGroup],
			newCostCenterId: [null, Validators.required],
			workforcePlanId: [this.data.workforcePlanId],
			currentJobTitleId: [this.data.employee.jobTitleId],
			currentCostCenterId: [this.data.employee.centerCostId],
		});

		this._loadCostCentersOptions();

		this._loadJobTitlesByCompany();
	}

	private async _loadFormRequestDismissal() {
		await this._loadEmployeeData();

		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			employeeId: [this.data.employee.id],
			movementType: [WorkforceplanMovementType.DISMISSAL.id],
			effectiveDate: [null, Validators.required],
			workScaleGroup: [this.data?.workScaleGroup],
			workforcePlanId: [this.data.workforcePlanId],
			resignationReason: [null, Validators.required],
			wasEmployeeInitiative: [false, Validators.required],
		});

		this._loadResignationTypes();
	}

	private async _loadFormRequestHiringExpansion() {
		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			raiseType: [MovementRequestRaiseType.Expansion.value, Validators.required],
			jobTitleId: [null],
			timeShiftId: [null, Validators.required],
			movementType: [WorkforceplanMovementType.NEW_HIRING_INCREASE.id],
			effectiveDate: [
				this.minDate,
				[Validators.required, CustomValidators.minDate(this.minDate)],
			],
			workScaleGroup: [this.data.workScaleGroup],
			workforcePlanId: [this.data.workforcePlanId],
			jobTitleGroupId: [null, Validators.required],
		});

		this.lists.hiringTypeOptions = [
			{ label: MovementRequestRaiseType.Expansion.label, value: MovementRequestRaiseType.Expansion.value },
		];

		this._loadJobTitleOfPlanWorkScaleGroups();

		await this._loadTimeShiftsByCompany();
	}

	private async _loadFormRequestHiringReplacement() {
		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			deadline: [null],
			employee: [],
			raiseType: [MovementRequestRaiseType.Substitution.value, Validators.required],
			employeeId: [null, Validators.required],
			jobTitleId: ["00000000-0000-0000-0000-000000000000"],
			timeShiftId: ["00000000-0000-0000-0000-000000000000"],
			movementType: [WorkforceplanMovementType.NEW_HIRING_REPLACEMENT.id],
			effectiveDate: [null, Validators.required],
			workScaleGroup: [this.data.workScaleGroup],
			workforcePlanId: [this.data.workforcePlanId],
		});

		this._loadJobTitleOfPlanWorkScaleGroups();

		await this._loadTimeShiftsByCompany();

		await this._loadEmployeesInProcessResignation();
	}

	private async _loadFormRequestHiringReplacementPosition() {
		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			raiseType: [MovementRequestRaiseType.Replacement.value, Validators.required],
			jobTitleId: [this.data?.employee?.jobTitleId, Validators.required],
			timeShiftId: [null, Validators.required],
			movementType: [WorkforceplanMovementType.NEW_HIRING_REPLACEMENT.id],
			effectiveDate: [
				this.minDate,
				[Validators.required, CustomValidators.minDate(this.minDate)],
			],
			workScaleGroup: [this.data?.workScaleGroup],
			workforcePlanId: [this.data?.workforcePlanId],
			jobTitleGroupId: [this.data?.employee?.jobTitleGroupId, Validators.required],
		});

		this.lists.hiringTypeOptions = [
			{ label: MovementRequestRaiseType.Replacement.label, value: MovementRequestRaiseType.Replacement.value },
		];

		await this._loadTimeShiftsByCompany();
	}

	private async _loadFormRequestWorkForceplan() {
		this.form = this._formBuilder.group({
			note: [null, Validators.required],
			movementType: [WorkforceplanMovementType.WORK_FORCEPLAN.id],
			workforcePlanId: [this.data.workforcePlanId]
		});
	}

	private async _loadFormSolicitationDetails() {
		const { solicitation, solicitationDetails } = this.data;

		this.form = this._formBuilder.group({
			note: [solicitationDetails?.note],
			movementType: [solicitation?.movementTypeData?.id],
			workforcePlanId: [this.data.workforcePlanId],
			employeeId: [solicitation?.employeeId],
			jobTitleId: [solicitationDetails?.payloadData?.jobTitleId],
			workScaleGroup: [solicitation?.workScaleGroup],
			trainingType: [solicitationDetails?.payloadData?.trainingType],
			costCenterId: [solicitationDetails?.payloadData?.costCenterId],
			effectiveDate: [solicitation?.effectiveDate?.substring(0, 10)],
			jobTitleGroupId: [solicitationDetails?.payloadData?.jobTitleGroupId],
			endTrainingDate: [solicitationDetails?.payloadData?.endTrainingDate],
			newJobTitleId: [solicitationDetails?.payloadData?.newJobTitleId],
			currentJobTitleId: [solicitationDetails?.payloadData?.currentJobTitleId],
			newCostCenterId: [solicitationDetails?.payloadData?.newCostCenterId],
			currentCostCenterId: [solicitationDetails?.payloadData?.currentCostCenterId],
			resignationReason: [solicitationDetails?.payloadData?.resignationReason],
			wasEmployeeInitiative: [solicitationDetails?.payloadData?.wasEmployeeInitiative],
			raiseType: [solicitationDetails?.payloadData?.raiseType],
			timeShiftId: [solicitationDetails?.employeeTimeShiftId],
			deadline: [solicitationDetails?.payloadData?.deadline],
			employee: [],
		});

		if (this.data.type === 'REQUEST_HIRING_REPLACEMENT') {
			const employee = this.lists.employeesResignationsOptions.find(item => item.id === solicitation.employeeId);

			this.onEmplooyeeChanged(employee)
		}

		if (this.data.type === 'REQUEST_HIRING_EXPANSION') {
			this.form.patchValue({ timeShiftId: solicitationDetails?.payloadData?.timeShiftId })
		}

		if (this.data.type === 'REQUEST_TRAINING' && this.data.mode === 'details') {
			this.lists.jobTitleOptions = [{ label: solicitationDetails?.payloadData?.jobTitleName, value: solicitationDetails?.payloadData?.jobTitleId }]
		}

		if (!this.canUpdate) {
			this.form.disable();
		}
	}

	private async _loadEmployeeData() {
		try {
			this.drawerStatus = "loading";

			const employee = this.data.employee

			const { data } = await this._employeeService.get(employee.id, true).toPromise();

			employee.companyId = data.companyId;
			employee.admissionDate = data.admissionDate;
			employee.cellPhoneNumber = data.cellPhoneNumber;
			employee.profileImagePath = data.profileImagePath;
			employee.timeShiftId = data.timeShiftId;
			employee.centerCostId = data.costCenterId
			employee.costCenterName = data.costCenterName

			this.data.employee = {
				...data,
				...employee,
				statusLabel: WorkforceplanEmployeeStatusType.getBy(employee.status).label,
			} as any;

			this.drawerStatus = "fetched";
		} catch (error) {
			console.error(error);

			this.drawerStatus = "error";
		}
	}

	private _loadJobTitleOfPlanWorkScaleGroups() {
		const jobTitles = new Map();
		const jobTitleGroups = new Map();

		this.data.workScaleGroups.forEach((item) => {
			item.amounts.forEach(({ jobTitleId, jobTitleName, jobTitleGroupId, jobTitleGroupName }) => {
				if (jobTitleGroupId) {
					jobTitleGroups.set(jobTitleGroupId, { jobTitleGroupId, jobTitleGroupName });
				}
				if (jobTitleId) {
					jobTitles.set(jobTitleId, { jobTitleId, jobTitleName });
				}
			});
		});

		this.lists.jobTitleOptions = [...jobTitles.values()].map(
			(item) => ({ label: item.jobTitleName, value: item.jobTitleId } as OptionModel)
		);

		this.lists.jobTitleGroupOptions = [...jobTitleGroups.values()].map(
			(item) => ({ label: item.jobTitleGroupName, value: item.jobTitleGroupId } as OptionModel)
		);
	}

	private _loadJobTitlesByCompany() {
		this._jobTitleService.getJobTitlesByCompany(this.data.employee.companyId).subscribe((data) => {
			this.lists.jobTitleOptions = data.map(item => ({ label: `${item.code} - ${item.title}`, value: item.id } as OptionModel));
		});
	}

	private _loadJobTitleGroupsByCompany() {
		this._jobTitleService.getJobTitleGroupsByCompany(this.data.employee.companyId).subscribe(({ data }) => {
			this.lists.jobTitleGroupOptions = data;
		});
	}

	private _loadResignationTypes() {
		this._service
			.getListResignationTypes()
			.subscribe((data) => (this.lists.resignationTypesOptions = data));
	}

	private async _loadTimeShiftsByCompany() {
		try {
			const params = {
				companyId: this.data.companyId,
				workScaleGroup: this.data.workScaleGroup,
			};

			const data = await this._timeShiftService.loadTimeShiftsByParams(params).toPromise();

			this.lists.timeShiftsOptions = data
		} catch (error) {
			console.log(error);

			this.lists.timeShiftsOptions = [];
		}
	}

	private async _loadEmployeesInProcessResignation() {
		try {

			const data = await this._service.getListEmployeesInResignation(this.data.workforcePlanId).toPromise();

			this.lists.employeesResignationsOptions = data;
		} catch (error) {
			console.error(error);

			this.lists.employeesResignationsOptions = [];
		}
	}

	private _loadCostCentersOptions() {
		this._costCenterService
			.listByHierarchyAndCompany(this.data.companyId)
			.subscribe((response) => {
				this.lists.centerCostsOptions = response.data.map((item) => item.toOption());
			});
	}

	private _getJobTitleGroupOption(jobTitleGroupId: string) {
		const { label } = this.lists.jobTitleGroupOptions.find(item => item.value === jobTitleGroupId);

		return label
	}

	private _getJobTitleOption(jobTitleId: string) {
		const { label } = this.lists.jobTitleOptions.find(item => item.value === jobTitleId);

		return label
	}

	private _getTimeshiftTitleOption(timeshiftId: string) {
		const { title } = this.lists.timeShiftsOptions.find(item => item.id === timeshiftId);

		return title
	}

	private _getCostCenterTitleOption(costCenterId: string) {
		const { label } = this.lists.centerCostsOptions.find(item => item.value === costCenterId);

		return label
	}

	private _updateFieldValidatorByValue(fieldName: 'newJobTitleId' | 'newCostCenterId', value: any) {
		const field = this.form.get(fieldName);

		if (value) {
			field.setValidators(null);
		} else {
			field.setValidators(Validators.required);
		}

		field.updateValueAndValidity();
	}

	private _getPayload() {
		switch (this.data.type) {
			case "REQUEST_TRAINING": {
				const {
					note,
					employeeId,
					jobTitleId,
					movementType,
					trainingType,
					effectiveDate,
					workforcePlanId,
					costCenterId,
					jobTitleGroupId,
					endTrainingDate,
					workScaleGroup
				} = this.form.value as RequestTrainingValues

				const jobTitleName = this._getJobTitleOption(jobTitleId);
				const jobTitleGroupName = this._getJobTitleGroupOption(jobTitleGroupId);
				const costCenterName = this._getCostCenterTitleOption(costCenterId);

				return {
					note,
					employeeId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						jobTitleId,
						jobTitleName,
						jobTitleGroupId,
						jobTitleGroupName,
						costCenterId,
						costCenterName,
						trainingType,
						endTrainingDate
					}),
				} as RequestTrainingPayload;
			}
			case "JOBTITLE_CHANGE": {
				const {
					note,
					employeeId,
					movementType,
					newJobTitleId,
					workScaleGroup,
					workforcePlanId,
					currentJobTitleId,
				} = this.form.value as FormJobTitleChangeValues;

				const newJobTitleName = this._getJobTitleOption(newJobTitleId);
				const currentJobTitleName = this.data?.employee?.jobTitleName;

				return {
					note,
					employeeId,
					movementType,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						newJobTitleId,
						newJobTitleName,
						currentJobTitleId,
						currentJobTitleName,
					}),
				} as FormJobTitleChangePayload;
			}
			case "CENTER_COST_CHANGE": {
				const {
					note,
					employeeId,
					movementType,
					workScaleGroup,
					workforcePlanId,
					currentCostCenterId,
					currentJobTitleId,
					newCostCenterId,
					newJobTitleId,
				} = this.form.value as FormCostCenterValues;

				const currentJobTitleName = this.data?.employee?.jobTitleName;
				const currentCostCenterName = this.data?.employee?.costCenterName

				const jobTitleInfos = newJobTitleId ? { currentJobTitleId, currentJobTitleName, newJobTitleId, newJobTitleName: this._getJobTitleOption(newJobTitleId) } : {}
				const costCenterInfos = newCostCenterId ? { currentCostCenterId, currentCostCenterName, newCostCenterId, newCostCenterName: this._getCostCenterTitleOption(newCostCenterId) } : {}

				return {
					note,
					employeeId,
					movementType,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						...jobTitleInfos,
						...costCenterInfos,
					}),
				} as FormCostCenterPayload;
			}
			case "REQUEST_DISMISSAL": {
				const {
					note,
					employeeId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					resignationReason,
					wasEmployeeInitiative
				} = this.form.value as FormRequestDismissalValues

				return {
					note,
					employeeId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						resignationReason,
						wasEmployeeInitiative,
					}),
				} as FormRequestDismissalPayload;
			}
			case "REQUEST_HIRING_EXPANSION": {
				const {
					note,
					raiseType,
					jobTitleId,
					timeShiftId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					jobTitleGroupId,
				} = this.form.value as FormRequestHiringIncreaseValues;

				const jobTitleGroupName = this._getJobTitleGroupOption(jobTitleGroupId);
				const timeShiftName = this._getTimeshiftTitleOption(timeShiftId);

				const jobTitleInfos = !!jobTitleId ? { jobTitleId, jobTitleName: this._getJobTitleOption(jobTitleId) } : {};

				return {
					note,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						raiseType,
						timeShiftId,
						timeShiftName,
						jobTitleGroupId,
						jobTitleGroupName,
						...jobTitleInfos
					}),
				} as FormRequestHiringIncreasePayload;
			}
			case "REQUEST_HIRING_REPLACEMENT": {
				const {
					note,
					deadline,
					raiseType,
					employeeId,
					jobTitleId,
					timeShiftId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId
				} = this.form.value as FormRequestHiringReplacementValues

				const jobTitleName = this.employeeSelected?.jobTitleName;
				const timeShiftName = this.employeeSelected?.timeshiftTitle

				return {
					note,
					employeeId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						deadline,
						raiseType,
						jobTitleId,
						timeShiftId,
						jobTitleName,
						timeShiftName,
					}),
				} as FormRequestHiringReplacementPayload;
			}
			case "REQUEST_HIRING_REPLACEMENT_POSITION": {
				const {
					note,
					raiseType,
					jobTitleId,
					timeShiftId,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					jobTitleGroupId,
				} = this.form.value as FormRequestHiringIncreaseValues;

				const timeShiftName = this._getTimeshiftTitleOption(timeShiftId);
				const jobTitleInfos = this.data?.employee?.jobTitleName ? { jobTitleId, jobTitleName: this.data?.employee?.jobTitleName } : {}
				const jobTitleGroupInfos = this.data?.employee?.jobTitleGroupName ? { jobTitleGroupId, jobTitleGroupName: this.data?.employee?.jobTitleGroupName } : {}

				return {
					note,
					movementType,
					effectiveDate,
					workScaleGroup,
					workforcePlanId,
					payload: JSON.stringify({
						raiseType,
						timeShiftId,
						timeShiftName,
						...jobTitleInfos,
						...jobTitleGroupInfos
					}),
				} as FormRequestHiringIncreasePayload;
			}
		}
	}

	private _getPayloadUpdateStatusSolicitation(status: number) {
		const payload = {
			note: this.justification,
			status,
			effectiveDate: this.data?.solicitation?.effectiveDate.substring(0, 10),
			movementRequestId: this.data?.solicitation?.id,
			approvalScopeItemId: this.data?.solicitation?.approvalScopeItemId,
		} as SolicitationUpdateStatusData

		return payload;
	}

	public getDateFormated(value: string) {
		return moment(new Date(value)).utc(false).format('DD/MM/yyyy')
	}

	public getEffectiveDateFormated() {
		return this.getDateFormated(this.data?.solicitation?.effectiveDate)
	}

	public getColorIndicator(status: 'APPROVED' | 'REJECTED' | 'NONE' | 'PENDING') {
		switch (status) {
			case 'APPROVED':
				return 'bg-success';
			case 'REJECTED':
				return 'bg-danger';
			case 'NONE':
			case 'PENDING':
				return 'bg-secondary';
			default:
				return 'bg-transparent';
		}
	}

	public getApprovalResponsible(step: WorkforcePlanSolicitationStepModel) {
		const isPending = !['APPROVED', 'REJECTED'].includes(step.status);
		if (isPending) return 'PENDENTE';

		return `${step.approverName ? `${step.approverName} - ${moment(step.effectiveDate).format('DD/MM/YYYY HH:mm')} 
			${step.approverJobTitleName ? `- ${step.approverJobTitleName}` : ''}` : ''}`;
	}

	private async _getSolicitationDetails() {
		const { id: movementRequestId, approvalScopeItemId } = this.data.solicitation;

		const response = await this._workforceplanService.getWorkforcePlanSolicitationDetails(movementRequestId, approvalScopeItemId).toPromise()

		this.data.solicitationDetails = response;
	}

	public get employeeSelected() {
		return this.form.value?.employee;
	}

	public get isSolicitationPending() {
		return this.data?.solicitation?.status === 'PENDING'
	}

	public get canUpdate() {
		return this.source !== "action-required" && this.data?.solicitationDetails?.permission?.canUpdate
	}

	public get canExecute() {
		return this.isSolicitationPending && this.data?.solicitationDetails?.permission?.canExecute;
	}

	public get isDrawerSRequestWorkforcePlanDetails() {
		return this.data?.mode === 'details' && this.data?.type === this.DrawerTypes.REQUEST_WORK_FORCEPLAN
	}
}

<div class="modal-header">
  <h4 class="modal-title" id="myModalLabel1">Programação de Férias - {{employee.name}}</h4>
  <button type="button" (click)="onDismiss()" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-12">
      <p><strong>CPF:</strong> {{employee.cpf | cpf}}</p>
      <p><strong>Matrícula:</strong> {{employee.registration}}</p>
      <p><strong>Data Período Inicial:</strong> {{referencePeriod.dateBegin | date: 'dd/MM/yyyy'}} </p>
      <p><strong>Dias de Direito:</strong> {{referencePeriod.daysOfLaw}} </p>
    </div>
    <div class="col-lg-4 col-md-4 col-12">
      <p><strong>Empresa:</strong> {{employee.companyName}} </p>
      <p><strong>Turno:</strong> {{employee.timeShiftTitle}} </p>
      <p><strong>Data Período Final:</strong> {{referencePeriod.dateEnd | date: 'dd/MM/yyyy'}} </p>
      <p><strong>Dias Antecipados:</strong> {{referencePeriod.paidDays}} </p>
    </div>
    <div class="col-lg-4 col-md-4 col-12">
      <p><strong>Centro de Custo:</strong> {{employee.costCenterName}} </p>
      <p><strong>Data de Admissão:</strong> {{employee.admissionDate | date: 'dd/MM/yyyy'}}</p>
      <p><strong>Data Limite Gozo:</strong> {{referencePeriod.dateLimit | date: 'dd/MM/yyyy'}}</p>
      <p><strong>Dias Disponíveis:</strong> {{referencePeriod.daysOfLaw - referencePeriod.scheduledDays + referencePeriod.scheduledAllowanceDays}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div *ngIf="vacationSchedules.length > 0" class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>AÇÕES</th>
              <th *ngFor="let header of headers">{{header}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of vacationSchedules; let i = index">
              <td class="custom-th">
                <div ngbDropdown id="action-open-menu" container="body">
                  <button class="btn p-0 no-dropdown-toggle-icon" type="button" ngbDropdownToggle>
                    <i class="icon-lg pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-left">
                    <a ngbDropdownItem (click)="manageVacationSchedule(item)" 
                      id="action-edit" [disabled]="!canChange(item.status)">
                      <i data-feather="edit" appFeatherIcon></i> Editar
                    </a>
                    <a [disabled]="!canApprove(i, item.status)" *ngIf="item.status === 'Pending' && !isSameUser && canView()" 
                      ngbDropdownItem (click)="manageVacationSchedule(item, true)" id="action-aprove">
                      <i data-feather="check-square" class="text-primary" appFeatherIcon> </i> Aprovar/Rejeitar
                    </a>
                    <a [disabled]="!canRemove(i, item.status)" ngbDropdownItem *ngIf="canView()"
                      (click)="removeVacationSchedule(item, true)" id="action-delete">
                      <i data-feather="trash" class="text-danger" appFeatherIcon> </i> Excluir
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <span *ngIf="!canManagerApprove(i)" class="text-primary" popoverTitle="Informativo"
                ngbPopover="Solicitação realizada após exceder dia de corte." triggers="mouseenter:mouseleave">
                  <span [data-feather]="'info'"></span>
                </span>

                {{ item.date | date: 'dd/MM/yyyy' }}
              </td>
              <td>{{ item.status !== 'Approved' ? '-' : item.sequence }}</td>
              <td>{{ item.amountDaysVacation }}</td>
              <td>{{ item.amountDaysAllowance }}</td>
              <td [innerHTML]="item.statusBadge"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="vacationSchedules.length == 0" class="row table-spacing">
        <h4 class="no-items">Sem histórico para exibir.</h4>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="onDismiss()" rippleEffect>
    Fechar
  </button>
  <button type="button" (click)="addVacationSchedule()" #btnConfirm class="btn btn-success" rippleEffect>
    Incluir
  </button>
</div>
import { ObjectMapper } from "json-object-mapper";
import { OptionModel } from "./model";

export class DynamicModel {
  type: TimeShiftEnum = undefined;
  options: DynamicOptionModel[] = [];

  static create(input: any): DynamicModel {
    return ObjectMapper.deserialize<DynamicModel>(DynamicModel, input);
  }
}

export class DynamicOptionModel {
  id: string | undefined = undefined;
  description: string | undefined = undefined;

  static create(input: any): DynamicOptionModel {
    return ObjectMapper.deserialize<DynamicOptionModel>(DynamicOptionModel, input);
  }

  toOption(): OptionModel {
    return { label: this.description, value: this.id } as OptionModel;
  }
}

export enum TimeShiftEnum {
  Table = 1,
  Sequence = 2
}

import { Injectable } from "@angular/core";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { PointTimeExceptionModel } from "services/models/corporate/pointTimeException.model";
import { Observable } from "rxjs";
import { Response } from 'services/models/response';
import { map } from "rxjs/operators";

@Injectable()
export class PointTimeExceptionService extends ServiceBase<PointTimeExceptionModel> {
  constructor(http: HttpService) {
    super("PointTimeExceptions", http, environment.apiUrl);
  }

  createEntity(input: any): PointTimeExceptionModel {
    return PointTimeExceptionModel.create(input);
  }

  remove(id: string) {
    return this.http.remove(`${this.endpoint}/${id}`);
  }

  listPointTimeExceptionsByEmployee(employeeId: string): Observable<Response<PointTimeExceptionModel[]>> {
    return this.http
      .get(`${this.endpoint}/list-pointtime-exceptions-by-employeeId/${employeeId}`)
      .pipe(
        map((res) => {
          const response = new Response<PointTimeExceptionModel[]>();
          response.data = res.body.map((point: any) => PointTimeExceptionModel.create(point));
          response.count = res.headers.get('x-count');
          return response;
        })
      );
  }
}

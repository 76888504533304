import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-choose-report-dialog",
  templateUrl: "./choose-report-dialog.component.html",
  styleUrls: ["./choose-report-dialog.component.scss"],
})
export class ChooseReportDialogComponent implements OnInit {
  availablesReports: { label: string; index: number }[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  choose(idx: number): void {
    this.activeModal.close(idx);
  }

  onDismiss(): void {
    this.activeModal.close(0);
  }
}

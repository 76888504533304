import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';
import buildQuery from 'odata-query';
import { Observable } from 'rxjs';
import { EmployeeModel } from "services/models/employee.model";
import { HttpService } from "services/http.service";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { map } from "rxjs/operators";
import { SummaryEmployeeManagementModel } from "services/models/corporate/summaryEmployeeManagement.model";

@Injectable()
export class EmployeeManagementService {
  private endpoint = 'EmployeeManagement';
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpService) { }

  listEmployeesByHierarchy(
    params: QueryParams = null,
  ): Observable<Response<EmployeeModel[]>> {
    return this.customList<EmployeeModel>(
      params, `list-employees-by-hierachy`, EmployeeModel
    );
  }

  listEmployeesWithOccurrences(
    params: QueryParams = null,
    subtype: number = 0
  ): Observable<Response<EmployeeModel[]>> {
    return this.customList<EmployeeModel>(
      params, `list-employees-with-occurrences/${subtype}`, EmployeeModel
    );
  }

  listEmployeesWithInfringements(
    params: QueryParams = null,
  ): Observable<Response<EmployeeModel[]>> {
    return this.customList<EmployeeModel>(
      params, `list-employees-with-infringements`, EmployeeModel
    );
  }

  getSummary(): Observable<Response<SummaryEmployeeManagementModel>> {
    return this.http
      .get(`${this.endpoint}/summary`, undefined, this.baseUrl)
      .pipe(
        map((res) => {
          const response = new Response<SummaryEmployeeManagementModel>();
          response.data = SummaryEmployeeManagementModel.create(res.body);
          return response;
        })
      );
  }

  createBuildQuery(params: QueryParams) {
    const select = buildQuery({ select: params.select });
    const top = params.top
      ? buildQuery({ top: params.top }).replace('?', '&')
      : buildQuery({ top: 999 }).replace('?', '&');
    const skip = params.skip
      ? buildQuery({ skip: params.skip }).replace('?', '&')
      : '';
    const filter = params.filter ? params.filter.replace('?', '&') : '';
    const orderBy = params.orderBy
      ? buildQuery({
        orderBy: `${params.orderBy} ${params.orderByOrder}`,
      }).replace('?', '&')
      : '';
    const expand = params.expand
      ? buildQuery({ expand: params.expand }).replace('?', '&')
      : '';

    const url = `${select}${expand}${top}${skip}${filter}${orderBy}`;
    return url;
  }

  customList<Type>(
    params: QueryParams,
    action: string,
    modelEntity: any
  ): Observable<Response<Type[]>> {
    const defaultSelect = ['id', 'enable', 'creationDate'];
    if (params && params.select)
      params.select = [
        ...params.select.filter((field) => !defaultSelect.includes(field)),
        ...defaultSelect,
      ];

    const query = params ? this.createBuildQuery(params) : '';
    params.filter = '';
    return this.http
      .get(`${this.endpoint}/${action}${query}`, undefined, this.baseUrl, null, true)
      .pipe(
        map((res) => {
          const response = new Response<Type[]>();
          response.data = res.body.map((pp: any) => {
            return modelEntity.create(pp) as Type;
          });
          response.count = res.headers.get('x-count');
          return response;
        })
      );
  }
}
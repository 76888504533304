import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-form-debug',
  templateUrl: './form-debug.component.html',
  styleUrls: ['./form-debug.component.scss']
})
export class FormDebugComponent implements OnInit {

  @Input() form;

  erroList: any = [];

  constructor() { }

  ngOnInit() {
    console.log(this.form);
  }
}

import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";
import { BenefitTypeEnum } from "services/enums/benefitsType.enum";

export class BenefitsEmployeeModel
  extends Model
  implements IModel<BenefitsEmployeeModel>
{
  benefitPlanId: string | undefined = undefined;
  dateBegin: string = "";
  dateEnd: string = "";
  employeeId: string | undefined = undefined;
  currentPetitionId: string | undefined = undefined;
  benefitType: string = "";
  benefitName: string = "";
  isForDependents: boolean | undefined = undefined;
  hasDependents: string | undefined = undefined;

  get customizeBenefit(): string {
    return `<b class="text-primary">${this.benefitName}</b><br>${this.benefitTypeLabel}`;
  }

  get benefitTypeLabel(): string {
    return BenefitTypeEnum.getBy(this.benefitType).label;
  }

  static create(input: any): BenefitsEmployeeModel {
    return ObjectMapper.deserialize<BenefitsEmployeeModel>(
      BenefitsEmployeeModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

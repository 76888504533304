import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { QueryParams } from "../models/odata/queryParams";
import { Injectable } from "@angular/core";

import { ServiceBase } from "services/servicebase";
import { HttpService } from "services/http.service";
import { BatimentoModel } from "../models/clock/batimento.model";
import { Response } from "../models/response";

@Injectable()
export class BatimentoService extends ServiceBase<BatimentoModel> {
  constructor(http: HttpService) {
    super("pointtime", http, environment.apiUrl);
  }

  createEntity(input: any): BatimentoModel {
    return BatimentoModel.create(input);
  }

  getBatimentos(
    employeeId: string,
    companyId: string,
    dateBegin: Date,
    dateEnd: Date,
    params: QueryParams
  ): Observable<Response<BatimentoModel[]>> {
    const query = this.createBuildQuery(params);
    return this.http
      .get(
        `${this.endpoint}/list.by.range/${employeeId}/${companyId}/${dateBegin}/${dateEnd}/${query}`
      )
      .pipe(
        map((res) => {
          const response = new Response<BatimentoModel[]>();
          response.data = res.body.map((pp) => BatimentoModel.create(pp));
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }
}

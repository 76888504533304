import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { CustomIconDirective } from './custom-icons.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, CustomIconDirective],
  exports: [RippleEffectDirective, FeatherIconDirective, CustomIconDirective]
})
export class CoreDirectivesModule {}

import { ObjectMapper } from "json-object-mapper";
import { WorkforceplanMovementType } from "services/enums/WorkforceplanMovementType.enum";
import { WorkforceplanSolicitationStatus } from "services/enums/WorkforceplanSolicitationStatus.enum";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkforcePlanSolicitationStepModel extends Model implements IModel<WorkforcePlanSolicitationStepModel> {
	id: string = "";
	sequence: number = 0;
	approverId: string = "";
	approverName: string = "";
	approverImage: string = "assets/images/logo/avatar-profile.png";
	approverJobTitleName: string = "";
	status: string = "";
	stepName: string = "";
	note: string = "";
	approvalLevelName: string = "";
	approvalProfileName: string = "";
	effectiveDate: string = "";

	get statusData(): WorkforceplanSolicitationStatus {
		return WorkforceplanSolicitationStatus.getBy(this.status)
	}

	static create(input: any): WorkforcePlanSolicitationStepModel {
		return ObjectMapper.deserialize<WorkforcePlanSolicitationStepModel>(
			WorkforcePlanSolicitationStepModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class WorkforcePlanSolicitationModel extends Model implements IModel<WorkforcePlanSolicitationModel> {
	movementType: any = WorkforceplanMovementType.WORK_FORCEPLAN.id;
	workforcePlanId: string = "";
	approvalScopeItemId: string = "";
	effectiveDate: string = "";
	note: string = "";
	status: string = "";
	payload: string = "";
	requesterId: string = "";
	requesterName: string = "";
	requesterImage: string = "assets/images/logo/avatar-profile.png";
	employeeId: string = "";
	employeeName: string = "";
	employeeImage: string = "assets/images/logo/avatar-profile.png";
	workScaleGroup: string = "";
	totalSteps: number = 0;
	totalDone: number = 0;
	steps: WorkforcePlanSolicitationStepModel[] = [];

	get statusBadge(): string {
		const status = WorkforceplanSolicitationStatus.getBy(this.status);

		return `<span class="badge bg-${status?.className} text-wrap">${status?.label}</span>`;
	}

	get statusData(): WorkforceplanSolicitationStatus {
		return WorkforceplanSolicitationStatus.getBy(this.status)
	}

	get movementTypeData(): WorkforceplanMovementType {
		return WorkforceplanMovementType.getBy(this.movementType)
	}

	get movementTypeLabel() {
		return this.movementTypeData.label
	}

	get movementTypeEnumerator() {
		switch (this.movementType) {
			case 'Dismissal':
				return 'REQUEST_DISMISSAL'
			case 'InternalTraining':
				return 'REQUEST_TRAINING'
			case 'ShiftChange':
				return 'SHIFT_CHANGE'
			case 'NewHiringReplacement':
				return 'REQUEST_HIRING_REPLACEMENT'
			case 'CostCenterChange':
				return 'CENTER_COST_CHANGE'
			case 'JobTitleChange':
				return 'JOBTITLE_CHANGE'
			case 'NewHiringIncrease':
				return 'REQUEST_HIRING_EXPANSION';
			case 'WorkforcePlanning':
				return 'REQUEST_WORK_FORCEPLAN';
		}
	}

	static create(input: any): WorkforcePlanSolicitationModel {
		return ObjectMapper.deserialize<WorkforcePlanSolicitationModel>(
			WorkforcePlanSolicitationModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

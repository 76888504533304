import { BaseEnum } from "./baseEnum";

const listWorkforceplanStatusEnum: Array<WorkforceplanStatusType> = [];

export class WorkforceplanStatusType extends BaseEnum {
	static Draft = new WorkforceplanStatusType(1, 'Draft', 'Rascunho', 'light-secondary');
	static InApproval = new WorkforceplanStatusType(2, 'InApproval', 'Em aprovação', 'light-secondary');
	static Effective = new WorkforceplanStatusType(3, 'Effective', 'Efetivado', 'light-info');
	static Discontinued = new WorkforceplanStatusType(4, 'Discontinued', 'Descontinuado', 'light-danger');

	constructor(public id: number, public value: string, public label: string, public className: string) {
		super(id, value, label, className);

		listWorkforceplanStatusEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listWorkforceplanStatusEnum;
	}
}

import { Injectable } from "@angular/core";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { InfringementModel } from "services/models/corporate/infringement.model";
import { QueryParams } from "services/models/odata/queryParams";
import { Observable } from "rxjs";
import { Response } from "services/models/response";

@Injectable()
export class InfringementService extends ServiceBase<InfringementModel> {
  constructor(http: HttpService) {
    super("infringements", http, environment.apiUrl);
  }

  createEntity(input: any): InfringementModel {
    return InfringementModel.create(input);
  }

  listInfringementsByEmployee(
    params: QueryParams = null,
    employeeId: string
  ): Observable<Response<InfringementModel[]>> {
    return this.customList<InfringementModel>(
      params, `list-by-employee/${employeeId}`, InfringementModel
    );
  }

}
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[customIcon]'
})
export class CustomIconDirective implements OnInit {
  @Input('customIcon') iconName!: string;
  @Input() size!: number;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.classList.add(`custom-icon-${this.iconName}`);
    if (this.size) {
      this.el.nativeElement.style.fontSize = `${this.size}px`;
    }
  }
}
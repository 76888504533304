import { Injectable } from "@angular/core";
import { FileService } from "@core/services/file.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { DocumentSignatureModel } from "services/models/documents/document-signature.model";
import { ServiceBase } from "services/servicebase";

type ValidateCodeData = {
	token: string;
	employeeId: string;
	documentSignatureId: string;
};

type SignDocumentData = {
	token: string;
	employeeId: string;
	rubricBaseSvg: string;
	documentSignatureId: string;
};

@Injectable()
export class DocumentSignatureService extends ServiceBase<DocumentSignatureModel> {
	constructor(http: HttpService, private file: FileService) {
		super("digitalsignature", http, environment.apiUrl);
	}

	createEntity(input: any): DocumentSignatureModel {
		return DocumentSignatureModel.create(input);
	}

	saveSendDocument(data: DocumentSignatureModel) {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}`, data).subscribe(response => {
				observer.next(response);
				observer.complete()
			})
		})
	}

	public getFileForSign(processId: string): Observable<Blob> {
		const requestUrl = `${this.endpoint}/document-for-sign?processId=${processId}`;

		return new Observable<any>((observer) => {
			this.http
				.get(requestUrl, { responseType: "blob" }, null, null, true)
				.subscribe(({ body }) => {
					observer.next(body);
					observer.complete();
				}, (error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	public getFileSigned(processId: string): Observable<Blob> {
		const requestUrl = `${this.endpoint}/signed-document?processId=${processId}`;

		return new Observable<any>((observer) => {
			this.http
				.get(requestUrl, { responseType: "arraybuffer" }, null, null, true)
				.subscribe(({ body }) => {
					observer.next(body);

					observer.complete();
				});
		});
	}

	getEvents(processId: string): Observable<any> {
		return this.http
			.get(`${this.endpoint}/events`, { params: { processId } }, null, null, true)
			.pipe(
				map((res) => {
					return res.body;
				})
			);
	}

	validateCode(data: ValidateCodeData) {
		return new Observable<any>((observer) => {
			this.http
				.post(`${this.endpoint}/validate-token`, data, null, null, true)
				.subscribe((response) => {
					observer.next(response);
					observer.complete();
				}, (error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	signDocument(data: SignDocumentData) {
		return new Observable<any>((observer) => {
			this.http
				.post(`${this.endpoint}/sign`, data, null, null, true)
				.subscribe((response) => {
					observer.next(response);
					observer.complete();
				}, (error) => {
					observer.error(error);
					observer.complete();
				})
		});
	}

	downloadDocument(url: string, fileName: string) {
		const link = document.createElement("a");

		link.href = url;
		link.download = `RHGestao-${fileName}`;

		link.click();
	}
}

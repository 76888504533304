import { BaseEnum } from "./baseEnum"

const listUserRoleEnum: Array<UserActionType> = [];

export class UserActionType extends BaseEnum {
    static can_clockin = new UserActionType(1, 'can_clockin', 'ROOT', 'primary');
    static pre_handling_time = new UserActionType(2, 'pre_handling_time', 'Empresa', 'primary');
    static can_view_hourly_basis_list = new UserActionType(3, 'can_view_hourly_basis_list', 'Grupo de Empresa', 'primary');
    static can_add_batch = new UserActionType(4, 'can_add_batch', 'Revisão em Lote', 'primary');

    constructor(public id: number, public value: string, public label: string, public className: string) {
        super(id, value, label, className)
        listUserRoleEnum.push(this);
    }

    public static buildListEnum(): Array<BaseEnum> {
        return listUserRoleEnum;
    }
}
import { JsonProperty, ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';

export class CepModel implements IModel<CepModel> {
  @JsonProperty({ name: 'cep' })
  public cep = '';
  @JsonProperty({ name: 'logradouro' })
  public logradouro = '';
  @JsonProperty({ name: 'complemento' })
  public complemento = '';
  @JsonProperty({ name: 'bairro' })
  public bairro = '';
  @JsonProperty({ name: 'localidade' })
  public localidade = '';
  @JsonProperty({ name: 'uf' })
  public uf = '';

  static create(input: any): CepModel {
    return ObjectMapper.deserialize<CepModel>(CepModel, input);
  }

  public toJSON(): string {
    Object.keys(this).forEach((e) => {
      const isDate = this[e] instanceof Date;
      if (isDate) {
        this[e] = this[e].toISOString();
      }
    });
    return ObjectMapper.serialize(this) as string;
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { HttpService } from "services/http.service";
import { BenefitProviderModel } from "services/models/corporate/benefitProvider.model";
import { ServiceBase } from "services/servicebase";
import { map } from "rxjs/operators";
import { BenefitModel } from "services/models/corporate/benefit.model";

@Injectable()
export class BenefitProviderService extends ServiceBase<BenefitProviderModel> {
	constructor(http: HttpService) {
		super("benefitprovider", http, environment.apiUrl);
	}

	createEntity(input: any): BenefitProviderModel {
		return BenefitProviderModel.create(input);
	}

	getLogo(id: string): Observable<any> {
		return this.http.get(`${this.endpoint}/${id}/logo`).pipe(
			map((res) => {
				return res;
			})
		);
	}

	removeBenefit(benefit: BenefitModel) {
		return this.http.post(`${this.endpoint}/remove/benefit`, benefit);
	}

	openDetailsFile(id: string) {
		this.http.get(`${this.endpoint}/${id}/details-file`).subscribe(
			({ body }) => {
				window.open(body, "_blank");
			},
			(error) => {
				console.log("error service", error);
			}
		);
	}
}

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <img src="{{domainImageUrl}}" class="company-logo" alt="{{ domainObj?.name }} Logo" />
          </div>
          <div class="card-body">
            <div class="form-wrapper">
              <form class="form form-vertical" [formGroup]="dateRangeForm" (ngSubmit)="downloadAFD()">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label fpr="company">Empresa</label>
                      <select id="company" formControlName="company" class="form-control">
                        <option *ngFor="let option of companyOptions" [value]="option.value">
                          {{option.label}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Data Inicial</label>
                      <input type="date" class="form-control" formControlName="startDate" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Data Final</label>
                      <input type="date" class="form-control" formControlName="endDate" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button type="submit" [disabled]="dateRangeForm.invalid" rippleEffect
                      class="btn btn-primary btn-block">
                      Baixar Arquivo
                    </button>
                  </div>
                </div>
              </form>
            </div> <!-- .form-wrapper -->
          </div> <!-- .card-body -->
        </div> <!-- .card -->
      </div> <!-- .col-12 -->
    </div> <!-- .row -->
  </div> <!-- .content-body -->
</div> <!-- .content-wrapper -->
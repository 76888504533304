const BASE_URL = 'https://apihmg.rhgestao.com';
const CEP_BASE_URL = 'https://viacep.com.br/ws/{cep}/json/';
const IBGE_BASE_URL = 'https://servicodados.ibge.gov.br/api/v1/localidades';

export const environment = {
  apiUrl: `${BASE_URL}/api/v1`,
  imagesApiUrl: `${BASE_URL}/images/companies`,
  cepService: CEP_BASE_URL,
  ibgeService: IBGE_BASE_URL,
  production: false
};

import { ObjectMapper } from "json-object-mapper";
import { WorkforceplanMovementType } from "services/enums/WorkforceplanMovementType.enum";
import { WorkforceplanSolicitationStatus } from "services/enums/WorkforceplanSolicitationStatus.enum";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";
import {WorkforcePlanWorkScaleGroupModel} from "./workforceplan-workscale-group.model";

export class WorkforcePlanSolicitationDetailsModel extends Model implements IModel<WorkforcePlanSolicitationDetailsModel> {
	note: string = "";
	payload: string = "";
	employeeAdmissionDate: string = "";
	employeeCostCenterId: string = "";
	employeeCostCenterCode: string = "";
	employeeCostCenterName: string = "";
	employeeCPF: string = "";
	employeeIsPCD: string = "";
	employeeJobTitleId: string = "";
	employeeJobTitleName: string = "";
	employeeRegistration: string = "";
	employeeTimeShiftCode: string = "";
	employeeTimeShiftSequence: string = "";
	employeeTimeShiftTitle: string = "";
	employeeTimeShiftId: string = "";
	requesterId: string = "";
	requesterName: string = "";
	requesterImage: string = "";
	stepEffectiveDate: string = "";
	stepNote: string = "";
	stepStatus: string = "";
	stepName: string = "";
	permission: { canExecute: boolean; canUpdate: boolean; } = {
		canExecute: false, canUpdate: false
	}

	approvalDetails?: { workScaleGroups: WorkforcePlanWorkScaleGroupModel[] } = {
		workScaleGroups: []
	}

	get statusBadge(): string {
		const status = WorkforceplanSolicitationStatus.getBy(this.stepStatus);

		return `<span class="badge bg-${status?.className} text-wrap">${status?.label}</span>`;
	}

	get statusData(): WorkforceplanSolicitationStatus {
		return WorkforceplanSolicitationStatus.getBy(this.stepStatus)
	}

	get movementTypeData(): WorkforceplanMovementType {
		return {} as any
	}

	get payloadData() {
		return JSON.parse(this.payload)
	}

	get movementTypeLabel() {
		if (this.payloadData.raiseType) {
			return this.payloadData.raiseType
		}

		return this.movementTypeData.label
	}

	static create(input: any): WorkforcePlanSolicitationDetailsModel {
		return ObjectMapper.deserialize<WorkforcePlanSolicitationDetailsModel>(
			WorkforcePlanSolicitationDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { BaseEnum } from "./baseEnum"

const listUserTypeEnum: Array<UserType> = [];

export class UserType extends BaseEnum {
  static CompanyGroup = new UserType(1, 'CompanyGroup', 'Interno');
  static OutsourcedLiable = new UserType(3, 'OutsourcedLiable', 'Terceirizado');

  constructor(public id: number, public value: string, public label: string) {
    super(id, value, label)

    listUserTypeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listUserTypeEnum;
  }
}
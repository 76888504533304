import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target']) onInput(input: HTMLInputElement) {
    const start = input.selectionStart || 0;
    const end = input.selectionEnd || 0;
    const upperCaseValue = input.value.toUpperCase();
    input.value = upperCaseValue
    this.control.control.setValue(upperCaseValue, { emitEvent: false });
    input.setSelectionRange(start, end);
  }
}
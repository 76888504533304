import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { VactionScheduleStatusType } from "services/enums/VactionScheduleStatusType.enum";

export class ReferencePeriodVacationScheduleModel extends Model implements IModel<ReferencePeriodVacationScheduleModel> {
  referencePeriodId: string | undefined = undefined;
  date: string = '';
  amountDaysVacation: number = 0;
  amountDaysAllowance: number = 0;
  status: any = VactionScheduleStatusType.Pending.value;
  sequence: number = 0;

  get statusBadge(): string {
    const status = VactionScheduleStatusType.getBy(this.status);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  static create(input: any): ReferencePeriodVacationScheduleModel {
    return ObjectMapper.deserialize<ReferencePeriodVacationScheduleModel>(ReferencePeriodVacationScheduleModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
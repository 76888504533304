import { ObjectMapper } from 'json-object-mapper';
import { CompanyModel } from 'services/models/company.model';
import { IModel } from './IModel';
import { Model } from './model';
import { PointTimeModel } from './point-time.model';

export class CheckRegistrationModel extends Model implements IModel<CheckRegistrationModel> {
  data: any = undefined;
  serverDate: Date = null;
  questionType: string = '';
  trueAnswer: boolean = false;
  rightPassword: boolean = false;
  registration: string = '';
  name: string = '';
  email: string = '';
  pis: string = '';
  nsr: string = '';
  latitude: number = 0;
  longitude: number = 0;
  id: string = '';
  pointsTime: PointTimeModel[] = [];
  company: CompanyModel = undefined;

  static create(input): CheckRegistrationModel {
    return ObjectMapper.deserialize<CheckRegistrationModel>(CheckRegistrationModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
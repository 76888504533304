import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class PointTimePeriodModel extends Model implements IModel<PointTimePeriodModel> {
    companyId: string | undefined = undefined;
    companyName: string = '';
    companyGroupId: string | undefined = undefined;
    note: string = '';
    dateBegin: string = '';
    dateEnd: string = '';

    get statusBadge(): string {
      return this.enable
        ? `<span class="badge bg-success rounded-pill">ABERTO</span>`
        : '<span class="badge bg-danger rounded-pill">FECHADO</span>';
    }
    
    static create(input: any): PointTimePeriodModel {
      return ObjectMapper.deserialize<PointTimePeriodModel>(PointTimePeriodModel, input);
    }
  
    public toJSON(): string {
      return ObjectMapper.serialize(this) as string;
    }
  }
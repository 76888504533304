import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@core/components/toasts/toasts.service';
import { ErrorModel } from './models/error.model';

@Injectable()
export class HandleErrorService {
  errors: ErrorModel[];
  httpErrors: any;

  constructor(private toasterService: ToastService) {}

  public handleError(err: HttpErrorResponse) {
    const handleMessage = (error) => {
      const messages = []
        .concat(error?.error?.errors?.messages, [error?.error?.Message])
        .filter((x) => x);
      switch (error.status) {
        case 0:
          return [
            ErrorModel.create({
              status: 0,
              message: 'Ocorreu um erro ao tentar se conectar com o servidor',
            }),
          ];
        case 400:
          return messages.map((message) =>
            ErrorModel.create({ status: 400, message })
          );
        case 401:
          return [
            ErrorModel.create({
              status: 401,
              message: 'Faça login para continuar',
            }),
          ];
        case 500:
          return messages?.length > 0
            ? messages.map((message) =>
                ErrorModel.create({ status: 500, message })
              )
            : [
                ErrorModel.create({
                  status: 500,
                  message: 'Falha na comunicação',
                }),
              ];
        default:
          return [
            ErrorModel.create({
              status: error.status,
              message: 'Erro desconhecido',
            }),
          ];
      }
    };

    if (err.error && Object.keys(err.error).includes('operationSuccess')) {
      if (err.error.status === 400) {
        this.toasterService.showWarning(err.error.message);
      } else {
        this.toasterService.showError(err.error.message);
      }
    } else if (err.error instanceof ErrorEvent) {
      // Erro no frontend ou erro de rede
      this.errors.push(
        ErrorModel.create({
          status: err.status,
          message: `Ocorreu um erro: ${err.message}`,
        })
      );
    } else {
      this.errors = handleMessage(err);
    }

    this.errors.forEach((err) => {
      if (err.status === 400) {
        this.toasterService.showWarning(err.message);
      } else {
        this.toasterService.showError(err.message);
      }
    });
  }

  public handleNotification(notificationBase64: string) {
    const decoded = decodeURIComponent(escape(atob(notificationBase64)));
  
    this.toasterService.showInfo(decoded, 'top-right');
  }  
}

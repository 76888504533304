import moment from 'moment-business-days';
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';

export enum FilterType {
  CONTAINS,
  EQUALS,
  GREATEROREQUALTHAN,
  DATE
}

export class Filter {
  constructor() { }

  static dataRange(dtFieldName, dataGt: any, dataLt: any) {
    const filter = f('and')
      .ge(dtFieldName, new Date(dataGt))
      .le(dtFieldName, new Date(dataLt))
      .toString();

    return buildQuery({ filter });
  }

  static contains(property: string, value: any) {
    const filter = f().contains((x) => x.toLower(property), value.toLocaleLowerCase()).toString();
    return buildQuery({ filter }).replace('?', '&');
  }

  static equal(property: string, value: string | number | boolean) {
    const filter = f().eq(property.toLowerCase(), value).toString();
    return buildQuery({ filter }).replace('?', '&');;
  }

  static notEqual(property: string, value: string | number | boolean | null) {
    const filter = f().ne(property.toLowerCase(), value).toString();
    return buildQuery({ filter }).replace("?", "&");
  }

  static lessThan(property: string, value: number) {
    return f().lt(property, value).toString().replace(/'/g, '');
  }

  static greaterThan(property: string, value: string) {
    const filter = f().gt(property, value).toString();
    return buildQuery({ filter }).replace("?", "&");
  }

  static greaterOrEqualThan(property: string, value: string) {
    const filter = f().ge(property, value).toString();
    return buildQuery({ filter }).replace("?", "&");
  }

  static equalToGuid(property: string, value: string | number | boolean) {
    const odataFilter = f().eq(property, value).toString().replace(/'/g, '');
    return buildQuery({ filter: odataFilter }).replace('?', '&');
  }

  static multEquals(filter = []) {
    return buildQuery({ filter }).replace("?", "&");
  }

  static differentToGuid(property: string, value: string | number | boolean) {
    const odataFilter = f().ne(property, value).toString().replace(/'/g, "");
    return buildQuery({ filter: odataFilter }).replace("?", "&");
  }

  static filterByExactDate(property: string, userInput: string) {
    const startDate = moment(userInput, "DD/MM/YYYY").startOf('day').toISOString();
    const endDate = moment(userInput, "DD/MM/YYYY").endOf('day').toISOString();

    const filter = `${property.toLowerCase()} ge ${startDate} and ${property.toLowerCase()} le ${endDate}`;
    return buildQuery({ filter }).replace("?", "&");
}

  static appendFilter(
    filterStr: string,
    condition: "and" | "or",
    newFilter: string
  ) {
    newFilter = newFilter.replace("&$filter=", "");
    return `${filterStr} ${condition} ${newFilter}`;
  }

  static buildFilter(property: string, value: string | number | boolean) {
    return f().eq(property, value).toString().replace(/'/g, '')
  }

  static buildNeFilter(property: string, value: string | number | boolean) {
    return f().ne(property, value).toString().replace(/'/g, '')
  }

  static inGuid(property: string, values: string[]) {
    return `${property} in (${values.map(v => `'${v}'`).join(',')})`;
  }
}
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionRequiredSource } from "services/enums/ActionRequiredSource.enum";
import { ActionRequiredModel } from "services/models/action-required";
import { WorkforcePlanSolicitationModel } from "services/models/workforceplan/workforceplan-solicitation.model";
import { WorkforceplanService } from "services/workforceplan/workforceplan-main.service";

@Component({
	selector: "app-shared-action-required-list",
	templateUrl: "./action-required-list.component.html",
	styleUrls: ["./action-required-list.component.scss"],
})
export class AppSharedActionRequiredListComponent {
	@Input() data: ActionRequiredModel[] = [];

	@Input() className?: string = "";

	@Output() onListUpdated = new EventEmitter();

	@Output() onOpenDrawer = new EventEmitter();

	public listItems = [];
	public actionDetails: [number, ActionRequiredModel];
	public isLoadingDrawerData = false;

	constructor(
		private _workforcePlanService: WorkforceplanService,
	) { }

	ngOnInit() {
	}

	public handleOnCancel() {
	}

	public handleShowDetails(index: number, action: ActionRequiredModel) {
		this.actionDetails = [index, action];

		switch (action.source) {
			case ActionRequiredSource.WorforcePlan.value: {
				this.onActionWorkforcePlan(action.getUrl);
				break;
			}
			default: {
				console.log('Action not mapped');
				break;
			}
		}
	}

	private onActionWorkforcePlan(url: string) {
		this.isLoadingDrawerData = true;

		this._workforcePlanService.getWorkforcePlanByResourceUrl(url).subscribe(
			(response) => {
				if (!response.movementRequestParent) return;

				const [_, action] = this.actionDetails;

				const solicitation = WorkforcePlanSolicitationModel.create(response.movementRequestParent[0]);
				const rejectedIndex = solicitation.steps.findIndex(f => f.status === 'REJECTED');
				if (rejectedIndex >= 0)
					solicitation.steps = solicitation.steps.slice(0, rejectedIndex + 1)

				const employee = solicitation.employeeId ? { id: solicitation.employeeId, status: 'ACTIVE' } : null

				this.isLoadingDrawerData = false;

				const data = {
					type: solicitation.movementTypeEnumerator,
					mode: 'details',
					employee,
					companyId: null,
					solicitation,
					workforcePlanId: solicitation.workforcePlanId,
					drawerOpened: true,
					workScaleGroup: solicitation.workScaleGroup
				};

				this.onOpenDrawer.emit({
					data,
					action
				});
			},
			() => {
				this.isLoadingDrawerData = false;
			}
		);
	}
}


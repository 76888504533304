import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";
import { ErrorMsgComponent } from "./error-msg.component";

@NgModule({
  declarations: [ErrorMsgComponent],
  imports: [CoreCommonModule],
  exports: [ErrorMsgComponent],
})
export class ErrorMsgModule {}

<div class="strength">
  <ul class="barlength">
    <li class="checker" [style.background-color]="length0"></li>
    <li class="checker" [style.background-color]="length1"></li>
    <li class="checker" [style.background-color]="length2"></li>
    <li class="checker" [style.background-color]="length3"></li>
  </ul>
  <br>
  <p style="font-size: 15px;">{{message}}</p>
  <ul>
    <li style="font-weight: bold;">Padrão da senha: </li>
    <li>No mínimo 8 caracteres </li>
    <li>Ter pelo menos 1 maiúsculo</li>
    <li>Ter pelo menos 1 minúsculo </li>
    <li>Ter pelo menos 1 número </li>
    <li>Ter pelo menos 1 caractere especial </li>
  </ul>
</div>
import { ObjectMapper } from "json-object-mapper";

export class TypeExitInverviewModel {
  name: string = '';
  companyGroupId: string | undefined = undefined;
  introductionContent: string = '';
  conclusionContent: string = '';

  static create(input: any): TypeExitInverviewModel {
    return ObjectMapper.deserialize<TypeExitInverviewModel>(TypeExitInverviewModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
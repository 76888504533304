export class QueryParams {
  select: string[];
  page = 1;
  perPage;
  top;
  skip = 0;
  filter = "";
  currentFilter = "";
  expand;
  orderBy = "id";
  orderByOrder: "asc" | "desc" = "asc";
}

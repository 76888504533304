import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'services/auth.service';

@Component({
  selector: 'app-table-title',
  templateUrl: './table-title.component.html',
  styleUrls: ['./table-title.component.scss']
})
export class TableTitleComponent implements OnInit {

  @Input() title: string='';
  @Input() menuActions = true;
  @Input() canCreateExternal: boolean = true;
  canCreate = true;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    const currentpath = this.router.url.split('/');
    const page = currentpath[currentpath.length - 1];

    if(!this.canCreateExternal) {
      this.canCreate = false;
    }
    // this.canCreate = this.authService.checkPermissionPage(page, 'write');
  }

}

import { ObjectMapper } from "json-object-mapper";
import { BenefitDiscountType } from "services/enums/benefitDiscountType.enum";
import { BenefitPricingType } from "services/enums/benefitPricingType.enum";
import { IModel } from "../IModel";
import { Model } from "../model";

export class BenefitPlanModel extends Model implements IModel<BenefitPlanModel> {
	benefitId: string = "";
	condition: number = 0;
	code: number = 0;
	value: number = 0;
	holderDiscount: number = 0;
	holderValue: number = 0;
	dependentDiscount: number = 0;
	dependentValue: number = 0;
	aggregateDiscount: number = 0;
	aggregateValue: number = 0;
	forDependents: boolean = false;
	benefitDescription: string = "";
	detailsFile: string = "";
	pricingType: BenefitPricingType = "UNSET";
	discountType: BenefitDiscountType = "PERCENTAGEM";

	static create(input: any): BenefitPlanModel {
		return ObjectMapper.deserialize<BenefitPlanModel>(BenefitPlanModel, input);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

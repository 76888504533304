import { OptionModel } from './../model';
import { ObjectMapper } from 'json-object-mapper';
import { Model } from '../model';
import { IModel } from '../IModel';

export class TimeShiftModel extends Model implements IModel<TimeShiftModel> {
  code = '';
  title = '';
  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  workScale: string | undefined = undefined;

  static create(input: any): TimeShiftModel {
    return ObjectMapper.deserialize<TimeShiftModel>(TimeShiftModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  toOption(): OptionModel {
    return { label: `${this['code']} - ${this['title']}`, value: this.id } as OptionModel;
  }

  toTitleOption(): OptionModel {
    return { label: this['title'], value: this.id } as OptionModel;
  }
}

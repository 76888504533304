import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpService } from "../http.service";
import { ServiceBase } from "../servicebase";
import { AccessProfileModel } from "services/models/corporate/accessProfile.model";
import { QueryParams } from "services/models/odata/queryParams";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { map } from "rxjs/operators";
import { AccessClaimModel } from "services/models/corporate/accessClaim.model";

@Injectable()
export class AccessProfileService extends ServiceBase<AccessProfileModel> {
  constructor(http: HttpService) {
    super("accessprofile", http, environment.apiUrl);
  }

  createEntity(input: any): AccessProfileModel {
    return AccessProfileModel.create(input);
  }

  getClaims(): Observable<Response<AccessClaimModel[]>> {
    const params = {
      select: ["type", "id", "title", "description", "name"],
    } as QueryParams;
    const query = this.createBuildQuery(params);
    return this.http.get(`${this.endpoint}/claims/odata${query}`).pipe(
      map((res) => {
        const response = new Response<AccessClaimModel[]>();
        response.data = res.body.map((pp) => AccessClaimModel.create(pp));
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  getPersons(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/${id}/persons`);
  }

  removeAccessProfileClaim(id: string): Observable<Response<any>> {
    return this.http.remove(`${this.endpoint}/access-profile-claim/${id}`).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }
}

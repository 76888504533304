import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";
import { WorkforceplanStatusType } from "services/enums/WorkforceplanStatusType.enum";
import { WorkforcePlanVersionModel } from "./workforceplan-version.model";
import { WorkforcePlanWorkScaleGroupModel } from "./workforceplan-workscale-group.model";

export class WorkforcePlanModel
	extends Model
	implements IModel<WorkforcePlanModel> {
	version: number = 0;
	companyId: string = "";
	costCenterId: string = "";
	workScaleGroups: WorkforcePlanWorkScaleGroupModel[] = [];
	versions: WorkforcePlanVersionModel[] = [];
	effectiveDate: string = "";
	status: string = WorkforceplanStatusType.Draft.value;

	static create(input: any): WorkforcePlanModel {
		return ObjectMapper.deserialize<WorkforcePlanModel>(
			WorkforcePlanModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { Injectable, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];
  constructor(private toastr: ToastrService) {}

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  showSuccess(message: string, position: 'top-right'| 'top-left' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center' | 'center' = 'top-right') {
    this.show(message, {
      autohide: true,
    });
    this.toastr.success(message, 'Sucesso!', { positionClass: `toast-${position}` });
  }

  showWarning(message: string, position: 'top-right'| 'top-left' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center' | 'center' = 'top-right') {
    this.toastr.warning(message, 'Atenção!', { positionClass: `toast-${position}` });
  }

  showInfo(message: string, position: 'top-right'| 'top-left' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center' | 'center' = 'top-right') {
    this.toastr.info(message, 'Informação!', { positionClass: `toast-${position}` });
  }

  showError(message: string, position: 'top-right'| 'top-left' | 'bottom-left' | 'bottom-right' | 'top-center' | 'bottom-center' | 'center' = 'top-right') {
    this.toastr.error(message, 'Erro', { positionClass: `toast-${position}` });
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class MovementRequestPermissionModel
  extends Model
  implements IModel<MovementRequestPermissionModel> {
  canExecute: boolean = false;
  canUpdate: boolean = false;

  static create(input: any): MovementRequestPermissionModel {
    return ObjectMapper.deserialize<MovementRequestPermissionModel>(
      MovementRequestPermissionModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

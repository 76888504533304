import { ObjectMapper } from "json-object-mapper";
import { BenefitTypeEnum } from "services/enums/benefitsType.enum";
import { IModel } from "../IModel";
import { Model } from "../model";

export class BenefitEnableModel extends Model implements IModel<BenefitEnableModel> {
	benefitOfferedId: string = "";
	petitionId: string | undefined = undefined;
	benefitType: string = "";
	description: string = "";
	entryMode: string = "";
	logo: string = "";
	name: string = "";
	providerId: string = "";

	get benefitTypeLabel(): string {
		return BenefitTypeEnum.getBy(this.benefitType).label;
	}

	static create(input: any): BenefitEnableModel {
		return ObjectMapper.deserialize<BenefitEnableModel>(BenefitEnableModel, input);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { BaseEnum } from "services/enums/baseEnum";

const listDocumentMockupTypeEnum: Array<DocumentMockupTypeEnum> = [];

export class DocumentMockupTypeEnum extends BaseEnum {
  static General = new DocumentMockupTypeEnum(0, "General", "Geral");

  static Hiring = new DocumentMockupTypeEnum(1, "Hiring", "Admissional");

  static Benefit = new DocumentMockupTypeEnum(2, "Benefit", "Benefício");

  constructor(public id: number, public value: string, public label: string) {
    super(id, value, label);

    listDocumentMockupTypeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listDocumentMockupTypeEnum;
  }
}

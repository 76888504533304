import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ResetPasswordService } from "services/reset-password.service";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  @ViewChild("btnConfirm") private btnConfirm: ElementRef;
  title: string = "Confirmação";
  focusConfirm = false;
  message: string;

  constructor(
    public activeModal: NgbActiveModal,
    public resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    if (this.focusConfirm) this.btnConfirm?.nativeElement.focus();
  }

  onConfirm(): void {
    this.activeModal.close(true);
  }

  onDismiss(): void {
    this.activeModal.close(false);
  }
}

import { KinshipDegreeType } from "services/enums/kinshipDegreeType.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import { BenefitPlanModel } from "./benefitPlan.model";
import { ObjectMapper } from "json-object-mapper";
import { BenefitModel as BenefitModelBase } from "./benefit.model";

export class BenefitModel extends BenefitModelBase {
	isActived: boolean = false;
	dependents: BenefitDependentModel[];
	benefitName?: string = "";
	benefitId?: string = "";
}

export class BenefitDependentModel
	extends Model
	implements IModel<BenefitDependentModel> {
	name: string = "";
	birthDate: string = "";
	rg: string = "";
	cpf: string = "";
	kinshipDegree: KinshipDegreeType = "Child";
	sex: string = "";
	employeeId: string | undefined = undefined;

	isActived?: boolean = false;
	isSelected?: boolean = false;
	pendingIncludeDependent?: boolean = false;
	pendingExcludeDependent?: boolean = false;
	cancellation?: boolean = false;
	order?: number;
	plan?: BenefitPlanModel = null;
	
	get kinshipDegreeLabel(): string {
		switch (this.kinshipDegree as any) {
			case "Spouse":
				return "Cônjuge/Companheiro";
			case "Child":
				return "Filho";
			case "Stepson":
				return "Enteado";
			case "Parent":
				return "Pai/Mãe";
			case "Relative":
				return "Agregado/Outros";
			default:
				return "";
		}
	}

	static create(input: any): BenefitDependentModel {
		return ObjectMapper.deserialize<BenefitDependentModel>(
			BenefitDependentModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { ObjectMapper } from "json-object-mapper";

export class SummaryExitInverviewModel {
  countPending: number = 0;
  countFinished: number = 0;

  static create(input: any): SummaryExitInverviewModel {
    return ObjectMapper.deserialize<SummaryExitInverviewModel>(SummaryExitInverviewModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
<div *ngIf="multipleFilters && multipleFilters.length>0" class="row">
  <div class="col-md-12">
    <div class="form-group">
      <label class="form-control-label" for="costCenterId">Filtros selecionados: </label>
      <div class="d-flex gap-2">
        <div [ngbTooltip]="'Remover o filtro de: '+getViewColumnByName(item.property).alias"
          *ngFor="let item of multipleFilters">
          <span (click)="removeFilterBtnHandler(item)" class="badge filter-filtered rounded-pill">X
            {{getViewColumnByName(item.property).alias}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="row">
  <div class="col-12 void text-center custom-load">
    <h4>Carregando elementos para exibição...</h4>
    <div class="spinner-grow text-primary mr-1" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="table-container">
  <table class="table table-condensed table-hover table-responsive-xl">
    <thead>
      <tr>
        <th *ngIf="menuActions" class="custom-th text-nowrap">Ações</th>
        <th class="text-nowrap" header-filter *ngFor="let field of viewColumns" [text]="field.alias"
          [property]="field.field" [model]="queryParams.filter" [defaultValue]="field.dafaultFilterValue"
          [isFiltred]="isFiltred(field.field)" [queryParams]="queryParams"
          (filterEvent)="filterByProperty(field, $event, field.filterType)" [listSource]="field.listSource"
          (click)="setOrder(field.field)">{{field.alias}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items; let i = index">
        <td *ngIf="(canDelete || canEdit) || menuActions" class="custom-th text-nowrap">
          <div ngbDropdown id="action-open-menu" container="body">
            <button class="btn p-0 no-dropdown-toggle-icon" type="button" ngbDropdownToggle>
              <i class="icon-lg pb-3px" data-feather="more-horizontal" appFeatherIcon></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-left">
              <a *ngIf="canEdit" ngbDropdownItem [routerLink]="['./', item.id]" id="action-edit">
                <i data-feather="edit" appFeatherIcon></i> Editar
              </a>
              <a *ngIf="canDelete" ngbDropdownItem (click)="delete(item.id)" id="action-delete">
                <i data-feather="trash" class="text-danger" appFeatherIcon> </i> Excluir
              </a>
              <div *ngFor="let itemAction of customActions">
                <a *ngIf="hideCustomActionItem(itemAction.hide, itemAction.showWhen, item)"
                  (click)="itemAction.callAction(item)" [id]="itemAction.id" ngbDropdownItem>
                  <i *ngIf="itemAction.icon.startsWith('feather-')" class="feather icon-{{itemAction.icon.replace('feather-', '')}} {{itemAction.class}}"></i>
                  <i *ngIf="itemAction.icon.startsWith('custom-')" class="custom-icon-{{itemAction.icon.replace('custom-', '')}} {{itemAction.class}}"></i>
                  {{itemAction.label}}
                </a>
              </div>
            </div>
          </div>
        </td>
        <ng-container *ngFor="let name of getFields(); let j = index">
          <td class="overflow" *ngIf="isIncludeInViewColumns(name)" [title]="getTitle(name, item)">
            <span class="text-nowrap"
              [innerHTML]="handleText(name, item) | dynamicPipe:getViewColumnByName(name)?.pipe:getViewColumnByName(name)?.args"></span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>

<app-paginator (query)="query()" [count]="count" [queryParams]="queryParams"
  [filter]="queryParams.filter"></app-paginator>
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model } from './model';
import { VacationLimitType } from 'services/enums/VacationLimitType.enum';

export class VacationManagementParametersModel extends Model implements IModel<VacationManagementParametersModel> {
  controlAccessByHierarchyLevel: boolean = false;
  vacationCutDate: number = 0;
  vacationLimitDateType: any = VacationLimitType.CLT.value;
  hierarchyLevelAccess: HierarchyLevelAccessModel = new HierarchyLevelAccessModel();

  static create(input: any): VacationManagementParametersModel {
    return ObjectMapper.deserialize<VacationManagementParametersModel>(VacationManagementParametersModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

export class HierarchyLevelAccessModel {
  programmingWithAllowance: number[] = [];
  programmingWithoutAllowance: number[] = [];
  requestForSubordinate: number[] = [];
  requestProgramming: number[] = [];
}

import { OptionModel } from '../model';
import { ObjectMapper } from 'json-object-mapper';
import { Model } from '../model';
import { IModel } from '../IModel';

export class SequenceTimeShiftModel extends Model implements IModel<SequenceTimeShiftModel> {
  timeShiftId: string | undefined = undefined;
  sequence: string = '';
  day: DayOfWeek = DayOfWeek.domingo;
  type: number = 1;
  entry1: string = '';
  departure1: string = '';
  entry2: string = '';
  departure2: string = '';

  static create(input: any): SequenceTimeShiftModel {
    return ObjectMapper.deserialize<SequenceTimeShiftModel>(SequenceTimeShiftModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  toOption(): OptionModel {
    return { label: `${this['sequence']} - ${this['dayLabel']}`, value: this.id } as OptionModel;
  }

  get dayLabel(): string {
    switch (this.day) {
      case DayOfWeek.domingo:
        return 'FOLGA NO DOMINGO';
      case DayOfWeek.segunda:
        return 'FOLGA NA SEGUNDA';
      case DayOfWeek.terça:
        return 'FOLGA NA TERÇA';
      case DayOfWeek.quarta:
        return 'FOLGA NA QUARTA';
      case DayOfWeek.quinta:
        return 'FOLGA NA QUINTA';
      case DayOfWeek.sexta:
        return 'FOLGA NA SEXTA';
      case DayOfWeek.sábado:
        return 'FOLGA NO SÁBADO';
      default:
        return 'FOLGA NO DOMINGO';
    }
  }
}

export enum DayOfWeek {
  domingo = 1,
  segunda = 2,
  terça = 3,
  quarta = 4,
  quinta = 5,
  sexta = 6,
  sábado = 7,
}
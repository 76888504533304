<span ngbTooltip="Clique para ordenar por {{text}}" id="colum-text" container="body">{{text}}</span>

<div class="btn-group" container="body" ngbDropdown (isOpenChange)="openChange()" (click)="$event.stopPropagation()">
  <i *ngIf="queryParams?.orderBy === property" class="{{'arrow fa fa-'+ iconArrow()}}"></i>
  <a *ngIf="!optionalFilter" container="body" ngbTooltip="Clique abrir filtro" href="javascript: void(0)"
    ngbDropdownToggle id="dropdownMenuButton" [ngClass]="isFiltred ? 'filter-icon-filtered' : 'filter-icon'">
    <i class="icon-lg pb-3px" data-feather="search" width="18px" appFeatherIcon></i>
  </a>

  <div container="body" ngbDropdownMenu class="dropdown-menu col-md-10" aria-labelledby="dropdownMenuButton">
    <form>
      <div class="row" *ngIf="!listSource">
        <div class="search-form">
          <div class="input-group border rounded-sm">
            <input container="body" type="text" class="form-control  border-0 rounded-sm" [(ngModel)]="model"
              ngbTooltip="Pesquisar por {{text}} ..." (keyup.enter)="filterFunction()" name="filtro">
            <div *ngIf="model" (click)="filterFunction()" class="input-group-prepend">
              <div class="input-group-text border-0 rounded-sm">
                <i class="filter-icon icon-lg pb-3px cursor-pointer" width="18px" data-feather="search"
                  appFeatherIcon></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row" *ngIf="listSource">
      <select class="form-control" (change)="listChange($event)">
        <option [selected]="modelIsNullOrEmpty()" [value]="''">Todos</option>
        <option *ngFor="let option of listSource" [selected]="!modelIsNullOrEmpty() && model==option.value"
          [value]="option.value">
          {{option.label}}
        </option>
      </select>
    </div>
  </div>
</div>
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "services/auth.service";
import { DomainService } from "services/domain.service";

@Injectable({
  providedIn: "root",
})
export class LoginUserTypeGuard implements CanActivate {
  currentUrl: string = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private _domainService: DomainService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const hasOutsourcedInPath = window.location.pathname.includes('outsourced');

		if (this.authService.isOutsourcedLiableUser) {
			if (!hasOutsourcedInPath) {
				const domainName = this.authService.getDomainName();

				this.router.navigate([`/${domainName}/corporate/outsourced/home`]);

				return false;
			}
		}

		return true;
  }
}

import { JobTitleModel } from 'services/models/hiring/jobTitle.model';
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from '../IModel';
import { Model } from '../model';
import moment from 'moment-business-days';
import { HiringStatusType } from 'services/enums/HiringStatusType.enum';

export class ApplicantModel extends Model implements IModel<ApplicantModel> {
  name: string = '';
  nickname: string = '';
  email: string = '';
  phoneNumber: string = '';
  domain: string = '';
  companyCnpj: string = '';
  companyName: string = '';
  dateStart: string = '';
  completionDeadline: string = '';
  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  jobTitleId: string | undefined = undefined;
  jobTitleCode: string = '';
  jobTitleName: string = '';
  jobTitleSalary: number = 0;
  workRegime: number = 1;
  timeShiftId: string | undefined = undefined;
  timeShiftCode: string = '';
  cpf: string = '';
  hiringStatus: any = HiringStatusType.WaitingSesmt.value;
  hiringStatusText: string = '';
  notice: string = '';
  isPcd: boolean = false;
  costCenterId: string | undefined = undefined;
  costCenterCode: string = '';
  jobTitle: JobTitleModel = new JobTitleModel();
  contractualTerm: number = 2;
  employeeType: number = 0;
  contractEnd: string = '';
  creatorId: string | undefined = undefined;
  creatorName: string = '';

  get statusBadge(): string {
    const a = `${this.name} - ${this.hiringStatus}`;
    const status = HiringStatusType.getBy(this.hiringStatus);
    const color = this.hasExpired ? 'danger' : status?.className;
    const label = this.hasExpired ? 'Expirado' : status?.label;

    return `<span class="badge bg-${color} rounded-pill text-wrap">${label}</span>`;
  }

  static create(input: any): ApplicantModel {
    return ObjectMapper.deserialize<ApplicantModel>(ApplicantModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get shortName(): string {
    const nameParts = this.name?.split(' ');
    return `${nameParts[0]} ${nameParts[1]}`;
  }

  get hasExpired(): boolean {
    const dateEnd = moment(this.completionDeadline);
    const today = moment();
    if (today > dateEnd &&
      (this.hiringStatus == HiringStatusType.WaitingApplicant.value
        || this.hiringStatus == HiringStatusType.InvalidDocs.value)) {
      return true;
    }
    return false;
  }

  get hasExpiredAndStartPass(): boolean {
    const dateEnd = moment(this.dateStart);
    const today = moment();
    return (this.hasExpired && today > dateEnd) ? true : false;
  }
}

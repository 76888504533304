import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { ExitInterviewItemModel } from "./exitInterviewItem.model";

export class ItensGroupExitInterviewModel
  extends Model
  implements IModel<ItensGroupExitInterviewModel>
{
  label: string = "";
  typeId: string | undefined = undefined;
  order: number = 0;
  canTerminate: boolean = false;
  itens: ExitInterviewItemModel[] = [];

  static create(input: any): ItensGroupExitInterviewModel {
    return ObjectMapper.deserialize<ItensGroupExitInterviewModel>(
      ItensGroupExitInterviewModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { HourlyBasisStatusType } from "services/enums/HourlyBasisStatusType.enum";

export class HourlyBasisModel extends Model implements IModel<HourlyBasisModel> {

  hourlyBasisTypeId: string | undefined = undefined;
  hourlyBasisTypeTitle: string = '';
  employeeId: string | undefined = undefined;
  day: string = '';
  start: string = '';
  end: string = '';
  amountTime: string | undefined = undefined;
  intervalTime: string | undefined = undefined;
  note: string = '';
  additionalInformation: string = '';
  additionalInformationERP: string = '';
  status: any = HourlyBasisStatusType.Pending.value;
  creditType: string | undefined = undefined;
  employeeName: string = '';
  employeeRegistration: string = '';

  static create(input: any): HourlyBasisModel {
    return ObjectMapper.deserialize<HourlyBasisModel>(HourlyBasisModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get statusBadge(): string {
    const status = HourlyBasisStatusType.getBy(this.status);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  get amountTimeLabel(): string {
    return this.amountTime.slice(0, -3);
  }
}
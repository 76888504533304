import { Injectable } from '@angular/core';
import { HttpService } from 'services/http.service';
import { ServiceBase } from 'services/servicebase';
import { environment } from '../../../environments/environment';
import { HourlyBasisModel } from 'services/models/corporate/hourlyBasis.model';
import { Observable } from 'rxjs';
import { Response } from 'services/models/response';
import { HourlyBasisTypeModel } from 'services/models/corporate/hourlyBasisType.model';
import { map, timeout } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { FileService } from '@core/services/file.service';
import { QueryParams } from 'services/models/odata/queryParams';

@Injectable()
export class HourlyBasisService extends ServiceBase<HourlyBasisModel> {
  constructor(http: HttpService, private file: FileService) {
    super('hourlybasis', http, environment.apiUrl);
  }

  createEntity(input: any): HourlyBasisModel {
    return HourlyBasisModel.create(input);
  }

  review(hourlyBasisId: string) {
    return this.http.post(`${this.endpoint}/${hourlyBasisId}`, '');
  }

  saveBatch(hourlyBasis: HourlyBasisModel[]): Observable<any> {
    const objects = hourlyBasis.map(hb =>  {
      this.http.convertTimes(hb);
      return hb;
    });
    let body = ObjectMapper.serialize(objects);
    body = body.length > 1 ? body : JSON.stringify(objects);

    return this.http.post(`${this.endpoint}/save-batch`, body, null, null, true);
  }

  listTypes(): Observable<Response<HourlyBasisTypeModel[]>> {
    return this.http.get(`${this.endpoint}/list-types`).pipe(
      map((res) => {
        const response = new Response<HourlyBasisTypeModel[]>();
        response.data = res.body.map((pp) => HourlyBasisTypeModel.create(pp));
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getType(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/type/${id}`).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  saveType(id: string | undefined, obj: any) {
    let _object = ObjectMapper.serialize(obj);
    return id == undefined
      ? this.http.post(`${this.endpoint}/type`, _object)
      : this.http.put(`${this.endpoint}/type`, JSON.parse(_object.toString()));
  }

  export(begin: string, end: string, status: string[]) {
    const searchParams = new URLSearchParams();
    status.forEach((status: string) => searchParams.append("status", status));

    const params = { select: [], filter: `&${searchParams.toString()}` } as QueryParams;
    const query = this.createBuildQuery(params);

    return this.http
      .get(`${this.endpoint}/export/${begin}/${end}${query}`)
      .pipe(
        map((res: any) => {
          const blobData = res.body.fileContents;
          const blob = this.file.base64ToBlob(blobData);
          const type = 'csv';
          this.file.saveFile(blob, `${res.body.fileDownloadName}.${type}`);
          return blob;
        }),
        timeout(60000)
      );
  }

  close(obj: any) {
    return this.http
      .put(`${this.endpoint}/close`, obj)
      .pipe(
        map((res: any) => {
          const blobData = res.fileContents;
          const blob = this.file.base64ToBlob(blobData);
          const type = 'csv';
          this.file.saveFile(blob, `${res.fileDownloadName}.${type}`);
          return blob;
        })
      );
  }

  exportClosed(obj: any) {
    const { employeeId, tradeUnionId, begin, end, lastClosed } = obj;
    const searchParams = new URLSearchParams();
    if (employeeId) searchParams.append("employeeId", employeeId);
    if (tradeUnionId) searchParams.append("tradeUnionId", tradeUnionId);
    if (lastClosed) searchParams.append("lastClosed", lastClosed);

    const params = { select: [], filter: `&${searchParams.toString()}` } as QueryParams;
    const query = this.createBuildQuery(params);

    return this.http
      .get(`${this.endpoint}/export-closed/${begin}/${end}${query}`)
      .pipe(
        map((res: any) => {
          const blobData = res.body.fileContents;
          const blob = this.file.base64ToBlob(blobData);
          const type = 'csv';
          this.file.saveFile(blob, `${res.body.fileDownloadName}.${type}`);
          return blob;
        }),
        timeout(60000)
      );
  }
}

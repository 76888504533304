import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { BenefitModel } from "services/models/corporate/benefit.model";
import { BenefitsEmployeeModel } from "services/models/corporate/benefitsEmployee";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";

@Injectable()
export class BenefitsEmployeeService extends ServiceBase<BenefitsEmployeeModel> {
  constructor(http: HttpService) {
    super("benefit", http, environment.apiUrl);
  }

  createEntity(input: any): BenefitsEmployeeModel {
    return BenefitsEmployeeModel.create(input);
  }

  getBenefits(
    params: QueryParams
  ): Observable<Response<BenefitsEmployeeModel[]>> {
    const query = this.createBuildQuery(params);
    return this.http.get(`${this.endpoint}/employee/odata${query}`).pipe(
      map((res) => {
        const response = new Response<BenefitsEmployeeModel[]>();
        response.data = res.body.map((pp) => BenefitsEmployeeModel.create(pp));
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  postBenefits(data: any[]): Observable<any> {
    return this.http.post(`${this.endpoint}/petitions`, data);
  }

  getBenefitsAvailables(): Observable<Response<BenefitModel[]>> {
    return this.http.get(`${this.endpoint}/employee/availables`).pipe(
      map((res) => {
        const response = new Response<BenefitModel[]>();

        response.data = res.body.map((pp) =>
          BenefitModel.create({
            ...pp,
            description: pp.description,
            logoFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logoFile}`,
          })
        );

        response.count = res.headers.get("x-count");

        return response;
      })
    );
  }

  getDetails(id: string): Observable<any> {
    return this.http
      .get(
        `${this.endpoint}/${id}/details`,
        undefined,
        undefined,
        undefined,
        true
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { AccessProfileClaimModel } from "./accessProfileClaim.model";

export class AccessProfileModel
  extends Model
  implements IModel<AccessProfileModel>
{
  companyGroupId: string | undefined = undefined;
  name: string = "";
  description: string = "";
  title: string = "";
  // companies: string[] = [];
  // linkedCompanies: string = "";
  accessProfileClaims: AccessProfileClaimModel[] = [];

  get statusBadge(): string {
    const status = {
      label: this.enable ? "Ativo" : "Desativado",
      color: this.enable ? "success" : "danger",
    };

    return `<span class="badge badge-pill badge-light-${status.color} rounded-pill text-wrap">${status.label}</span>`;
  }

  static create(input: any): AccessProfileModel {
    return ObjectMapper.deserialize<AccessProfileModel>(
      AccessProfileModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { BenefitModel } from "services/models/corporate/benefit.model";
import { BenefitPetitionModel } from "services/models/corporate/benefitPetition.model";
import { ApplicantDependentModel } from "services/models/hiring/applicantDependent.model";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";

@Injectable()
export class BenefitService extends ServiceBase<BenefitModel> {
  constructor(http: HttpService) {
    super("benefit", http, environment.apiUrl);
  }

  createEntity(input: any): BenefitModel {
    return BenefitModel.create(input);
  }

  getDetails(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/${id}/details`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPetitions(
    params: QueryParams
  ): Observable<Response<BenefitPetitionModel[]>> {
    const query = this.createBuildQuery(params);
    return this.http.get(`${this.endpoint}/petitions/odata${query}`).pipe(
      map((res) => {
        const response = new Response<BenefitPetitionModel[]>();
        response.data = res.body.map((pp) => BenefitPetitionModel.create(pp));
        response.count = res.headers.get("x-count");
        return response;
      })
    );
  }

  getSummary(): Observable<any> {
    return this.http.get(`${this.endpoint}/petitions/summary`);
  }

  processPetition(petition: BenefitPetitionModel, status: string) {
    return this.http.put(`${this.endpoint}/petitions/process`, {
      status: status,
      id: petition.id,
    });
  }

  getDependents(benefitId: string) {
    return this.http
      .get(
        `${this.endpoint}/employee/list-dependents-by-benefit?benefitId=${benefitId}`,
        null,
        null,
        null,
        true
      )
      .pipe(
        map((res: any) => {
          const response = new Response<ApplicantDependentModel[]>();

          response.data = res.body.map((e: ApplicantDependentModel) =>
            ApplicantDependentModel.create(e)
          );

          return response;
        })
      );
  }
}

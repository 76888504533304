import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { BenefitModel } from "services/models/corporate/benefit.model";
import { BenefitOfferedModel } from "services/models/corporate/benefitOffered.model";
import { BenefitPetitionModel } from "services/models/corporate/benefitPetition.model";
import { BenefitPetitionDetailsModel } from "services/models/corporate/benefitPetitionDetails.model";
import { BenefitPlanModel } from "services/models/corporate/benefitPlan.model";
import { ApplicantDependentModel } from "services/models/hiring/applicantDependent.model";
import { Filter } from "services/models/odata/filter";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";
import buildQuery from "odata-query";

@Injectable()
export class BenefitService extends ServiceBase<BenefitModel> {
	constructor(http: HttpService) {
		super("benefit", http, environment.apiUrl);
	}

	createEntity(input: any): BenefitModel {
		return BenefitModel.create(input);
	}

	getDetails(id: string): Observable<any> {
		return this.http.get(`${this.endpoint}/${id}/details`).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getPetition(id: string): Observable<BenefitPetitionModel> {
		return this.http.get(`${this.endpoint}/petitions/${id}`).pipe(
			map((res) => {
				const data = res.body;

				return BenefitPetitionModel.create({
					...data,
					providerLogo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${data.providerLogo}`,
				});
			})
		);
	}

	getPetitions(
		params: QueryParams
	): Observable<Response<BenefitPetitionModel[]>> {
		const query = this.createBuildQuery(params);
		return this.http.get(`${this.endpoint}/petitions/odata${query}`).pipe(
			map((res) => {
				const response = new Response<BenefitPetitionModel[]>();
				response.data = res.body.map((pp) => BenefitPetitionModel.create(pp));
				response.count = res.headers.get("x-count");
				return response;
			})
		);
	}

	getSummary(): Observable<any> {
		return this.http.get(`${this.endpoint}/petitions/summary`);
	}

	viewPlanDetails(planId: string) {
		return this.http.get(`${this.endpoint}/plans/${planId}/details-file`).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getDependents(benefitId: string) {
		return this.http
			.get(
				`${this.endpoint}/employee/list-dependents-by-benefit?benefitId=${benefitId}`,
				null,
				null,
				null,
				true
			)
			.pipe(
				map((res: any) => {
					const response = new Response<ApplicantDependentModel[]>();

					response.data = res.body.map((e: ApplicantDependentModel) =>
						ApplicantDependentModel.create(e)
					);

					return response;
				})
			);
	}

	processPetition(data: any) {
		return this.http.put(`${this.endpoint}/petitions/process`, data);
	}

	getBenefitOffered(providerId: string, benefitType: string) {
		const filter = [];
		filter.unshift(Filter.buildFilter("providerId", providerId));
		filter.unshift(Filter.equal("benefitType", benefitType));
		const params = {
			select: [
				"id",
				"forDependents",
				"forRelatives",
				"allowDifferentPlanForDependent",
			],
			filter: buildQuery({ filter }).replace("?", "&"),
		} as QueryParams;

		const query = this.createBuildQuery(params);

		return this.http.get(`${this.endpoint}/offered/odata${query}`).pipe(
			map((res) => {
				const response = new Response<BenefitOfferedModel[]>();
				response.data = res.body.map((pp) => BenefitOfferedModel.create(pp));
				response.count = res.headers.get("x-count");
				return response;
			})
		);
	}

	getPlansByPetition(petitionId: string): Observable<BenefitPlanModel[]> {
		return this.http.get(`${this.endpoint}/petitions/${petitionId}/plans`).pipe(
			map((res) => {
				return res.body.map((pp) =>
					BenefitPlanModel.create({
						...pp,
						detailsFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.detailsFile}`,
					})
				);
			})
		);
	}

	getBenefitEmployee(id: string) {
		return this.http.get(`${this.endpoint}/employee/${id}`).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getPlan(id: string) {
		return this.http.get(`${this.endpoint}/plans/${id}`).pipe(
			map((res) => {
				return res;
			})
		);
	}
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class InfringementModel extends Model implements IModel<InfringementModel> {

  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  employeeId: string | undefined = undefined;
  date: string = '';
  typeId: string | undefined = undefined;
  typeTitle: string = '';
  description: string = '';
  amountTime: number = 0;

  static create(input: any): InfringementModel {
    return ObjectMapper.deserialize<InfringementModel>(InfringementModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get amountTimeLabel(): string {
    const hours = Math.floor(this.amountTime);
    const minutes = Math.round((this.amountTime - hours) * 100);

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }

}
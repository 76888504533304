import { Injectable } from "@angular/core";

import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { map } from "rxjs/operators";
import { HiringConfigModel } from "services/models/hiring/hiringConfig.model";
import { ToastService } from "@core/components/toasts/toasts.service";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HiringConfigService extends ServiceBase<HiringConfigModel> {

  private hiringConfigSubject = new BehaviorSubject<any>(null);

  constructor(
    http: HttpService,
    private toasterService: ToastService,
  ) {
    super("hiringconfig", http);
  }

  createEntity(input: any): HiringConfigModel {
    return HiringConfigModel.create(input);
  }

  getHiringConfigValue(): any {
    return this.hiringConfigSubject.value;
  }

  getConfigs(companyGroupId: string) {
    return this.http
      .get(`${this.endpoint}/configs/${companyGroupId}`)
      .pipe(
        map((res: any) => {
          this.hiringConfigSubject.next(res.body);
          return this.createEntity(res.body);
        })
      );
  }
  saveConfigs(configData: any) {
    return this.http.put(`${this.endpoint}`, configData).pipe(
      map((res: any) => {
        console.log(res); 
        this.toasterService.showSuccess("Configurações de admissão salvas com sucesso!"); 
        return res; 
      })
    );
  }
}

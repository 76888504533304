import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "services/auth.service";
import { DomainService } from "services/domain.service";

@Injectable({
  providedIn: "root",
})
export class LoginRequiredGuard implements CanActivate {
  currentUrl: string = "";

  constructor(
    private router: Router,
    private authService: AuthService,
    private _domainService: DomainService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.currentUserValue) {
      this.currentUrl = window.location.pathname.slice(1);
      const regex = /\b[a-f\d-]{36}\b/;
      const hasGuid = regex.test(this.currentUrl);
      if (hasGuid) {
        this.currentUrl = this.currentUrl.replace(regex, "");
        this.currentUrl = this.currentUrl.substring(
          0,
          this.currentUrl.length - 1
        );
      }

      if (!this.checkUrl(this._domainService.getAllowedUrls())) {
        if (
          window.location.pathname.includes("auth/login") ||
          window.location.pathname.includes("home")
        ) {
          return true;
        }
        return false;
      }
      return true;
    } else {
      const authRoute = this.router.createUrlTree(
        [route.parent.params.domainName, "auth", "login"],
        { queryParams: { returnUrl: state.url } }
      );

      return authRoute;
    }
  }

  checkUrl(allowedUrls: string[]) {
    if (this.checkMockedUrls()) {
      return true;
    }

    let checkPass = false,
      index = 0;
    while (checkPass === false && index < allowedUrls.length) {
      if (this.currentUrl.includes(allowedUrls[index])) checkPass = true;

      index++;
    }

    return checkPass;
  }

  checkMockedUrls() {
    const paths = ["corporate/benefit", "documents-management"];

    return paths.some((path) => window.location.href.includes(path));
  }
}

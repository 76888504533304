import { ObjectMapper } from "json-object-mapper";

export class SummaryEmployeeManagementModel {
  countInfringements: number = 0;
  countOvertimes: number = 0;
  countabsenses: number = 0;
  countEmployees: number = 0;

  static create(input: any): SummaryEmployeeManagementModel {
    return ObjectMapper.deserialize<SummaryEmployeeManagementModel>(SummaryEmployeeManagementModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
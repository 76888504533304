import { ApplicantDependentModel } from "../hiring/applicantDependent.model";
import { IModel } from "../IModel";
import { Model } from "../model";
import { BenefitModel as BenefitModelBase } from "./benefit.model";
import { BenefitPlanModel } from "./benefitPlan.model";
import { ObjectMapper } from 'json-object-mapper';

export class BenefitDependentModel extends Model implements IModel<BenefitDependentModel> {
	name: string = '';
	cpf: string = '';
	sex: string = '';
	birthDate: string = '';
	kinshipDegree: 'Spouse' | 'Child' | 'Stepson' | 'Parent' | 'Relative' = 'Child';
	applicantId: string | undefined = undefined;
	isActived?: boolean = false;
	isSelected?: boolean = false;
	plan?: BenefitPlanModel = null;

	get kinshipDegreeLabel(): string {
		switch (this.kinshipDegree as any) {
			case 'Spouse':
				return 'Cônjuge/Companheiro';
			case 'Child':
				return 'Filho';
			case 'Stepson':
				return 'Enteado';
			case 'Parent':
				return 'Pai/Mãe';
			case 'Relative':
				return 'Agregado/Outros';
			default:
				return '';
		}
	}

	static create(input: any): BenefitDependentModel {
		return ObjectMapper.deserialize<BenefitDependentModel>(
			BenefitDependentModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class BenefitModel extends BenefitModelBase {
	isActived: boolean = false;
	dependents: BenefitDependentModel[]
	benefitName?: string = "";
	benefitId?: string = "";
}

import { ObjectMapper } from "json-object-mapper";
import { TimeShiftModel } from "services/models/hiring/timeShift.model";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkforcePlanJobTitleGroupModel extends Model implements IModel<WorkforcePlanJobTitleGroupModel> {
	id: string = "";
	name: string = "";
	companyGroupId: string = "";

	static create(input: any): WorkforcePlanJobTitleGroupModel {
		return ObjectMapper.deserialize<WorkforcePlanJobTitleGroupModel>(
			WorkforcePlanJobTitleGroupModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class WorkforcePlanShifPositionModel extends Model implements IModel<WorkforcePlanShifPositionModel> {
	id: string = "";
	amount: number = 0;
	jobTitleId: string = "";
	jobTitlename: string = "";

	static create(input: any): WorkforcePlanShifPositionModel {
		return ObjectMapper.deserialize<WorkforcePlanShifPositionModel>(
			WorkforcePlanShifPositionModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class WorkforcePlanAmount extends WorkforcePlanShifPositionModel { }

export class WorkforcePlanShiftModel extends TimeShiftModel {
	id: string = "";
	name: string = "";
	amounts: WorkforcePlanAmount[] = [];
	positions: WorkforcePlanShifPositionModel[] = [];

	static create(input: any): WorkforcePlanShiftModel {
		return ObjectMapper.deserialize<WorkforcePlanShiftModel>(
			WorkforcePlanShiftModel,
			input
		);
	}
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit {
  @Input() queryParams: any;
  @Input() count = 0;
  @Input() filter = "";
  @Output() query: EventEmitter<any> = new EventEmitter();
  pageSize = 1;
  page = 1;
  perPage = 10;

  currentSizePage = 1;
  optionSize = [10, 30, 50, 100];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryParams) {
      this.queryParams = changes.queryParams.currentValue;

      this.currentSizePage = this.optionSize.findIndex(f => f === this.queryParams.perPage) + 1;
    }
  }

  ngOnInit(): void {
    this.perPage = this.queryParams.top;
  }

  pageChange() {
    this.queryParams.page = this.page;
    this.queryParams.top = this.perPage;
    this.queryParams.skip = this.perPage * (this.page - 1);
    this.queryParams.filter = this.filter ?? '';
    this.query.emit(null);
  }
  getPageLabel(size) {
    return this.optionSize[size - 1];
  }

  sizePageChange(e) {
    this.perPage = this.optionSize[e - 1];
    this.queryParams.top = this.perPage;
    this.query.emit(null);
  }

  maxPage(): number {
    return Math.ceil(this.count / this.perPage);
  }
}

import { BaseEnum } from "./baseEnum";

const listMovementRequestRaiseTypeEnum: Array<MovementRequestRaiseType> = [];

export class MovementRequestRaiseType extends BaseEnum {
	static Substitution = new MovementRequestRaiseType(0, 'Substitution', 'Substituição', 'light-danger');
	static Replacement = new MovementRequestRaiseType(1, 'Replacement', 'Reposição de quadro', 'light-success');
	static Expansion = new MovementRequestRaiseType(2, 'Expansion', 'Aumento de Quadro', 'light-info');

	constructor(public id: number, public value: string, public label: string, public className: string) {
		super(id, value, label, className);

		listMovementRequestRaiseTypeEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listMovementRequestRaiseTypeEnum;
	}
}

import { BaseEnum } from "./baseEnum";

const listKinshipDegreeTypeEnum: Array<KinshipDegreeTypeEnum> = [];

export type KinshipDegreeType =
	| "Spouse"
	| "Child"
	| "Stepson"
	| "Parent"
	| "Relative";

export class KinshipDegreeTypeEnum extends BaseEnum {
	static Spouse = new KinshipDegreeTypeEnum(1, "Spouse", "Cônjuge");
	static Child = new KinshipDegreeTypeEnum(2, "Child", "Filho(a)");
	static Stepson = new KinshipDegreeTypeEnum(3, "Stepson", "Enteado(a)");
	static Parent = new KinshipDegreeTypeEnum(4, "Parent", "Pai/Mãe");
	static Relative = new KinshipDegreeTypeEnum(5, "Relative", "Agregado");

	constructor(public id: number, public value: string, public label: string) {
		super(id, value, label);
		listKinshipDegreeTypeEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listKinshipDegreeTypeEnum;
	}
}

import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";

export class BenefitPetitionDetailsModel extends Model implements IModel<BenefitPetitionDetailsModel> {
	id: string = "";
	benefitPlanId: string = "";
	employeeId: string = "";
	employeeJobTitle: string = "";
	employeeName: string = "";
	requestType: string = "";
	status: string = "";
	providerId: string = "";
	benefitType: string = "";
	benefitDescription: string = "";
	providerName: string = "";
	providerLogo: string = "";
	benefitPetitionDependent: {
		id: string;
		employeeId: string;
		petitionId: string;
		dependentId: string;
		benefitPlanId: string;
		dependentName: string;
		dependentCpf: string;
		benefitDescription: string;
	}[] = []

	static create(input: any): BenefitPetitionDetailsModel {
		return ObjectMapper.deserialize<BenefitPetitionDetailsModel>(
			BenefitPetitionDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

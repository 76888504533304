import { IModel } from "../IModel";
import { Model } from "../model";
import { ObjectMapper } from "json-object-mapper";
import { BenefitsEmployeeDependentsModel } from "./benefitsEmployeeDependents.model";
import { BenefitPetitionDependentModel } from "./benefitPetitionDependent.model";
import { PetitionsRequestTypeEnum } from "services/enums/petitionsRequestType.enum";

export class PetitionDetailsModel
	extends Model
	implements IModel<PetitionDetailsModel> {
	benefitDescription: string = "";
	benefitEmployeeId: string = "";
	benefitType: string = "";
	employeeBenefitPlanId: string = "";
	employeeId: string = "";
	newEmployeeBenefitPlanId: string = "";
	providerId: string = "";
	providerLogo: string = "";
	providerName: string = "";
	requestType: string = "";

	benefitsEmployeeDependents: BenefitsEmployeeDependentsModel[] = [];
	dependentPetition: BenefitPetitionDependentModel[] = [];

	get benefitTypeBadge(): string {
		return `<span class="badge badge-light-primary text-wrap">${this.benefitDescription}</span>`;
	}

	get requestTypeBadge(): string {
		return `<span class="badge badge-light-${PetitionsRequestTypeEnum.getBy(this.requestType).className
			} text-wrap">${PetitionsRequestTypeEnum.getBy(this.requestType).label}</span>`;
	}

	static create(input: any): PetitionDetailsModel {
		if ("benefitsEmployeeDependents" in input && input.benefitsEmployeeDependents !== null) {
			input.benefitsEmployeeDependents = input.benefitsEmployeeDependents.map(
				BenefitsEmployeeDependentsModel.create
			);
		}

		if ("dependentPetition" in input) {
			input.dependentPetition = input.dependentPetition.map(
				BenefitPetitionDependentModel.create
			);
		}

		return ObjectMapper.deserialize<PetitionDetailsModel>(
			PetitionDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

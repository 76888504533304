import { map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CepModel } from './models/cep.model';
import { Response } from './models/response';
import { BankModel } from './models/hiring/bank.model';

@Injectable()
export class GeographyService {
  private readonly cepBaseUrl = environment.cepService;
  private readonly ibgeBaseUrl = environment.ibgeService;
  private readonly brasilUrl = 'https://brasilapi.com.br/api/';

  public readonly defaultCountry = 'Brasil';

  constructor(private _httpClient: HttpClient) {}

  public search(cep: string): Observable<Response<CepModel>> {
    return new Observable<Response<CepModel>>((observer) => {
      const http = new XMLHttpRequest();
      http.open('get', this.getEndpoint(cep), true);
      http.send();

      http.onload = function () {
        const response = new Response<CepModel>();
        response.data = CepModel.create(JSON.parse(this.response));

        observer.next(response);
      };
    });
  }

  public getUfs() {
    return this._httpClient.get(`${this.ibgeBaseUrl}/estados?orderBy=nome`);
  }

  public getPlaces() {
    return this._httpClient.get(`${this.ibgeBaseUrl}/municipios?orderBy=nome`);
  }

  getPlacesByUF(uf: string) {
    return this._httpClient.get(
      `${this.ibgeBaseUrl}/estados/${uf}/distritos?orderBy=nome`
    );
  }

  public getCountries() {
    return this._httpClient.get(`${this.ibgeBaseUrl}/paises?orderBy=nome`);
  }

  private getEndpoint(cep: string): string {
    return this.cepBaseUrl.replace('{cep}', cep);
  }

  public getBanks(): Observable<Response<BankModel[]>> {
    return this._httpClient.get(`${this.brasilUrl}banks/v1`).pipe(
      map((res: any) => {
        const response = new Response<BankModel[]>();
        response.data = res
          .map((e: BankModel) => BankModel.create(e))
          .filter((e: { code: BankModel; }) => e.code)
          .sort((a: { name: any; }, b: { name: any; }) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
          return response;
    }));
  }
}

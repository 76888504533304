import { IntegratedReportsParametersModel } from "./integratedReportsParameters.model";
import { CoreMenuItem } from "@core/types";
import { ObjectMapper } from "json-object-mapper";
import { IModel } from "./IModel";
import { Model } from "./model";
import { VacationManagementParametersModel } from "./vacationManagementParameters.model";

export type vacationPermissionType = 'programmingWithAllowance' | 'programmingWithoutAllowance' | 'requestForSubordinate' | 'requestProgramming';

export class DomainModel extends Model implements IModel<DomainModel> {
  name: string = "";
  razaoSocial: string = "";
  domainName: string = "";
  cnpj: string = "";
  adress: string = "";
  image: string = "";
  zipCode: string = "";
  enable: boolean = false;
  mobileToclockin: boolean = false;
  requiredResetPassword: boolean = false;
  requiredStrongPassword: boolean = false;
  serverTime: Date = undefined;
  integratedReportsParameters: IntegratedReportsParametersModel =
    new IntegratedReportsParametersModel();
  vacationManagementParameters: VacationManagementParametersModel =
    new VacationManagementParametersModel();
  validationType: string = "";
  allowedUrls: string[] = [];
  menu: CoreMenuItem[] = [];
  featureFlag: string = "";
  // defaultPageUrl: string = '';

  static create(input: any): DomainModel {
    return ObjectMapper.deserialize<DomainModel>(DomainModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

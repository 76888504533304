<div class="paginator">
  <div class="col-md-4 col-12">
    <div class="pageSize">
      <p class="pageSizeText">Registros por página (Total: {{count}})</p>
      <ngb-pagination (pageChange)="sizePageChange($event)" [directionLinks]="false" [collectionSize]="40"
      [(page)]="currentSizePage" [maxSize]="10">
      <ng-template ngbPaginationNumber let-p>{{ getPageLabel(p) }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
  <div class="col-md-8 col-12">
    <div class="collectionSize">
      <p class="collectionSizeText">Escolher página:</p>
      <ngb-pagination [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize" [collectionSize]="maxPage()" (pageChange)="pageChange()">
      </ngb-pagination>
    </div>
  </div>
</div>
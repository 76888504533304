import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "environments/environment";
import { DomainService } from "./domain.service";
import { LoginRequestModel } from "./models/login.model";
import { ClaimModel } from "./models/claim.model";
import ClaimTypes from "./enums/claimType.enum";
import { EmployeeService } from "./corporate/employee.service";
import { EmployeeModel } from "./models/employee.model";
import { UserType } from "./enums/userType.enum";

@Injectable({ providedIn: "root" })
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public employeeSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private readonly _KEY: string = "currentRhUser";
  private readonly _EMPLOYEE: string = "employee";

  constructor(
    private http: HttpClient,
    private employeeService: EmployeeService,
    private domainService: DomainService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem(this._KEY))
    );
    this.employeeSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem(this._EMPLOYEE))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get employeeValue(): any {
    return this.employeeSubject.value;
  }

  public getToken(): string {
    return JSON.parse(localStorage.getItem(this._KEY))?.accessToken;
  }

  public GetName(): string {
    const jwtHelper = new JwtHelperService();
    const payload = jwtHelper.decodeToken(this.getToken());
    if (!payload) return undefined;
    var t = payload["user_name"];
    var FirstName = t
      ? t.split(" ")[0].charAt(0).toUpperCase() +
        t.split(" ")[0].substr(1).toLowerCase()
      : "";
    var SecondName = t
      ? t.split(" ")[1].charAt(0).toUpperCase() +
        t.split(" ")[1].substr(1).toLowerCase()
      : "";
    return FirstName + " " + SecondName;
  }

  public GetCompany(): string {
    const jwtHelper = new JwtHelperService();
    const payload = jwtHelper.decodeToken(this.getToken());
    if (!payload) return undefined;
    return payload["user_company"];
  }

  public getDomainName(): string {
    const claim = this.getClaims().find(
      (f) => f.type === ClaimTypes.domainName
    );
    return claim?.value;
  }

  public getShortProfileImagePath(): string {
    return JSON.parse(localStorage.getItem(this._EMPLOYEE))
      ?.shortProfileImagePath;
  }

  public getProfileImagePath(): string {
    return JSON.parse(localStorage.getItem(this._EMPLOYEE))?.profileImagePath;
  }

  public getUserId(): string {
    const claim = this.getClaims().find((f) => f.type === ClaimTypes.user_id);
    return claim?.value;
  }

  public getUserType(): string {
    const claim = this.getClaims().find((f) => f.type === ClaimTypes.user_type);
    return claim?.value;
  }

  public getUserRoles(): string[] {
    const claim = this.getClaims().find((f) => f.type === ClaimTypes.role);
    if (!claim) return undefined;

    return claim.value.split(",");
  }

  public getUserActions(): string[] {
    const claim = this.getClaims().find((f) => f.type === ClaimTypes.action);
    if (!claim) return undefined;

    return claim.value.split(",");
  }

  public getUserLevels(): number[] {
    const claim = this.getClaims().find(
      (f) => f.type === ClaimTypes.user_levels
    );
    if (!claim) return [];

    return (claim.value.split(",") || []).map((level: string) =>
      parseInt(level)
    );
  }

  private getClaims(): ClaimModel[] {
    const jwtHelper = new JwtHelperService();
    const payload = jwtHelper.decodeToken(this.getToken());
    if (payload) {
      const claims = Object.entries(payload).map(([key, value]) =>
        ClaimModel.create(key, `${value}`.toLowerCase())
      );
      return claims;
    } else return [];
  }

  public getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return headers;
  }

  login(params: LoginRequestModel): Observable<any> {
    return new Observable<any>((observer) => {
      const domain = this.domainService.getDomain();
      const _headers = new HttpHeaders({
        "Content-Type": "application/json",
        DomainName: domain.domainName,
      });
      this.http
        .post<any>(`${environment.apiUrl}/account/auth`, params, {
          headers: _headers,
        })
        .subscribe(
          (user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this._KEY, JSON.stringify(user));

            //set employee if the user is a employee type
            this.setEmployeeWhenUserTypeIsEmployee();

            this.currentUserSubject.next(user);
            observer.next(user);
            observer.complete();
          },
          (err) => {
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  setEmployeeWhenUserTypeIsEmployee() {
    if (this.getUserType() == "employee") {
      this.getEmployeeByUserId();
    }
  }

  getEmployeeByUserId() {
    this.employeeService
      .get(this.getUserId())
      .subscribe((res) => this.setEmployeeOnStorage(res.data));
  }

  setEmployeeOnStorage(employee: EmployeeModel) {
    const object = {
      id: employee.id,
      shortProfileImagePath: employee.shortProfileImagePath,
      profileImagePath: employee.profileImagePath,
    };
    localStorage.setItem(this._EMPLOYEE, JSON.stringify(object));
    this.employeeSubject.next(object);
  }

  removeEmployeeOnStorage() {
    localStorage.removeItem(this._EMPLOYEE);
    this.employeeSubject.next(null);
  }

  logout() {
    localStorage.removeItem(this._KEY);
    localStorage.removeItem(this._EMPLOYEE);
    this.currentUserSubject.next(null);
    this.employeeSubject.next(null);
    this.domainService
      .checkDomainName(this.domainService.getDomain().domainName, true)
      .subscribe((domain) => {
        this.domainService.setDomain(domain);
      });
  }

  userContainsRoles(...userRoles: string[]): boolean {
    const roles = this.getUserRoles();
    if (roles) {
      return roles.some((r) => userRoles.includes(r));
    }
  }

  userContainsActions(...userActions: string[]): boolean {
    const actions = this.getUserActions();
    if (actions) {
      return actions.some((r) => userActions.includes(r));
    }
  }

	get payload(): any | undefined {
    const jwtHelper = new JwtHelperService();
    const payload = jwtHelper.decodeToken(this.getToken());

		if (!payload) return undefined;

    return payload;
  }

  get isOutsourcedLiableUser(): boolean {
		if (!this.payload) return false;

    return this.payload?.user_type === UserType.OutsourcedLiable.value;
  }
}

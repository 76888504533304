import { ObjectMapper } from "json-object-mapper";
import moment from "moment-business-days";
import { ExitInterviewStatusType } from "services/enums/ExitInterviewStatusType.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import { ExitInterviewEventModel } from "./exitInterviewEvent.model";
import { TypeExitInverviewModel } from "./typeExitInterview.model";

export class ExitInterviewModel
  extends Model
  implements IModel<ExitInterviewModel> {
  companyId: string | undefined = undefined;
  companyName: string = "";
  employeeId: string | undefined = undefined;
  typeId: string | undefined = undefined;
  type: TypeExitInverviewModel = new TypeExitInverviewModel();
  employeeName: string = "";
  employeeCPF: string = "";
  employeeRegistration: string = "";
  scheduledDate: string = "";
  resignationDate: string = "";
  companyGroupId: string | undefined = undefined;
  status: any = ExitInterviewStatusType.PENDING.value;
  statusText: string = "";
  justification: string = "";
  events: ExitInterviewEventModel[] = [];

  get statusBadge(): string {
    const status = ExitInterviewStatusType.getBy(this.status);
    const color = this.hasExpired ? "danger" : status?.className;
    const label = this.hasExpired ? "Atrasada" : status?.label;

    return `<span class="badge bg-${color} rounded-pill text-wrap">${label}</span>`;
  }

  static create(input: any): ExitInterviewModel {
    return ObjectMapper.deserialize<ExitInterviewModel>(
      ExitInterviewModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get hasExpired(): boolean {
    const dateEnd = moment(this.scheduledDate);
    const today = moment();
    if (
      today > dateEnd &&
      [ExitInterviewStatusType.CREATED.value, ExitInterviewStatusType.LATE.value, ExitInterviewStatusType.PENDING.value].includes(this.status)
    ) {
      return true;
    }
    return false;
  }
}

import { BaseEnum } from "./baseEnum";

const listActionRequiredSourceEnum: Array<ActionRequiredSource> = [];

export class ActionRequiredSource extends BaseEnum {
    static None = new ActionRequiredSource(0, 'None', 'Nenhum');
    static WorforcePlan = new ActionRequiredSource(1, 'WFP', 'Orgânico ideal');
    static Vacation = new ActionRequiredSource(2, 'VACATION', 'Férias');
    static PointTimeHandling = new ActionRequiredSource(3, 'POINT_TIME_HANDLING', 'Ponto');
    static Benefit = new ActionRequiredSource(4, 'BENEFIT', 'benefício');

    constructor(public id: number, public value: string, public label: string) {
        super(id, value, label);

        listActionRequiredSourceEnum.push(this);
    }

    public static buildListEnum(): Array<BaseEnum> {
        return listActionRequiredSourceEnum;
    }
}

import { Injectable } from "@angular/core";
import { FileService } from "@core/services/file.service";
import { environment } from "environments/environment";
import { HttpService } from "../http.service";
import { ServiceBase } from "../servicebase";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { QueryParams } from "services/models/odata/queryParams";
import { map } from "rxjs/operators";
import { OrganizationModel } from "services/models/organization.model";
import { ObjectMapper } from "json-object-mapper";

@Injectable()
export class OrganizationService extends ServiceBase<OrganizationModel> {
  constructor(http: HttpService, private file: FileService) {
    super("company", http, environment.apiUrl);
  }

  createEntity(input: any): OrganizationModel {
    return OrganizationModel.create(input);
  }

  listOrganizations(): Observable<Response<OrganizationModel[]>> {
    const params = {
      select: ["name", "cnpj", "id", "enable", "creationDate"],
      orderBy: "name",
      orderByOrder: "asc",
    } as QueryParams;

    const query = this.createBuildQuery(params);
    return this.http
      .get(
        `${this.endpoint}/list-organization${query}`,
        undefined,
        this.baseUrl
      )
      .pipe(
        map((res) => {
          const response = new Response<OrganizationModel[]>();
          response.data = res.body.map(
            (pp) => this.createEntity(pp) as OrganizationModel
          );
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  saveOrganization(id: string | undefined, obj: any) {
    let _object = ObjectMapper.serialize(obj);
    return id == undefined
      ? this.http.post(`${this.endpoint}/organization`, _object)
      : this.http.put(
          `${this.endpoint}/organization`,
          JSON.parse(_object.toString())
        );
  }
}

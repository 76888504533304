import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { AuthService } from "services/auth.service";
import { DomainService } from "services/domain.service";
import { DomainModel } from "services/models/domain.model";

import { pageClaims } from "app/page-access";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean = true;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;
  public Name: string;
  public Company: string;
  public shortProfileImagePath: string;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  domain: DomainModel;
  currentUser: any;
  isEmployeeUserType: boolean = false;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    private _domainService: DomainService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService
  ) {
    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      fr: {
        title: "French",
        flag: "fr",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authService.logout();
    this.router.navigateByUrl(`/${this.domain.domainName}/auth/login`);
  }

  redirectToProfile() {
    this.router.navigateByUrl(`/${this.domain.domainName}/profile`);
  }

  redirectToHonme() {
    this.router.navigateByUrl(`/${this.domain.domainName}/home`);
  }

/**
   * Settings method
   */
   hasAdminPermissions(): boolean {
    return this._authService.userContainsRoles(
      ...pageClaims.admin.canAccessSettings
    );
  }

  redirectToSettings() {
    this.router.navigateByUrl(`/${this.domain.domainName}/settings`);
  }
 
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._domainService.domainSubject
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((domain) => (this.domain = domain));

    this._authService.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => (this.currentUser = user));
    this.isEmployeeUserType = this._authService.getUserType() == "employee";

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });

    this.Name = this._authService.GetName();
    this.Company = this._authService.GetCompany();
    this.setShortProfileImagePath();
  }

  setShortProfileImagePath() {
    this._authService.employeeSubject.subscribe(res => {
      this.shortProfileImagePath = res?.shortProfileImagePath;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

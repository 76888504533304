import { DocumentTypeModel } from './document-type.model';
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './../IModel';
import { Model } from './../model';
import { JobTitleModel } from './jobTitle.model';

export class JobTitleDocumentModel
 extends Model 
 implements IModel<JobTitleDocumentModel> 
 {
  jobTitleId: string | undefined = undefined;
  jobTitle: JobTitleModel = new JobTitleModel();
  documentTypeId: string | undefined = undefined;
  required: boolean = true;
  forDependents: boolean = false;
  sideType: number = 1 | 2 | 3;
  twoSides: boolean = false;
  order: number = 99;
  documentType: DocumentTypeModel = new DocumentTypeModel();
  companyName: string | undefined = undefined; 
  code: string | undefined = undefined; 
  title: string | undefined = undefined; 
  action: number = 0;
  
  static create(input: any): JobTitleDocumentModel {
    return ObjectMapper.deserialize<JobTitleDocumentModel>(
      JobTitleDocumentModel, 
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

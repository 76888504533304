import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { DomainGuard } from "@core/guards/domain.guard";
import { LoginRequiredGuard } from "@core/guards/login-required.guard";
import { coreConfig } from "app/app-config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";

import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { CoreToastsModule } from "@core/components/toasts/toasts.module";
import { CoreModule } from "@core/core.module";
import { setAppInjector } from "@core/injector/app-injector.service";
import { ErrorInterceptor } from "@core/interceptors/error.interceptor";
import { HttpconfigInterceptor } from "@core/interceptors/httpconfig.interceptor";
import { LoadingScreenInterceptor } from "@core/interceptors/loading-screen.interceptor";
import { ToastrModule } from "ngx-toastr";
import { ServicesModule } from "services/services.module";
import { AfdComponent } from "./views/pages/clock/afd/afd.component";
import { SharedModule } from "./views/shared/shared.module";

registerLocaleData(localePt);
const appRoutes: Routes = [
	{
		path: "",
		loadChildren: () =>
			import("./views/pages/miscellaneous/miscellaneous.module").then(
				(m) => m.MiscellaneousModule
			),
	},
	{
		path: ":domainName",
		canActivate: [DomainGuard],
		children: [
			{ path: "", redirectTo: "clock", pathMatch: "full" },
			{
				path: "clock",
				loadChildren: () =>
					import("./views/pages/clock/clock.module").then((m) => m.ClockModule),
			},
			{
				path: "afd",
				component: AfdComponent,
				data: { animation: "misc" },
				canActivate: [LoginRequiredGuard],
				loadChildren: () =>
					import("./views/pages/clock/clock.module").then((m) => m.ClockModule),
			},
			{
				path: "home",
				loadChildren: () =>
					import("./views/pages/home/home.module").then((m) => m.HomeModule),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "reports",
				loadChildren: () =>
					import("./views/pages/reports/reports.module").then(
						(m) => m.RelatoriosModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "hiring",
				loadChildren: () =>
					import("./views/pages/hiring/hiring.module").then(
						(m) => m.HiringModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "docs",
				loadChildren: () =>
					import("./views/pages/documents/documents.module").then(
						(m) => m.DocumentsModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "corporate",
				loadChildren: () =>
					import("./views/pages/corporate/corporate.module").then(
						(m) => m.CorporateModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "offboarding",
				loadChildren: () =>
					import("./views/pages/offboarding/offboarding.module").then(
						(m) => m.OffboardingModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "profile",
				loadChildren: () =>
					import("./views/pages/employee-profile/employee-profile.module").then(
						(m) => m.EmployeeProfileModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "workforceplan",
				loadChildren: () =>
					import("./views/pages/workforce-plan/workforce-plan.module").then(
						(m) => m.WorkForcePlanModule
					),
				canActivate: [LoginRequiredGuard],
			},
			{
				path: "frmadm/:CPF",
				loadChildren: () =>
					import(
						"./views/pages/hiring/admissionForm/admissionform.module"
					).then((m) => m.AdmissionFormModule),
			},
			{
				path: "auth",
				loadChildren: () =>
					import("./views/pages/authentication/authentication.module").then(
						(m) => m.AuthenticationModule
					),
			},
			{
				path: "settings",
				loadChildren: () =>
					import("./views/pages/settings/settings.module").then(
						(m) => m.SettingsModule
					),
				canActivate: [LoginRequiredGuard],
			},
		],
	},
	{
		path: "**",
		redirectTo: "error", // error 404 - Page not found
	},
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRoutes, {
			scrollPositionRestoration: "enabled", // Add options right here
			relativeLinkResolution: "legacy",
		}),
		TranslateModule.forRoot(),
		ToastrModule.forRoot({
			positionClass: "toast-bottom-center",
			closeButton: true,
		}),
		ServicesModule,
		SharedModule,
		//NgBootstrap
		NgbModule,
		// Core modules
		CoreModule.forRoot(coreConfig),
		CoreCommonModule,
		CoreSidebarModule,
		CoreToastsModule,
		CoreThemeCustomizerModule,
		LayoutModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpconfigInterceptor,
			multi: true,
		},
		{
			provide: LOCALE_ID,
			useValue: "pt",
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingScreenInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		DomainGuard,
		LoginRequiredGuard,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(injector: Injector) {
		setAppInjector(injector);
	}
}

import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ToastService } from "@core/components/toasts/toasts.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { combineLatest } from "rxjs";
import { VacationScheduleService } from "services/corporate/vacationSchedule.service";
import { EmployeeService } from "services/corporate/employee.service";
import { ReferencePeriodVacationScheduleModel } from "services/models/corporate/referencePeriodVacationSchedule.model";
import { EmployeeModel } from "services/models/employee.model";
import { CreateVacationScheduleModalComponent } from "../create-vacation-schedule-modal/create-vacation-schedule-modal.component";
import { ReferencePeriodVacationModel } from "services/models/corporate/referencePeriodVacation.model";
import { Filter } from "services/models/odata/filter";
import { QueryParams } from "services/models/odata/queryParams";
import { ReferencePeriodStatusType } from "services/enums/ReferencePeriodStatusType.enum";
import moment from "moment-business-days";
import { AuthService } from "services/auth.service";
import { ConfirmDialogComponent } from "app/layout/components/confirm-dialog/confirm-dialog.component";
import { VactionScheduleStatusType } from "services/enums/VactionScheduleStatusType.enum";
import { VacationLimitType } from "services/enums/VacationLimitType.enum";
import { toGUID } from "utils/helps";
import { VacationManagementParametersModel } from "services/models/vacationManagementParameters.model";

@Component({
  selector: "app-vacations-schedule-modal-list",
  templateUrl: "./vacations-schedule-modal-list.component.html",
  styleUrls: ["./vacations-schedule-modal-list.component.scss"],
})
export class VacationsScheduleModalListComponent implements OnInit {
  headers = [
    "Data Programação",
    "Sequência",
    "Qtd Dias de Férias",
    "Qtd Dias de Abono",
    "Status",
  ];

  @Input() referencePeriod: ReferencePeriodVacationModel;

  isSameUser: boolean = false;
  employee: EmployeeModel = new EmployeeModel();
  vacationSchedules: ReferencePeriodVacationScheduleModel[] = [];
  vacationManagementParameters: VacationManagementParametersModel;

  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _employeeService: EmployeeService,
    private _service: VacationScheduleService,
    private _datePipe: DatePipe,
    private toasterService: ToastService
  ) { }

  ngOnInit(): void {
    this.vacationManagementParameters = this._service.domainObj.vacationManagementParameters;

    this.initialRequest();
  }

  initialRequest() {
    combineLatest([
      this.getEmployee(),
      this.getReferencePeriodVacationSchedules(),
    ]).subscribe(([employee, history]) => {
      this.employee = employee.data;
      this.vacationSchedules = history.data;
      this.verifySameUser();
    });
  }

  getEmployee = () =>
    this._employeeService.get(this.referencePeriod.employeeId);

  getReferencePeriodVacationSchedules = () => {
    const queryParams = {
      select: [
        "date",
        "amountDaysVacation",
        "amountDaysAllowance",
        "status",
        "referencePeriodId",
        "sequence",
      ],
      orderBy: "date",
      orderByOrder: "asc",
      filter: Filter.equalToGuid("referencePeriodId", this.referencePeriod.id),
    } as QueryParams;
    return this._service.list(queryParams);
  };

  onDismiss(): void {
    this.activeModal.close(true);
  }

  isInUnavailableStatus(statusToCheck: string) {
    const invalidStatusToAdd = [ReferencePeriodStatusType.Paid.value, ReferencePeriodStatusType.AroundDue.value, ReferencePeriodStatusType.Scheduled.value];
    const pendingOrApprovedSchedules = this.vacationSchedules.filter(s => s.status !== VactionScheduleStatusType.Rejected.value);
    const scheduledOrRequestedDays = pendingOrApprovedSchedules.reduce((previous: number, current) => {
      previous += current.amountDaysAllowance + current.amountDaysVacation;

      return previous;
    },
      0
    );

    return invalidStatusToAdd.includes(statusToCheck) || scheduledOrRequestedDays === 30 || moment(this.referencePeriod.dateLimit) < moment();
  }

  formatDate(date: string) {
    return moment(date).format("DD/MM/YYYY").toString();
  }

  canView() {
    return this._service.isManager || this._service.isVacationManager || this._service.hasWithoutAllowancePermission;
  }

  canChange(status: string) {
    if (status === VactionScheduleStatusType.Pending.value) return true;

    if (this._service.isManager && !this.isNearToStart()) return true;

    if (this._service.isVacationManager || this._service.hasWithoutAllowancePermission) return true;

    return false;
  }

  canApprove(index: number, status: string) {
    if (this._service.isVacationManager || this._service.hasWithoutAllowancePermission) {
      const isFirstPending = this.vacationSchedules.findIndex(v => v.status === 'Pending') === index;
      return status === 'Pending' && isFirstPending && this.canManagerApprove(index);
    }

    return false;
  }

  canManagerApprove(index: number): boolean {
    const schedule = this.vacationSchedules[index];
    const isCustomVacationLimit =
      this.vacationManagementParameters.vacationLimitDateType ===
      VacationLimitType.Custom.value;
    const creationDate = moment(schedule.creationDate);
    const scheduleDate = moment(schedule.date);

    const isSameMonth = scheduleDate.month() === creationDate.month();
    const isNextMonth =
      scheduleDate.month() === creationDate.add(1, "month").month();
    const hasExceededCutDate =
      creationDate.date() > this.vacationManagementParameters.vacationCutDate;

    if (
      (!this._service.isVacationManager && !this._service.hasWithoutAllowancePermission) &&
      isCustomVacationLimit &&
      (isSameMonth || (hasExceededCutDate && (isSameMonth || isNextMonth)))
    ) {
      return false;
    }

    return true;
  }

  canRemove(index: number, status: string) {
    if (status != VactionScheduleStatusType.Approved.value) return true;

    const isLast = this.vacationSchedules.length - 1 === index;

    if (this._service.isManager && !this.isNearToStart() && isLast) return true;

    if ((this._service.isVacationManager || this._service.hasWithoutAllowancePermission) && isLast) return true;

    return false;
  }

  isNearToStart(): boolean {
    return (
      this.vacationSchedules.filter(
        (f) => moment(f.date).diff(moment(), "days") < 30
      ).length > 0
    );
  }

  addVacationSchedule() {
    if (!this._service.hasRequestProgrammingPermission) {
      this.toasterService.showWarning(
        `Você não tem permissão para criar nova Solicitação de Férias.`
      );

      return;
    }
    if (!this._service.hasRequestForSubordinatePermission && toGUID(this._authService.getUserId()) !== this.employee.id) {
      this.toasterService.showWarning(
        `Você não tem permissão para criar nova Solicitação de Férias para subordinados.`
      );

      return;
    }

    if (this.isInUnavailableStatus(this.referencePeriod.status)) {
      this.toasterService.showWarning(
        `Novas Programações indisponíveis para este Período Aquisitivo: ${this.formatDate(
          this.referencePeriod.dateBegin
        )} a ${this.formatDate(this.referencePeriod.dateEnd)}`
      );
      return;
    }

    const modalRef = this._modalService.open(
      CreateVacationScheduleModalComponent,
      {
        centered: true,
        size: "md",
        backdrop: "static",
      }
    );
    modalRef.componentInstance.referencePeriod = this.referencePeriod;
    modalRef.componentInstance.isSameUser = this.isSameUser;
    modalRef.closed.subscribe(() => this.initialRequest());
  }

  manageVacationSchedule(
    vacationSchedule: ReferencePeriodVacationScheduleModel,
    onlyView = false
  ) {
    const modalRef = this._modalService.open(
      CreateVacationScheduleModalComponent,
      {
        centered: true,
        size: "md",
        backdrop: "static",
      }
    );
    modalRef.componentInstance.referencePeriod = this.referencePeriod;
    modalRef.componentInstance.vacationSchedule = vacationSchedule;
    modalRef.componentInstance.onlyView = onlyView;
    modalRef.closed.subscribe(() => this.initialRequest());
  }

  removeVacationSchedule(
    vacationSchedule: ReferencePeriodVacationScheduleModel
  ) {
    const modalRef = this._modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.message = `Deseja realmente excluir a programação do dia ${this.formatDate(
      vacationSchedule.date
    )}?`;
    modalRef.componentInstance.focusConfirm = true;
    modalRef.closed.subscribe((submit) => {
      if (submit) {
        this._service.removeSchedule(vacationSchedule).subscribe((res) => {
          this.toasterService.showSuccess("Programação excluída com sucesso!");
          this.initialRequest();
        });
      }
    });
  }

  verifySameUser() {
    this.isSameUser = toGUID(this._authService.getUserId()) === this.employee.id;
  }
}

<div class="modal-header">
  <h4 class="modal-title" *ngIf="!onlyView">Programar Férias</h4>
  <h4 class="modal-title" *ngIf="onlyView">Aprovação - Programação de Férias</h4>
  <button type="button" (click)="onDismiss()" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12">
      <form class="form-horizontal" [formGroup]="form" #frm>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-control-label cursor-pointer" for="date" popoverTitle="Início do Gozo de Férias"
                ngbPopover="Permitido até 3 programações. Deve respeitar os dias do período selecionado.
              A programação deve ser realizada pelo menos 30 dias antes do início do gozo de férias."
                triggers="mouseenter:mouseleave"><span [data-feather]="'info'"></span> Data da Programação</label>
              <input type="date" id="date" formControlName="date" required class="form-control" />
              <app-error-msg [control]="form.controls['date']" [label]="'date'"> </app-error-msg>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="cursor-pointer" for="amountDaysVacation" popoverTitle="Férias" ngbPopover="Pelo menos uma programação deve conter 14 ou mais dias corridos.
              Não é permitida programação inferior a 5 dias corridos." triggers="mouseenter:mouseleave"><span
                  [data-feather]="'info'"></span> Quantidade de dias - Férias</label>
              <input type="text" id="amountDaysVacation" formControlName="amountDaysVacation" required
                class="form-control" mask="99" (keypress)="omitChar($event)" />
              <app-error-msg [control]="form.controls['amountDaysVacation']" [label]="'amountDaysVacation'">
              </app-error-msg>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="cursor-pointer" for="amountDaysAllowance" popoverTitle="Abono Pecuniário"
                ngbPopover="Limitado a 1/3 dos dias de saldo, com máximo de 10 dias."
                triggers="mouseenter:mouseleave"><span [data-feather]="'info'"></span> Quantidade de dias -
                Abono</label>
              <input type="text" id="amountDaysAllowance" formControlName="amountDaysAllowance" required
                class="form-control" mask="99" (keypress)="omitChar($event)" />
              <app-error-msg [control]="form.controls['amountDaysAllowance']" [label]="'amountDaysAllowance'">
              </app-error-msg>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="!onlyView" class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="onDismiss()" rippleEffect>
    Cancelar
  </button>
  <button type="button" (click)="handleSubmit()" #btnConfirm class="btn btn-success" rippleEffect>
    {{actionText}}
  </button>
</div>

<div *ngIf="onlyView" class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="openApproveVacationSchedule(2)" rippleEffect>
    Rejeitar
  </button>
  <button type="button" (click)="openApproveVacationSchedule(1)" #btnConfirm class="btn btn-success" rippleEffect>
    Aprovar
  </button>
</div>
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";
import { environment } from "environments/environment";
import { DomainService } from "services/domain.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChooseReportDialogComponent } from "app/views/pages/reports/choose-report-dialog/choose-report-dialog.component";

@Injectable({
  providedIn: "root",
})
export class LaborReportService {
  BASE_API_URL = environment.apiUrl;
  public ApiDataTime: any;
  public Year: number;

  constructor(
    private _modalService: NgbModal,
    private _domainService: DomainService,
    private http: HttpClient
  ) {}

  downloadContrachequePDF(
    ano: number,
    mes: number,
    tipo: number
  ): Observable<any> {
    return this.requestReport(`report/paycheck/${ano}/${mes}/${tipo}`);
  }

  downloadEspelhopontoPDF = (ano: number, mes: number): Observable<any> =>
    this.requestReport(`report/workinghours/${ano}/${mes}`);

  downloadRendimentosPDF = (ano: number): Observable<any> =>
    this.requestReport(`report/yield/${ano}`);

  downloadBancodeHorasPDF = (inicio: string, fim: string): Observable<any> =>
    this.requestReport(`report/annualleave/${inicio}/${fim}`);

  downloadReciboFeriasPDF = (ano: string, mes: string): Observable<any> =>
    this.requestReport(`report/vacation/${ano}/${mes}`);

  getDataTime(): Observable<any> {
    return this.http.get<any>(
      this.BASE_API_URL.replace("/api/v1", "") + "/current-time",
      { responseType: "text" as "json" }
    );
  }

  requestReport(url): Observable<any> {
    return new Observable<any>((observer) => {
      const subscription = this._domainService.domainSubject.subscribe((domain) => {
          if (!domain || !domain.domainName) {
            observer.error({ message: "Domínio não encontrado" });
            observer.complete();
            subscription.unsubscribe(); 
            return;
          }
          const _headers = new HttpHeaders({
            "Content-Type": "application/json",
            DomainName: domain.domainName,
          });
          this.http
          .get(`${this.BASE_API_URL}/${url}`, {
            headers: _headers,
          })
          // .pipe(
          //   map((data: any) => {
          //   }),
          //   timeout(60000)
          // )
          .subscribe(
            (response: any) => {
              if (
                response &&
                Object.prototype.toString.call(response) == "[object Array]" &&
                response.length == 0
              ) {
                observer.error({});
              } else if (response) {
                this.createReportPage(response, false);
                observer.next(response);
                observer.complete();
              } else observer.error({});
                subscription.unsubscribe();
              },
              (err) => {
                observer.error(err);
                subscription.unsubscribe(); 
              }
            );
        }
      );
    });
  }

  
  createReportPage(data: any, print = false) {
    if (Object.prototype.toString.call(data) == "[object Array]") {
      if (data.length > 1) {
        const modalRef = this._modalService.open(ChooseReportDialogComponent, {
          size: "sm",
        });
        modalRef.componentInstance.availablesReports = data.map((d, idx) => {
          return { label: d["label"], index: idx };
        });
        modalRef.result
          .then((result) => {
            this.openReportPage(data[result]["reportBody"], print);
          })
          .catch((err) => {
            console.log(err);
          });
      } else this.openReportPage(data[0]["reportBody"], print);
    } else this.openReportPage(data["reportBody"], print);
  }

  openReportPage(htm: string, print: boolean) {
    const win = window.open();
    const winUrl = URL.createObjectURL(new Blob([htm], { type: "text/html" }));
    win.location.href = winUrl;
    win.focus();
    if (print)
      setTimeout(() => {
        win.print();
      }, 2000);
  }
}

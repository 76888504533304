import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Pipe({
    name: 'controlsFilter'
})
export class ControlsFilterPipe implements PipeTransform {
    transform(controls: AbstractControl[], filtro: string): any {
        if (filtro) {
            filtro = filtro.toUpperCase();
            
            return controls.filter(a =>
                a.get('employee').value.name.toUpperCase().indexOf(filtro) >= 0 || a.get('employee').value.registration.indexOf(filtro) >= 0
            );
        } else {
            return controls;
        }
    }
}
import { BaseEnum } from "./baseEnum";

const WorkforceplanSolicitationStatusEnum: Array<WorkforceplanSolicitationStatus> = [];

export class WorkforceplanSolicitationStatus extends BaseEnum {
	static NONE = new WorkforceplanSolicitationStatus(0, 'NONE', '', "secondary");
	static PENDING = new WorkforceplanSolicitationStatus(1, 'PENDING', 'Pendente', "secondary");
	static APPROVED = new WorkforceplanSolicitationStatus(2, 'APPROVED', 'Aprovado', "success");
	static REJECTED = new WorkforceplanSolicitationStatus(3, 'REJECTED', 'Rejeitado', "danger");

	constructor(public id: number, public value: string, public label: string, public className: string) {
		super(id, value, label, className);

		WorkforceplanSolicitationStatusEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return WorkforceplanSolicitationStatusEnum;
	}
}

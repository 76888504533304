import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { Model } from './model';

export class ErrorModel extends Model implements IModel<ErrorModel> {
  status: number = 0;
  message: string = '';

  static create(input: any): ErrorModel {
    return ObjectMapper.deserialize<ErrorModel>(ErrorModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
import { OptionModel } from "services/models/model";
import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class CostCenterModel extends Model implements IModel<CostCenterModel> {
  code: string = "";
  name: string = "";
  description: string = "";
  enable: boolean = true;
  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;

  static create(input: any): CostCenterModel {
    return ObjectMapper.deserialize<CostCenterModel>(CostCenterModel, input);
  }

  toOption(): OptionModel {
    return {
      label: `${this["code"]} - ${this["name"]}`,
      value: this.id,
    } as OptionModel;
  }
}

import { Response } from "./../models/response";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "services/http.service";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";
import { TimeOccurrenceModel } from "services/models/corporate/timeOccurrence.model";
import { map } from "rxjs/operators";

@Injectable()
export class TimeOccurrenceService extends ServiceBase<TimeOccurrenceModel> {
  constructor(http: HttpService) {
    super("TimeOccurrence", http, environment.apiUrl);
  }

  createEntity(input: any): TimeOccurrenceModel {
    return TimeOccurrenceModel.create(input);
  }

  listPendingOccurrencesByEmployee(
    employeeId: string
  ): Observable<Response<TimeOccurrenceModel[]>> {
    return this.http
      .get(
        `${this.endpoint}/list-pending-occurrences-by-employeeId/${employeeId}`
      )
      .pipe(
        map((res) => {
          const response = new Response<TimeOccurrenceModel[]>();
          response.data = res.body.map((pp) => TimeOccurrenceModel.create(pp));
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  listManagedOccurrencesByEmployee(
    employeeId: string
  ): Observable<Response<TimeOccurrenceModel[]>> {
    return this.http
      .get(
        `${this.endpoint}/list-managed-occurrences-by-employeeId/${employeeId}`
      )
      .pipe(
        map((res) => {
          const response = new Response<TimeOccurrenceModel[]>();
          response.data = res.body.map((pp) => TimeOccurrenceModel.create(pp));
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  syncOccurrencesByEmployee(employeeId: string) {
    return this.http.post(
      `${this.endpoint}/sync-employee-occurrences/${employeeId}`,
      ""
    );
  }

  accreditTimeOccurrence(absences: any[]): Observable<Response<any>> {
    return this.http
      .put(`${this.endpoint}/accredit-timeoccurrence`, absences)
      .pipe(
        map((res) => {
          const response = new Response<any>();
          response.data = res;
          return response;
        })
      );
  }

  authorizeTimeOccurrence(overtimes: any[]): Observable<Response<any>> {
    return this.http
      .put(`${this.endpoint}/authorize-timeoccurrence`, overtimes)
      .pipe(
        map((res) => {
          const response = new Response<any>();
          response.data = res;
          return response;
        })
      );
  }

  uploadTempFile(
    occurrence: TimeOccurrenceModel
  ): Observable<Response<TimeOccurrenceModel>> {
    return this.http
      .post(`${this.endpoint}/upload-temp-file`, occurrence, null, null, true)
      .pipe(
        map((res) => {
          const response = new Response<TimeOccurrenceModel>();
          response.data = res;
          return response;
        })
      );
  }

  public getFile(id: string, tempFile: boolean): Observable<Blob> {
    return this.http.get(`${this.endpoint}/${id}/file?tempFile=${tempFile}`);
  }
}

import { BaseEnum } from "./baseEnum"

const listEnum: Array<ReferencePeriodStatusType> = [];

export class ReferencePeriodStatusType extends BaseEnum {
  static AroundDue = new ReferencePeriodStatusType(0, 'AroundDue', 'A Vencer', 'light-secondary');
  static Overdue = new ReferencePeriodStatusType(1, 'Overdue', 'Vencido', 'light-warning');
  static PartiallyScheduled = new ReferencePeriodStatusType(2, 'PartiallyScheduled', 'Parcialmente Programado', 'info');
  static Scheduled = new ReferencePeriodStatusType(3, 'Scheduled', 'Programado', 'success');
  static CloseToLimit = new ReferencePeriodStatusType(4, 'CloseToLimit', 'Limite Próximo', 'danger');
  static Paid = new ReferencePeriodStatusType(5, 'Paid', 'Pago', 'primary');
  static Exceeded = new ReferencePeriodStatusType(6, 'Exceeded', 'Excedido', 'dark');
  static PendingSchedules = new ReferencePeriodStatusType(7, 'PendingSchedules', 'Pendente', 'warning');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}
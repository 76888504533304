import { Injectable } from "@angular/core";
import { DomainService } from "./domain.service";
import { HttpService } from "./http.service";
import { ResetPasswordModel } from "./models/reset-password.model";
import { ServiceBase } from "./servicebase";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordService extends ServiceBase<ResetPasswordModel> {
  constructor(http: HttpService, private domainService: DomainService) {
    super("resetpassword", http);
  }

  createEntity(input: any) {
    return ResetPasswordModel.create(input);
  }

  requestCode(registration: string) {
    const domainName = this.domainService.getDomain().domainName;
    const requestBody = { registration };

    return this.http.post(`${this.endpoint}/request.code`, requestBody, {
      headers: { DomainName: domainName },
    });
  }

  checkValidateCode(codigo: string, registration: string) {
    const domainName = this.domainService.getDomain().domainName;
    const requestBody = { registration: registration };

    return this.http.post(
      `${this.endpoint}/validate.code/${codigo}`,
      requestBody,
      { headers: { DomainName: domainName } }
    );
  }

  resetPassword(codigo: string, resetPassword: ResetPasswordModel) {
    const domainName = this.domainService.getDomain().domainName;
    const requestBody = {
      Password: resetPassword.password,
      Registration: resetPassword.registration,
    };

    return this.http.post(`${this.endpoint}/reset/${codigo}`, requestBody, {
      headers: { DomainName: domainName },
    });
  }
}

import {Component, Input, OnInit} from "@angular/core";
import {DrawerDataInput} from "../drawer-workforce-plan-solicitation/form-types";
import {WorkforcePlanAmountModel, WorkforcePlanWorkScaleGroupModel} from "services/models/workforceplan";

@Component({
	selector: "app-drawer-workforceplan-solicitation-planning",
	templateUrl: "./drawer-workforce-plan-solicitation-planning.component.html",
	styleUrls: ["./drawer-workforce-plan-solicitation-planning.component.scss"],
})
export class AppSharedDrawerWorkforceplanSolicitationPlanningComponent implements OnInit {
	@Input() data!: DrawerDataInput;

	@Input() source: "action-required" | null = null;

	public justification = "";
	public isSubmtitting = false;

	constructor() {
	}

	ngOnInit() {
		console.log(this.data);
	}

	ngOnDestroy() { }

	public getJobLabel(value: WorkforcePlanAmountModel) {
		if (value?.jobTitleName) {
			return `${value?.jobTitleGroupName} / ${value?.jobTitleName}`
		}

		return `${value?.jobTitleGroupName}`
	}

	public getTotalHadCount(workScale: WorkforcePlanWorkScaleGroupModel) {
		return workScale.amounts.reduce((acc, item) => acc + item?.newAmount, 0);
	}

	public getValueTag(item: WorkforcePlanAmountModel) {
		if (item.changeDirection === 'increase') {
			return `<span class="text-danger">${item.newAmount}</span> <i data-feather="arrow-up"></i>`
		}
		if (item.changeDirection === 'decrease') {
			return `<span class="text-danger">${item.newAmount}</span> <i data-feather="arrow-down"></i>`
		}

		return `<span>${item.newAmount}</span>`
	}

	public get workScaleGroups() {
		return this.data?.solicitationDetails?.approvalDetails?.workScaleGroups;
	}
}

<div class="solicitation-details-container">
	<div class="card-informations card card-fit shadow rounded-lg">
		<div class="card-infos-content">
			<strong>Tipo de solicitação</strong>
			<span>{{ data?.solicitation?.movementTypeData?.label }}</span>
		</div>
		<div class="sepator-vertical"></div>
		<div class="card-infos-content">
			<strong>Solicitante</strong>
			<div class="requester-infos">
				<img
					alt="avatar"
					class="avatar"
					[width]="32"
					[height]="32"
					[src]="data?.solicitation?.requesterImage || 'assets/images/logo/avatar-profile.png'"
				/>
				<span>{{ data?.solicitation?.requesterName }}</span>
			</div>
		</div>
	</div>

	<div class="note">
		<strong>Justificativa</strong>
		<p>{{ data?.solicitation?.note || 'Não informada' }}</p>
	</div>

	<hr>

	<h3 class="cards-title my-2">Projeção</h3>

	<div class="d-flex flex-row flex-wrap row mt-2 workscale-items">
		<ng-container *ngFor="let workScale of workScaleGroups; let i = index">
			<div class="flex flex-column col-md-6 workscale-item">
				<div class="flex flex-1 justify-space-between gap-2">
					<div class="icon-container">
						<i class="feather icon-clock"></i>
					</div>
					<span class="workscale-item-title">{{ workScale.workScaleGroup }}</span>
				</div>

				<div class="card card-fit workscale-item-cards">
					<div class="card-header">
						<div class="flex-1 text-uppercase">Cargo / Função</div>
						<div class="flex-1 text-uppercase">Quantidade</div>
					</div>
					<div class="card-body px-0 py-1">
						<ng-container *ngIf="workScale.amounts.length > 0">
							<ng-container *ngFor="let item of workScale.amounts;let isLast = last;let j = index">
								<div class="flex justify-content-between align-items-center workscale-item-infos">
									<div class="flex-1">
										<span class="text-sm">{{ getJobLabel(item) }}</span>
									</div>
									<div class="flex-1 value-container">
										<ng-container *ngIf="item.changeDirection == 'increase' || item.changeDirection == 'decrease'">
											<span class="text-through">{{ item.previousAmount }}</span>
										</ng-container>

										<span class="{{ item.changeDirection }}">{{ item.newAmount }}</span>

										<i *ngIf="item.changeDirection == 'added'" data-feather="plus" class="mb-icon text-primary" size="14"></i>
										<i *ngIf="item.changeDirection == 'increase'" data-feather="arrow-up" class="mb-icon text-success" size="14"></i>
										<i *ngIf="item.changeDirection == 'decrease'" data-feather="arrow-down" class="mb-icon text-danger" size="14"></i>
									</div>
								</div>

								<hr *ngIf="!isLast" />
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!workScale.amounts.length">
							<div class="card-body-empty flex justify-content-center align-items-center">
								<span> Nenhuma posição incluída.</span>
							</div>
						</ng-container>
					</div>
					<div class="card-footer flex align-items-center justify-content-between">
						<span>Total HC: {{ getTotalHadCount(workScale) }}</span>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
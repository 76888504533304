import { ObjectMapper } from "json-object-mapper";
import { DigitalSignatureStatus, DigitalSignatureStatusEnum } from "services/enums/digitalSignatureStatus.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import { DocumentMockupTypeEnum } from "./document-mockup-type.model";
import { DocumentSignatureGroupDocumentModel } from "./document-signature-group.model";
import { DocumentSignatureSignerModel } from "./document-signature-signer.model";

export class DocumentSignatureModel
	extends Model
	implements IModel<DocumentSignatureModel> {
	originalFileS3Path: string = "";
	originalFileExtension: string = "";
	signedFileS3Path: string = "";
	signedFileExtension: string = "";
	hashChecksumSHA256: string = "";
	title: string = "";
	status: DigitalSignatureStatus | string = "";
	limitDate: string = "";
	companyGroupId: string = "";
	creatorEmployeeId: string = "";
	creatorEmployeeName: string = "";
	signers?: DocumentSignatureSignerModel[] = [];

	type: string = DocumentMockupTypeEnum.General.value;
	countDocuments?: number = 0;
	countDocumentsSigned?: number = 0;
	documents?: DocumentSignatureGroupDocumentModel[] = [];

	static create(input): DocumentSignatureModel {
		return ObjectMapper.deserialize<DocumentSignatureModel>(
			DocumentSignatureModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}

	get isSigned() {
		return (this.countDocuments === this.countDocumentsSigned).toString()
	}

	get typeLabel(): string {
		return DocumentMockupTypeEnum.getBy(this.type).label;
	}

	get statusLabel(): string {
		return DigitalSignatureStatusEnum.getBy(this.status).label;
	}

	get progressPercentage() {
		return (this.countDocumentsSigned / this.countDocuments) * 100
	}

	get progressColor() {
		const value = (this.countDocumentsSigned / this.countDocuments)

		if (value < 0.5) {
			return 'danger'
		}

		if (value === 1) {
			return 'success';
		}

		return 'warning';
	}

	get progressIcon() {
		const value = (this.countDocumentsSigned / this.countDocuments)

		if (value < 0.5) {
			return ''
		}

		if (value === 1) {
			return 'check';
		}

		return 'clock';
	}

	get statusProps() {
		return {
			icon: this.progressIcon,
			color: this.progressColor,
			percentage: this.progressPercentage,
		}
	}
}

import { BaseEnum } from "./baseEnum"

const listEnum: Array<VactionScheduleStatusType> = [];

export class VactionScheduleStatusType extends BaseEnum {
  static Pending = new VactionScheduleStatusType(0, 'Pending', 'Pendente', 'warning');
  static Approved = new VactionScheduleStatusType(1, 'Approved', 'Aprovado', 'success');
  static Rejected = new VactionScheduleStatusType(2, 'Rejected', 'Rejeitado', 'danger');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}
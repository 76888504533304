import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import {
	WorkforcePlanSummaryModel,
	WorkforcePlanTeamModel,
} from "services/models/workforceplan";
import { WorkforcePlanSolicitationModel } from "services/models/workforceplan/workforceplan-solicitation.model";
import { ServiceBase } from "services/servicebase";
import _ from 'lodash'
import { WorkforcePlanSolicitationDetailsModel } from "services/models/workforceplan/workforceplan-solicitation-details.model";
import { WorkforcePlanReportModel } from "services/models/workforceplan/workforceplan-report";
import { WorkforceplanEmployeeStatus } from "services/enums/WorkforceplanEmployeeStatus.enum";
import { WorkforceplanMovementType } from "services/enums/WorkforceplanMovementType.enum";
import { MovementRequestPermissionModel } from "services/models/workforceplan/movement-request-permission.model";
import { WorkforcePlanModel } from "services/models/workforceplan/workforceplan-main.model";
import { Response } from "services/models/response";

type StateScreenActionsResponse = {
	shouldRefetchCountSolicition?: boolean;
};

type StateWorkforceplanDataResponse = {
	data?: WorkforcePlanModel;
	form?: any;
};

@Injectable()
export class WorkforceplanService extends ServiceBase<WorkforcePlanModel> {
	private stateActionsSubject = new Subject<StateScreenActionsResponse>();
	public stateScreenActions$ = this.stateActionsSubject.asObservable();

	private stateWorkforceplanSubject = new Subject<StateWorkforceplanDataResponse>();
	public stateWorkforceplanData$ = this.stateWorkforceplanSubject.asObservable();

	constructor(http: HttpService) {
		super("WorkForcePlan", http, environment.apiUrl);
	}

	createEntity(input: any): WorkforcePlanModel {
		return WorkforcePlanModel.create(input);
	}

	emitEventUpdateStateScreenActions(data: StateScreenActionsResponse) {
		this.stateActionsSubject.next(data);
	}

	emitEventUpdateStateWorkforceplanData(data: StateWorkforceplanDataResponse) {
		this.stateWorkforceplanSubject.next(data);
	}

	override get(id: string, disableLoading?: boolean): Observable<Response<WorkforcePlanModel>> {
		return this.http
			.get(`${this.endpoint}/${id}`, undefined, this.baseUrl, undefined, disableLoading)
			.pipe(
				map((res) => {
					const response = new Response<WorkforcePlanModel>();
					response.data = this.parseWorkforcePlanData(res.body || {});
					return response;
				})
			);
	}

	getWorkforcePlan(companyId: string, costCenterId: string, disableLoading = false): Observable<WorkforcePlanModel[]> {
		return this.http.get(`${this.endpoint}/get-by-company-and-costcenter?companyId=${companyId}&costCenterId=${costCenterId}`, null, null, null, disableLoading).pipe(
			map((res) => {
				return res.body.map(wfp => this.parseWorkforcePlanData(wfp));
			})
		);
	}

	getWorkforcePlanByVersionId(versionId: string, disableLoading = false): Observable<WorkforcePlanModel> {
		return this.http.get(`${this.endpoint}/version/${versionId}`, null, null, null, disableLoading).pipe(
			map((res) => this.parseWorkforcePlanData(res.body))
		);
	}

	getPermissionsByMovementType(movementType: WorkforceplanMovementType): Observable<MovementRequestPermissionModel> {
		return this.http.get(`${this.endpoint}/permission-by-movement-type/${movementType.id}`, null, null, null, true).pipe(
			map((res) => MovementRequestPermissionModel.create(res.body))
		);
	}

	loadWorkScales(companyId: string): Observable<any[]> {
		return this.http.get(`TimeShift/${companyId}/work-scale-groups-by-company`).pipe(
			map((res) => {
				return res.body.map((item) => ({ id: item, title: item }));
			})
		);
	}

	getWorkforcePlanSummary(id: string): Observable<WorkforcePlanSummaryModel> {
		return this.http.get(`${this.endpoint}/${id}/summary`, null, null, null, true).pipe(
			map((res) => {
				return WorkforcePlanSummaryModel.create(res.body);
			})
		);
	}

	getWorkforcePlanTeam(id: string, status: number = WorkforceplanEmployeeStatus.None.id): Observable<WorkforcePlanTeamModel[]> {
		return this.http.get(`${this.endpoint}/${id}/team?status=${status}`).pipe(
			map((res) => {
				const data = res.body.map((item) => WorkforcePlanTeamModel.create(item));
				return data
			})
		);
	}

	getCountWorkforcePlanPendingSolicitation(wfpId: string): Observable<any> {
		return this.http.get(`${this.endpoint}/${wfpId}/movement-request/pending`, null, null, null, true).pipe(
			map((res) => {
				return res.body
			})
		);
	}

	getWorkforcePlanSolicitation(id: string): Observable<WorkforcePlanSolicitationModel[]> {
		return this.http.get(`${this.endpoint}/${id}/movement-request/odata?$orderBy=CreationDate desc`, null, null, null, true).pipe(
			map((res) => {
				return res.body.map(item => {
					const created = WorkforcePlanSolicitationModel.create(item);

					const rejectedIndex = created.steps.findIndex(f => f.status === 'REJECTED');
					if (rejectedIndex >= 0)
						created.steps = created.steps.slice(0, rejectedIndex + 1)

					return created;
				})
			})
		);
	}

	getWorkforcePlanSolicitationPermission(approvalScopeItemId: string): Observable<{ canExecute: boolean; canUpdate: boolean; }> {
		return this.http.get(`${this.endpoint}/movement-request/${approvalScopeItemId}/permission`, null, null, null, true).pipe(
			map((res) => {
				return res.body
			})
		);
	}

	postWorkforcePlanSolicitationUpdateStatus(movementRequestId: string, data: any) {
		return this.http.post(`${this.endpoint}/movement-request/${movementRequestId}/status`, data, null, null, true)
	}

	getWorkforcePlanSolicitationDetails(movementRequestId: string, approvalScopeItemId: string): Observable<WorkforcePlanSolicitationDetailsModel> {
		return this.http.get(`${this.endpoint}/movement-request/${movementRequestId}/details/${approvalScopeItemId}`, null, null, null, true).pipe(
			map((res) => WorkforcePlanSolicitationDetailsModel.create(res.body))
		);
	}

	getWorkforcePlanReports(workforcePlanId: string, costCenterId: string, companyId: string): Observable<WorkforcePlanReportModel[]> {
		let query = '';

		if (workforcePlanId) {
			query += `?id=${workforcePlanId}`;
		}

		if (costCenterId) {
			query += query ? `&costCenterId=${costCenterId}` : `?costCenterId=${costCenterId}`;
		}

		if (companyId) {
			query += query ? `&companyId=${companyId}` : `?companyId=${companyId}`;
		}

		return this.http.get(`${this.endpoint}/report${query}`, null, null, null, true).pipe(
			map((res) => res.body.map(WorkforcePlanReportModel.create))
		);
	}

	getWorkforcePlanByResourceUrl(url: string): Observable<any> {
		return this.http.get(url, null, null, null, true).pipe(
			map((res) => {
				return res.body
			})
		);
	}

	removeDraft(id: string): Observable<Response<any>> {
		return this.http.remove(`${this.endpoint}/remove-draft/${id}`).pipe(
			map((res) => {
				const response = new Response<any>();
				response.data = res;
				return response;
			})
		);
	}

	private parseWorkforcePlanData(workfocePlan: WorkforcePlanModel) {
		if (!workfocePlan) return null;
		const workScaleGroups = workfocePlan.workScaleGroups.sort((a, b) => a.workScaleGroup.localeCompare(b.workScaleGroup));

		workScaleGroups.forEach(item => {
			item.amounts.forEach(ia => {
				if (!ia.jobTitleName) {
					ia.jobTitleId = undefined;
					ia.jobTitleName = undefined;
				}
			});

			item.amounts = item.amounts.sort((a, b) => a.jobTitleGroupName.localeCompare(b.jobTitleGroupName));
		});

		workfocePlan.workScaleGroups = workScaleGroups;

		return workfocePlan;
	}
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";

import { FileService } from "@core/services/file.service";

import { CheckRegistrationModel } from "./models/checkregistration.model";
import { ServiceBase } from "./servicebase";
import { HttpService } from "./http.service";

export const KeyQuestionType = {
  BirthdayDay: "Qual dia do seu nascimento?",
  AnniversaryMonth: "Qual mês do seu nascimento?",
  AnniversaryYear: "Qual ano do seu nascimento?",
  MothersName: "Qual o primeiro nome da sua mãe?",
  FourFirstRG: "Quais os 4 primeiros dígitos do seu RG?",
  HiringYear: "Qual o ano da sua admissão na empresa?",
  BirthCity: "Qual a cidade que você nasceu?",
  HiringMonth: "Qual o mês da sua admissão na empresa?",
};

@Injectable()
export class PontoService extends ServiceBase<CheckRegistrationModel>{
  constructor(http: HttpService, private file: FileService) {
    super('pointtime', http);
  }

  createEntity(input: any) {
    return CheckRegistrationModel.create(input);
  }

  employeeCheckRegistration(
    domainName: string,
    registrationData: string
  ): Observable<CheckRegistrationModel> {
    const requestBody = {
      registration: registrationData,
    };

    return this.http
      .post('employee/check.registration', requestBody, {
        headers: { DomainName: domainName },
      })
      .pipe(
        map((data: any) => this.createEntity(data)),
        timeout(60000)
      );
  }

  pointTimeRegister(
    domainName: string,
    registration: string,
    questionType: string,
    answer: string,
    password: string,
    latitude?: number,
    longitude?: number
  ): Observable<CheckRegistrationModel> {
    const requestBody = {
      registration,
      answer,
      questionType,
      password,
    };

    if (latitude && longitude) {
      requestBody["latitude"] = latitude;
      requestBody["longitude"] = longitude;
    }

    return this.http
      .post(`${this.endpoint}/register`, requestBody, {
        headers: { DomainName: domainName },
      })
      .pipe(
        map((data: any) => this.createEntity(data)),
        timeout(60000)
      );
  }

  pointTimeReceiptDownload(domainName: string, pointTimeId: string) {
    return this.http
      .get(`${this.endpoint}/receipt/${pointTimeId}`, {
        headers: { DomainName: domainName },
      })
      .pipe(
        map((res: any) => {
          const blobData = res.body.fileContents;
          const blob = this.file.base64ToBlob(blobData);
          const tipo = "pdf";
          this.file.saveFile(blob, `${res.body.fileDownloadName}.${tipo}`);
          return blob;
        }),
        timeout(60000)
      );
  }
}

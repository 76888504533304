import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class UserModel extends Model implements IModel<UserModel> {
  public email: string = "";
  public name: string = "";
  public password: string = "";
  public confirmPassword: string = "";

  static create(input: any): UserModel {
    return ObjectMapper.deserialize<UserModel>(UserModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

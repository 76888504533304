import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import buildQuery from 'odata-query';
import { environment } from 'environments/environment';
import { HttpService } from 'services/http.service';
import { Model } from 'services/models/model';
import { QueryParams } from 'services/models/odata/queryParams';
import { Response } from 'services/models/response';
import { Filter } from 'services/models/odata/filter';
import { DomainModel } from 'services/models/domain.model';

export abstract class ServiceBase<M extends Model> {
  BASE_COMPANIES_IMAGE_URL = environment.imagesApiUrl;
  domainObj: DomainModel = null;
  domainImageUrl = '';

  constructor(
    public endpoint: string,
    public http: HttpService,
    public baseUrl = ''
  ) { }

  get getHttp(): HttpService {
    return this.http;
  }

  get getEndPoint(): string {
    return this.endpoint;
  }

  get(id: string, disableLoading = false): Observable<Response<M>> {
    return this.http
      .get(`${this.endpoint}/${id}`, undefined, this.baseUrl, undefined, disableLoading)
      .pipe(
        map((res) => {
          const response = new Response<M>();
          response.data = this.createEntity(res.body || {}) as M;
          return response;
        })
      );
  }

  changeStatus(idObject: string): Observable<Response<any>> {
    return this.http.put(`${this.endpoint}/status/${idObject}`, {}).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }

  delete(id: string): Observable<Response<any>> {
    return this.http.remove(`${this.endpoint}/${id}`).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }

  disable(id: string): Observable<Response<any>> {
    return this.http.put(`${this.endpoint}/status/${id}`, null).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }

  save(id: number | string, model: M, url = undefined): Observable<Response<M>> {

    const object = model;
    this.http.convertTimes(object);
    let _object = ObjectMapper.serialize(object);
    _object = _object.length > 1 ? _object : object.toJSON();
    var newEndpoint = url === undefined ? this.endpoint : `${this.endpoint}/${url}`;
    const observer = (value: string | number) => {
      if (!value) {
        return this.http.post(
          `/${newEndpoint}`,
          _object,
          undefined,
          this.baseUrl
        );
      } else {
        return this.http.put(newEndpoint, JSON.parse(_object.toString()));
      }
    };

    return observer(id).pipe(
      map((res) => {
        const response = new Response<M>();
        // response.Data = this.createEntity(res.Data) as M;
        return response;
      })
    );
  }

  createBuildQuery(params: QueryParams) {
    const select = buildQuery({ select: params.select });
    const top = params.top
      ? buildQuery({ top: params.top }).replace('?', '&')
      : buildQuery({ top: 999 }).replace('?', '&');
    const skip = params.skip
      ? buildQuery({ skip: params.skip }).replace('?', '&')
      : '';
    const filter = params.filter ? params.filter.replace('?', '&') : '';
    const orderBy = params.orderBy
      ? buildQuery({
        orderBy: `${params.orderBy} ${params.orderByOrder}`,
      }).replace('?', '&')
      : '';
    const expand = params.expand
      ? buildQuery({ expand: params.expand }).replace('?', '&')
      : '';

    const url = `${select}${expand}${top}${skip}${filter}${orderBy}`;
    return url;
  }

  customList<Type>(
    params: QueryParams,
    action: string,
    modelEntity: any,
    disableLoading: boolean = false
  ): Observable<Response<Type[]>> {
    const defaultSelect = ['id', 'enable', 'creationDate'];
    if (params && params.select)
      params.select = [
        ...params.select.filter((field) => !defaultSelect.includes(field)),
        ...defaultSelect,
      ];

    const query = params ? this.createBuildQuery(params) : '';
    params.filter = '';
    return this.http
      .get(`${this.endpoint}/${action}${query}`, undefined, this.baseUrl, null, disableLoading)
      .pipe(
        map((res) => {
          const response = new Response<Type[]>();
          response.data = res.body.map((pp: any) => {
            return modelEntity.create(pp) as Type;
          });
          response.count = res.headers.get('x-count');
          return response;
        })
      );
  }

  list(params: QueryParams, disableLoading: boolean = false): Observable<Response<M[]>> {
    const defaultSelect = ['id', 'enable', 'creationDate'];
    params.select = [
      ...params.select.filter((field) => !defaultSelect.includes(field)),
      ...defaultSelect,
    ];

    const query = this.createBuildQuery(params);
    return this.http
      .get(`${this.endpoint}/odata${query}`, undefined, this.baseUrl, null, disableLoading)
      .pipe(
        map((res) => {
          const response = new Response<M[]>();
          response.data = res.body.map((pp) => this.createEntity(pp) as M);
          response.count = res.headers.get('x-count');
          return response;
        })
      );
  }

  getAll(): Observable<Response<M[]>> {
    return this.http.get(`${this.endpoint}/odata`).pipe(
      map((res) => {
        const response = new Response<M[]>();
        response.data = res.body.map((pp) => this.createEntity(pp) as M);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getByIdGuidProperty(
    property: string,
    id: string,
    select: string[]
  ): Observable<Response<M[]>> {
    const queryParams = new QueryParams();
    queryParams.select = select;
    queryParams.filter = Filter.equalToGuid(property, id);

    return this.list(queryParams).pipe(
      map((res) => {
        const response = new Response<M[]>();
        response.data = res.data.map((e) => this.createEntity(e));
        return response;
      })
    );
  }

  getByProperty(
    property: string,
    value: any,
    select: string[]
  ): Observable<Response<M[]>> {
    const queryParams = new QueryParams();
    queryParams.select = select;
    queryParams.filter = Filter.equal(property, value);

    return this.list(queryParams).pipe(
      map((res) => {
        const response = new Response<M[]>();
        response.data = res.data.map((e) => this.createEntity(e));
        return response;
      })
    );
  }

  abstract createEntity(input: any): M;
}

<div *ngIf="locationGranted">
  <div class="weather" *ngIf="!isLoading">
    <div class="icon-temperature">
      <img [src]="getIcon()" width="100" height="100" alt="Icone Clima" />
      <div class="temperature-content">
        <div class="temperature">{{weather.temperature}}</div>
        <div class="grau-content">
          <div class="grau-c">°</div>
          <div class="grau-c">C</div>
        </div>
      </div>
    </div>
    <div>
      <p>{{ weather.climaticCondition }}</p>
      <h5>{{ weather.city }}, {{ weather.state }}</h5>
    </div>
  </div>
  <div *ngIf="isLoading" class="weather">
    <div class="col-12 void text-center custom-load">
      <h4>Carregando dados do clima...</h4>
      <div class="spinner-grow text-primary mr-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
<div class="weather" *ngIf="!locationGranted">
  Permissão de localização não concedida. 🚫
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CoreCommonModule } from "@core/common.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { ToastContainerComponent } from "./toast-container/toast-container.component";

@NgModule({
  declarations: [ToastContainerComponent],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    ContentHeaderModule,
  ],
})
export class CoreToastsModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./auth.service";
import { BenefitService } from "./corporate/benefit.service";
import { BenefitsEmployeeService } from "./corporate/benefitsEmployee.service";
import { CompanyService } from "./corporate/company.service";
import { CostCenterService } from "./corporate/costcenter.service";
import { EmployeeService } from "./corporate/employee.service";
import { EmployeeManagementService } from "./corporate/employeeManagement.service";
import { HierarchyLeaderService } from "./corporate/hierarchyLeader.service";
import { HourlyBasisService } from "./corporate/hourlyBasis.service";
import { InfringementService } from "./corporate/infringement.service";
import { PointTimeExceptionService } from "./corporate/pointTimeException.service";
import { PointTimePeriodService } from "./corporate/pointTimePeriod.service";
import { TimeOccurrenceService } from "./corporate/timeOccurrence.service";
import { TimeShiftHistoryService } from "./corporate/timeShiftHistory.service";
import { VacationScheduleService } from "./corporate/vacationSchedule.service";
import { DocumentSignatureService } from "./documents/document-signature.service";
import { DocumentMockupService } from "./documents/documentmockup.service";
import { DomainService } from "./domain.service";
import { GeographyService } from "./geography.service";
import { HandleErrorService } from "./handleError.service";
import { ApplicantDocumentService } from "./hiring/applicant-document.service";
import { ApplicantService } from "./hiring/applicant.service";
import { DocumentTypeService } from "./hiring/document-type.service";
import { ExitInterviewService } from "./hiring/exitInterview.service";
import { HiringConfigService } from "./hiring/hiring-config.service";
import { JobTitleService } from "./hiring/jobTitle.service";
import { TimeShiftService } from "./hiring/timeShift.service";
import { HttpService } from "./http.service";
import { PontoService } from "./ponto.service";
import { BatimentoService } from "./reports/batimento.service";
import { LaborReportService } from "./reports/labor-report.service";
import { ResetPasswordService } from "./reset-password.service";
import { BenefitProviderService } from "./corporate/benefitProvider.service";
import { UserService } from "./corporate/user.service";
import { CompanyRootService } from "./corporate/companyRoot.service";
import { OrganizationService } from "./corporate/organization.service";

@NgModule({
	declarations: [],
	imports: [CommonModule, BrowserAnimationsModule],
	providers: [
		AuthService,
		CompanyService,
		OrganizationService,
		CompanyRootService,
		CostCenterService,
		DomainService,
		HiringConfigService,
		HttpService,
		GeographyService,
		PontoService,
		LaborReportService,
		ResetPasswordService,
		ApplicantService,
		ApplicantDocumentService,
		JobTitleService,
		BenefitService,
		UserService,
		BenefitProviderService,
		BenefitsEmployeeService,
		DocumentMockupService,
		TimeShiftService,
		DocumentTypeService,
		BatimentoService,
		EmployeeService,
		TimeOccurrenceService,
		PointTimePeriodService,
		HourlyBasisService,
		TimeShiftHistoryService,
		InfringementService,
		EmployeeManagementService,
		VacationScheduleService,
		PointTimeExceptionService,
		HandleErrorService,
		HierarchyLeaderService,
		ExitInterviewService,
		DocumentSignatureService
	],
})
export class ServicesModule { }

import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastService } from "@core/components/toasts/toasts.service";
import { Observable } from "rxjs";
import { Model } from "services/models/model";
import { QueryParams } from "services/models/odata/queryParams";
import { ServiceBase } from "services/servicebase";

export abstract class FormAbstact<M extends Model, S extends ServiceBase<M>> {
  isLoading: boolean = false;
  form: UntypedFormGroup = new UntypedFormGroup({});
  queryParams: QueryParams = new QueryParams();
  idGuid = "00000000-0000-0000-0000-000000000000";

  protected _service: S;
  private _totalItens: number = 0;
  private _router: Router;
  private _activeRoute: ActivatedRoute;
  private _toaster: ToastService;

  private _object: M;
  private _id: any = "";

  set object(object: M) {
    this._object = object;
  }

  get object(): M {
    return this._object;
  }

  get id(): string {
    return this._id;
  }

  get serviceAbstract(): S {
    return this._service;
  }

  get routerAbstract(): Router {
    return this._router;
  }

  get toasterAbstract(): ToastService {
    return this._toaster;
  }

  get isNew(): boolean {
    return !this.object?.id;
  }

  get activeRouteAbstract(): ActivatedRoute {
    return this._activeRoute;
  }

  constructor(
    service: S,
    activeRoute: ActivatedRoute,
    routerAbstract: Router,
    toasterAbstract: ToastService
  ) {
    this._service = service;
    this._activeRoute = activeRoute;
    this._router = routerAbstract;
    this._toaster = toasterAbstract;
    this._object = service.createEntity("");
  }

  verifyFormValidationRecursive(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      controle?.markAsDirty();
      controle?.markAsTouched();
      if (controle instanceof UntypedFormGroup || controle instanceof UntypedFormArray) {
        this.verifyFormValidationRecursive(controle);
      }
    });
  }

  verifyFormValidation(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
    });
  }

  verificaValidTouched(campo: UntypedFormControl) {
    if (campo == null) {
      throw new Error("Campo nulo sendo passado");
    }

    return !campo.valid && (campo.touched || campo.dirty);
  }

  query(): Observable<M> {
    return new Observable<M>((observer) => {
      try {
        this._activeRoute.params.subscribe((params: Params) => {
          const id = params.id;
          if (id === undefined || id == "new") {
            this._id = null;
            this.object = this._service.createEntity("");
            observer.next(this.object);
          } else {
            this._id = id;
            this._service.get(this._id).subscribe(({ data }) => {
              this.object = data;
              observer.next(this.object);
            });
          }
        });
      } catch (e) {}
    });
  }

  save(uid = undefined, url = undefined, callback = undefined) {
    this._service.save(this.id || uid, this.object, url).subscribe((res) => {
      this._toaster.showSuccess("Operação concluída...");
      this._router.navigate(["../"], { relativeTo: this._activeRoute });
      if (callback) { callback(); }
    }, () => this.isLoading = false);
  }

  handleSubmit() {
    if (this.validaForm()) {
      this.object = this.object.updateModel(this.form?.value);
      this.save();
    }
  }

  validaForm() {
    if (this.form?.valid) {
      return true;
    } else {
      this.verifyFormValidationRecursive(this.form as UntypedFormGroup);
      return false;
    }
  }

  get formControls() {
    return this.form.controls;
  }

  fieldError(controlName: string) {
    return Object.keys(this.formControls).map((x) => {
      if (x == controlName && !this.formControls[x].valid)
        return this.formControls[x];
    })[0];
  }

  abstract initializeForm();

  comparer(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o2 === o2;
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'services/auth.service';
import { DomainService } from 'services/domain.service';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
  ignoreAutherror: string[] = ['applicant/odata'];

  constructor(
    private router: Router,
    private authService: AuthService,
    private domainService: DomainService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const domain = this.domainService.getDomain();
          this.authService.logout();
          if (domain?.domainName) {
            this.router.navigate([domain.domainName, 'auth', 'login'], {
              state: { errorMessage: 'Token Inválido' },
            });
          } else if (!this.isIgnoredAuthErr(error.url)) {
            this.router.navigate(['/error']);
          }
        }
        return throwError(error);
      })
    );
  }

  isIgnoredAuthErr(url: string): boolean {
    var filtred = this.ignoreAutherror.filter((x) => url.indexOf(x) > -1);
    return filtred.length > 0;
  }
}

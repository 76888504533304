import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";

export class BenefitPetitionDependent extends Model implements IModel<BenefitPetitionDependent> {
	id: string = "";
	employeeId: string = "";
	petitionId: string = "";
	dependentId: string = "";
	benefitPlanId: string = "";
	dependentName: string = "";
	dependentCpf: string = "";
	benefitDescription: string = "";

	static create(input: any): BenefitPetitionDependent {
		return ObjectMapper.deserialize<BenefitPetitionDependent>(
			BenefitPetitionDependent,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class BenefitPetitionDetailsModel extends Model implements IModel<BenefitPetitionDetailsModel> {
	id: string = "";
	benefitPlanId: string = "";
	employeeId: string = "";
	employeeJobTitle: string = "";
	employeeName: string = "";
	requestType: string = "";
	status: string = "";
	providerId: string = "";
	benefitType: string = "";
	benefitDescription: string = "";
	providerName: string = "";
	providerLogo: string = "";
	requestInProgress: boolean = false;
	benefitEmployeeId?: string = "";
	benefitPetitionDependent?: BenefitPetitionDependent[] = []
	benefitsEmployeeDependent?: BenefitPetitionDependent[] = []

	get benefitTypeBadge(): string {
		return `<span class="badge badge-light-primary text-wrap">${this.benefitDescription}</span>`;
	}

	static create(input: any): BenefitPetitionDetailsModel {
		if ('benefitPetitionDependent' in input) {
			input.benefitPetitionDependent = input.benefitPetitionDependent.map(BenefitPetitionDependent.create)
			input.benefitsEmployeeDependent = input.benefitPetitionDependent.map(BenefitPetitionDependent.create)
		}
		if ('benefitsEmployeeDependent' in input) {
			input.benefitPetitionDependent = input.benefitsEmployeeDependent.map(BenefitPetitionDependent.create)
			input.benefitsEmployeeDependent = input.benefitsEmployeeDependent.map(BenefitPetitionDependent.create)
		}

		return ObjectMapper.deserialize<BenefitPetitionDetailsModel>(
			BenefitPetitionDetailsModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { ObjectMapper } from 'json-object-mapper';
import { IModel } from '../IModel';
import { Model } from '../model';
import { FileModel } from '../file.model';

export class CommunicationModel extends Model implements IModel<CommunicationModel> {
  title: string = '';
  message: string = '';
  image: FileModel = new FileModel();
  imageURL: string = '';
  type: string = '';
  expirationDate: string = '';
  companyId: string | undefined = undefined;
  costCenterId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;

  static create(input: any): CommunicationModel {
    return ObjectMapper.deserialize<CommunicationModel>(CommunicationModel, input);
  }

  get typeLabel(): string {
    return this.type == '1' ? 'BANNER' : 'AVISO';
  }

}

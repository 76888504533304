import { Model } from 'services/models/model';
import { IModel } from '../IModel';
import { ObjectMapper } from 'json-object-mapper';

export class PointTimeExceptionModel extends Model implements IModel<PointTimeExceptionModel> {
  companyGroupId: string | undefined = undefined;
  employeeId: string | undefined = undefined;
  timeShiftId: string | undefined = undefined;
  date: string = '';
  type: string = '';
  entry1: string = '';
  departure1: string = '';
  entry2: string = '';
  departure2: string = '';

  static create(input: any): PointTimeExceptionModel {
    return ObjectMapper.deserialize<PointTimeExceptionModel>(PointTimeExceptionModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from 'json-object-mapper';
import { CompanyModel } from './company.model';
import { IModel } from './IModel';
import { Model, OptionModel } from './model';
import { WorkRegimeType } from 'services/enums/WorkRegimeType.enum';
import { EmployeeType } from 'services/enums/EmployeeType.enum';

export class EmployeeModel extends Model implements IModel<EmployeeModel> {
  name: string = '';
  cpf: string = '';
  cnpj: string = '';
  registration: string = '';
  pis: string = '';
  rg: string = '';
  password: string = '';
  admissionDate: string = '';
  birthday: string = '';
  email: string = '';
  fathersName: string = '';
  mothersName: string = '';
  birthCity: string = '';
  companyId: string | undefined = undefined;
  company: CompanyModel = new CompanyModel();
  costCenterId: string | undefined = undefined;
  costCenterCode: string = '';
  costCenterName: string = '';
  timeShiftId: string | undefined = undefined;
  timeShiftCode: string = '';
  timeShiftTitle: string = '';
  jobTitleId: string | undefined = undefined;
  jobTitleCode: string = '';
  jobTitleName: string = '';
  companyCNPJ: string = '';
  companyName: string = '';
  workRegime: number = WorkRegimeType.CLT.id;
  type: any = EmployeeType.NORMAL.id;
  foreignId: number = 0;
  companyGroupId: string | undefined = undefined;
  cellPhoneNumber: string = '';
  profileImagePath: string = '';
  shortProfileImagePath: string = '';

  static create(input: any): EmployeeModel {
    return ObjectMapper.deserialize<EmployeeModel>(EmployeeModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  public toOption(): OptionModel {
    return {
      label: `${this.name} - ${this.registration} `,
      value: this.id,
    } as OptionModel;
  }
}

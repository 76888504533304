import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { HandleErrorService } from 'services/handleError.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  ignoreAutherror: string[] = ['applicant/odata'];

  constructor(
    private handleErrorService: HandleErrorService,
    private modal: NgbModal,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        const notification = event.headers.get('notification');
        if (notification !== null)
          this.handleErrorService.handleNotification(notification);

        return event;
      }),
      tap(
        (_) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if(!this.isIgnoredAuthErr(error.url))
            this.handleErrorService.handleError(error);
            if (error.status === 401 && !this.isIgnoredAuthErr(error.url)) {
              this.authService.logout();
              this.modal.dismissAll();
            }
            throw error;
          }
        }
      )
    );
  }

  isIgnoredAuthErr(url: string): boolean {
    var filtred = this.ignoreAutherror.filter((x) => url.indexOf(x) > -1);
    return filtred.length > 0;
  }
}

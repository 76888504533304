import { BaseEnum } from "./baseEnum"

const listEnum: Array<VacationLimitType> = [];

export class VacationLimitType extends BaseEnum {
  static Custom = new VacationLimitType(1, 'Custom', 'Dia de Corte', 'danger');
  static CLT = new VacationLimitType(2, 'CLT', '30 dias - CLT', 'primary');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}
import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";

export class HierarchyLevelModel
  extends Model
  implements IModel<HierarchyLevelModel>
{
  title: string = "";
  description: string = "";
  level: number = 0;
  companyGroupId: string | undefined = undefined;

  static create(input: any): HierarchyLevelModel {
    return ObjectMapper.deserialize<HierarchyLevelModel>(
      HierarchyLevelModel,
      input
    );
  }

  toOption(): OptionModel {
    return { label: `${this.level} - ${this.title}`, value: this.id } as OptionModel;
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
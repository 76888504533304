import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@core/components/toasts/toasts.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "app/layout/components/confirm-dialog/confirm-dialog.component";
import { FormAbstact } from "app/views/pages/abstract/form-abstract";
import moment from "moment-business-days";
import { CustomValidators } from "ng2-validation";
import { AuthService } from "services/auth.service";
import { VacationScheduleService } from "services/corporate/vacationSchedule.service";
import { DomainService } from "services/domain.service";
import { VacationLimitType } from "services/enums/VacationLimitType.enum";
import { VactionScheduleStatusType } from "services/enums/VactionScheduleStatusType.enum";
import { ReferencePeriodVacationModel } from "services/models/corporate/referencePeriodVacation.model";
import { ReferencePeriodVacationScheduleModel } from "services/models/corporate/referencePeriodVacationSchedule.model";
import { VacationManagementParametersModel } from "services/models/vacationManagementParameters.model";
import { FormValidations } from "utils/form-validation";
import { comparer, convertToUpperCase, omit_special_char } from "utils/helps";

@Component({
  selector: "app-create-vacation-schedule-modal",
  templateUrl: "./create-vacation-schedule-modal.component.html",
  styleUrls: ["./create-vacation-schedule-modal.component.scss"],
})
export class CreateVacationScheduleModalComponent
  extends FormAbstact<
    ReferencePeriodVacationScheduleModel,
    VacationScheduleService
  >
  implements OnInit {
  @Input() isSameUser: boolean = false;
  @Input() referencePeriod: ReferencePeriodVacationModel;
  @Input() vacationSchedule: ReferencePeriodVacationScheduleModel = null;
  @Input() onlyView: boolean = false;
  actionText: "Salvar" | "Programar" | "Solicitar Programação" =
    "Solicitar Programação";
  compareValues = comparer;
  convertToUpperCase = convertToUpperCase;
  omitChar = omit_special_char;
  minDate = moment().add(5, "day");
  vacationManagementParameters: VacationManagementParametersModel;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private _modalService: NgbModal,
    private toasterService: ToastService,
    private service: VacationScheduleService,
    private _domain: DomainService
  ) {
    super(service, activeRoute, router, toasterService);
  }

  ngOnInit(): void {
    this.vacationManagementParameters = this._domain.getDomain().vacationManagementParameters;

    this.initializeForm();
    this.setActionText();
    if (this.vacationSchedule) {
      this.vacationSchedule.date = moment(this.vacationSchedule.date).format(
        "yyyy-MM-DD"
      );
      this.form.patchValue(this.vacationSchedule);
    }
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      referencePeriodId: [
        this.referencePeriod.id,
        [Validators.required, FormValidations.guidRequired],
      ],
      date: ["", [Validators.required]],
      amountDaysVacation: [
        5,
        [Validators.required, Validators.min(5), Validators.max(30)],
      ],
      amountDaysAllowance: [
        0,
        [Validators.required, Validators.min(0), Validators.max(30)],
      ],
    });

    if (this.onlyView) {
      this.form.disable();
    }

    if (this.vacationManagementParameters) {
      if (this.vacationManagementParameters.vacationLimitDateType === VacationLimitType.Custom.value && moment().date() > this.vacationManagementParameters.vacationCutDate) {
        this.minDate = moment().add(1, "month").endOf("month");
      }

      if (this.vacationManagementParameters.vacationLimitDateType === VacationLimitType.CLT.value) {
        this.minDate = moment().add(30, "day");
      }
    }

    if (!this.service.hasWithoutAllowancePermission) {
      if (this.vacationManagementParameters.vacationLimitDateType === VacationLimitType.Custom.value && this.service.isOnlyManager)
        this.minDate = moment().add(5, "day");

      this.dateField.setValidators([Validators.required, CustomValidators.minDate(this.minDate)]);
      this.form.updateValueAndValidity();
    }
  }

  setActionText() {
    this.actionText = this.vacationSchedule
      ? "Salvar"
      : (this.service.hasAllowancePermission || this.service.isVacationManager) ||
        (this.service.hasWithoutAllowancePermission && !this.amountDaysAllowanceField.value)
        ? "Programar"
        : "Solicitar Programação";
  }

  onDismiss(): void {
    this.activeModal.close(false);
  }

  handleSubmit() {
    if (this.validaForm()) {
      this.updateObject();
      this.saveObject();
    }
  }

  updateObject() {
    this.object = this.object.updateModel(this.form?.value);
    this.object.id = this.vacationSchedule
      ? this.vacationSchedule.id
      : this.object.id;

    if (this.canChange()) {
      this.object.status = this.vacationSchedule
        ? this.vacationSchedule.status
        : VactionScheduleStatusType.Approved.value;
    }

    if (this.isSameUser && (!this.service.hasWithoutAllowancePermission && !this.service.isVacationManager)) {
      this.object.status = VactionScheduleStatusType.Pending.value;
    }

    // Verifica se não é administrador e é apenas gestor
    const isOnlyManagerAndCustomLimit =
      (!this.service.hasWithoutAllowancePermission && !this.service.isVacationManager) &&
      this.service.isOnlyManager && this.vacationManagementParameters.vacationLimitDateType === VacationLimitType.Custom.value;

    // Verifica se a solicitação é para o mês atual OU se é após exceder a data de corte para o mês atual OU próximo mês
    const creationDate = moment();
    const scheduleDate = moment(this.object.date);
    const isSameMonth = scheduleDate.month() === creationDate.month();
    const isNextMonth =
      scheduleDate.month() === creationDate.add(1, "month").month();
    const hasExceededCutDate =
      creationDate.date() > this.vacationManagementParameters.vacationCutDate;

    if (
      isOnlyManagerAndCustomLimit &&
      (isSameMonth || (hasExceededCutDate && (isSameMonth || isNextMonth)))
    ) {
      this.object.status = VactionScheduleStatusType.Pending.value;
    }

    this.convertToUpperCase(this.object);
  }

  saveObject() {
    this.service.save(this.object.id, this.object).subscribe(() => {
      this.toasterService.showSuccess(`Operação realizada com sucesso!`);
      this.activeModal.close(true);
    });
  }

  openApproveVacationSchedule(status: number) {
    const modalRef = this._modalService.open(ConfirmDialogComponent);
    const action = status == 2 ? "rejeitar" : "aprovar";
    modalRef.componentInstance.message = `Deseja realmente ${action} a programação de férias?`;
    modalRef.componentInstance.focusConfirm = true;
    modalRef.closed.subscribe(() => {
      this.saveApprove(status);
    });
  }

  saveApprove(status: number) {
    this.object = this.object.updateModel(this.vacationSchedule);
    this.object.status = status;
    this.convertToUpperCase(this.object);
    this._service.changeVacationScheduleStatus(this.object).subscribe(
      () => {
        const action = status == 2 ? "rejeitada" : "aprovada";
        this.toasterService.showSuccess(`Programação ${action} com sucesso.`);
        this.activeModal.close(true);
      },
      (error) => {
        this.toasterService.showError(error.message);
      }
    );
  }

  canChange() {
    return this.service.hasAllowancePermission || this.service.hasWithoutAllowancePermission || this.service.isVacationManager;
  }

  get dateField() {
    return this.form.get("date") as UntypedFormControl;
  }
  get amountDaysVacationField() {
    return this.form.get("amountDaysVacation") as UntypedFormControl;
  }
  get amountDaysAllowanceField() {
    return this.form.get("amountDaysAllowance") as UntypedFormControl;
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpService } from "../http.service";
import { CostCenterModel } from "../models/corporate/costcenter.model";
import { ServiceBase } from "../servicebase";
import { Observable } from "rxjs";
import { Response } from "../models/response";
import { map } from "rxjs/operators";

@Injectable()
export class CostCenterService extends ServiceBase<CostCenterModel> {
  constructor(http: HttpService) {
    super("costcenter", http, environment.apiUrl);
  }

  createEntity(input: any): CostCenterModel {
    return CostCenterModel.create(input);
  }

  listByHierarchyAndCompany(
    companyId: string
  ): Observable<Response<CostCenterModel[]>> {
    if (!companyId) companyId = "00000000-0000-0000-0000-000000000000";
    return this.http
      .get(
        `${this.endpoint}/list-by-hierarchy-and-company/${companyId}`,
        undefined,
        undefined,
        undefined,
        true
      )
      .pipe(
        map((res) => {
          const response = new Response<CostCenterModel[]>();
          response.data = res.body
            .filter((c) => c.enable)
            .map((pp) => CostCenterModel.create(pp));
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }
}

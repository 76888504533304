import { ObjectMapper } from "json-object-mapper";
import { IModel } from "./IModel";
import { Model, OptionModel } from "./model";

export class CompanyRootModel
  extends Model
  implements IModel<CompanyRootModel>
{
  name: string = "";
  rootCNPJ: string = "";
  companyGroupId: string | undefined = undefined;
  organizationId: string | undefined = undefined;

  toOption(): OptionModel {
    return {
      label: `${this.name} - ${this.rootCNPJ}`,
      value: this.id,
    } as OptionModel;
  }

  static create(input: any): CompanyRootModel {
    return ObjectMapper.deserialize<CompanyRootModel>(CompanyRootModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from 'json-object-mapper';
import { Model, OptionModel } from '../model';
import { IModel } from '../IModel';
import { JobTitleDocumentModel } from './jobTitleDocument.model';

export class JobTitleModel extends Model implements IModel<JobTitleModel> {
  title: string = '';
  description: string = '';
  code: string = '';
  salary: number = 0;
  companyId: string | undefined = undefined;
  companyName: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  jobTitleDocuments: JobTitleDocumentModel[] = [];
  required:boolean = false;
  
  static create(input: any): JobTitleModel {
    return ObjectMapper.deserialize<JobTitleModel>(JobTitleModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  toOption(): OptionModel {
    return { label: `${this['code']} - ${this['title']} - R$ ${this['salary']}`, value: this.id } as OptionModel;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";

export class BenefitOfferedModel extends Model implements IModel<BenefitOfferedModel> {
  providerId: string | undefined = undefined;
  benefitType: string = "";
  forDependents: boolean = false;
  forRelatives: boolean = false;

  static create(input: any): BenefitOfferedModel {
    return ObjectMapper.deserialize<BenefitOfferedModel>(BenefitOfferedModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

<div class="modal-header ">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" (click)="onDismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body" tabindex="0" ngbAutofocus>
      <iframe *ngIf="type == 1" class="frame-lgpd" id="modallgpdContent" src="RHGestao_Aviso_de_Privacidade.html"></iframe>
      <iframe *ngIf="type == 2" class="frame-lgpd" id="modallgpdContent" src="RHGestao_Termos_e_Condicoes.html"></iframe>
  </div>
import { ObjectMapper } from "json-object-mapper";
import { WorkforceplanEmployeeStatusType } from "services/enums/WorkforceplanEmployeeStatusType.enum";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkforcePlanTeamEmployeeModel extends Model implements IModel<WorkforcePlanTeamEmployeeModel> {
	id: string = "";
	name: string = "";
	jobTitleId: string = "";
	jobTitleName: string = "";
	jobTitleGroupId: string = "";
	jobTitleGroupName: string = "";
	status: string = WorkforceplanEmployeeStatusType.PENDING.label;
	shiftSchedule: string = "";
	registration: string = "";
	isPcd: boolean = false;
	cellPhoneNumber?: string = "";
	admissionDate?: string = "";
	profileImagePath?: string = "";
	companyId?: string = "";
	centerCostId?: string = "";
	timeShiftId?: string = "";
	costCenterName?: string = "";
	endFirstTrialPeriod?: string = "";
	endLastTrialPeriod?: string = "";
	workScaleGroup: string = "";

	get isPending(): boolean {
		return this.status === WorkforceplanEmployeeStatusType.PENDING.value;
	}

	get getStatusLabel(): string {
		return WorkforceplanEmployeeStatusType.getBy(this.status).label;
	}

	get statusBadge(): string {
		const status = WorkforceplanEmployeeStatusType.getBy(this.status);

		return `<span class="badge bg-${status?.className} text-wrap">${status?.label}</span>`;
	}

	static create(input: any): WorkforcePlanTeamEmployeeModel {
		return ObjectMapper.deserialize<WorkforcePlanTeamEmployeeModel>(
			WorkforcePlanTeamEmployeeModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class WorkforcePlanTeamModel extends Model implements IModel<WorkforcePlanTeamModel> {
	amountVariance: { name: string, count: number }[] = [];
	workScaleGroup: string = "";
	headCount: string = "";
	employees: WorkforcePlanTeamEmployeeModel[] = [];

	static create(input: any): WorkforcePlanTeamModel {
		input.employees = input.employees.map((item) => WorkforcePlanTeamEmployeeModel.create(item));

		return ObjectMapper.deserialize<WorkforcePlanTeamModel>(
			WorkforcePlanTeamModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { OptionModel } from "services/models/model";

export abstract class BaseEnum {
  constructor(
    public id: number,
    public value: string,
    public label: string,
    public className: string = ""
  ) { }

  public static values(): Array<BaseEnum> {
    return this.buildListEnum();
  }

  public static buildListEnum(): Array<BaseEnum> {
    return [];
  }

  public static getBy(value: string): BaseEnum {
    return this.buildListEnum().find((p: BaseEnum) => p.value === value);
  }

  public static getById(id: number): BaseEnum {
    return this.buildListEnum().find((p: BaseEnum) => p.id === id);
  }

  public static toOptions(label = "label", value = "id"): Array<OptionModel> {
    return this.buildListEnum().map((x) => {
      return { label: x[label], value: x[value] } as OptionModel;
    });
  }
}
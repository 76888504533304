import { BaseEnum } from "./baseEnum";

const listPetitionsRequestTypeEnum: Array<PetitionsRequestTypeEnum> = [];

export type PetitionsRequestType =
	| "ACCESSION"
	| "INCLUDE_DEPENDENTS"
	| "EXCLUDE_DEPENDENTS"
	| "CANCELLATION"
	| "CHANGE_PLAN";

export class PetitionsRequestTypeEnum extends BaseEnum {
	static ACCESSION = new PetitionsRequestTypeEnum(
		0,
		"ACCESSION",
		"Adesão",
		"success"
	);
	static INCLUDE_DEPENDENTS = new PetitionsRequestTypeEnum(
		1,
		"INCLUDE_DEPENDENTS",
		"Incl. Dependente",
		"success"
	);
	static EXCLUDE_DEPENDENTS = new PetitionsRequestTypeEnum(
		2,
		"EXCLUDE_DEPENDENTS",
		"Excl. Dependente",
		"danger"
	);
	static CANCELLATION = new PetitionsRequestTypeEnum(
		3,
		"CANCELLATION",
		"Cancelamento",
		"danger"
	);

	static CHANGE_PLAN = new PetitionsRequestTypeEnum(
		4,
		"CHANGE_PLAN",
		"Troca de plano",
		"info"
	);

	constructor(
		public id: number,
		public value: string,
		public label: string,
		public className: string
	) {
		super(id, value, label, className);
		listPetitionsRequestTypeEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listPetitionsRequestTypeEnum;
	}
}

import { BaseEnum } from "./baseEnum"

const listEmployeeEnum: Array<EmployeeType> = [];

export class EmployeeType extends BaseEnum {
  static NORMAL = new EmployeeType(0, 'NORMAL', 'NORMAL', 'success');
  static TRAINEE = new EmployeeType(1, 'TRAINEE', 'ESTAGIÁRIO', 'info');
  static LEARNER = new EmployeeType(2, 'LEARNER', 'APRENDIZ', 'warning');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEmployeeEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEmployeeEnum;
  }
}
import { ObjectMapper } from 'json-object-mapper';
import { IModel } from './IModel';
import { UserRoleType } from 'services/enums/userRoleType.enum';

export class ClaimModel implements IModel<ClaimModel> {
  public type = '';
  public value: any = '';

  static create(type, value): ClaimModel {
    if (type === 'userType') {
      value = value as UserRoleType;
    }
    const res = ObjectMapper.deserialize<ClaimModel>(ClaimModel, { type, value });
    return res;
  }

  public toJSON(): string {
    Object.keys(this).forEach((e) => {
      const isDate = this[e] instanceof Date;
      if (isDate) {
        this[e] = this[e].toISOString();
      }
    });
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkforcePlanReportItemModel extends Model {
	name: string = "";
	totalOnLeave: number = 0;
	totalInResignation: number = 0;
	totalFinishedTraining: number = 0;
	totalInTraining: number = 0;
	totalInPlanning: number = 0;
	totalAllocated: number = 0;
	balance: number = 0;
}

export class WorkforcePlanReportGroupModel extends WorkforcePlanReportItemModel {
	jobTitleGroups: WorkforcePlanReportItemModel[] = [];
}

export class WorkforcePlanReportCostCenterModel extends WorkforcePlanReportItemModel {
	workScaleGroups: WorkforcePlanReportGroupModel[] = [];
}

export class WorkforcePlanReportModel extends WorkforcePlanReportItemModel implements IModel<WorkforcePlanReportModel> {
	costCenters: WorkforcePlanReportCostCenterModel[] = [];

	static create(input: any): WorkforcePlanReportModel {
		return ObjectMapper.deserialize<WorkforcePlanReportModel>(WorkforcePlanReportModel, input);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

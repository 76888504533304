import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TableHeaderFilterComponentService {
  clearSubjects: { sub: Subject<any>; name: string }[] = [];

  constructor() {}

  addSubject(_name: string) {
    if (_name)
      this.clearSubjects.push({ sub: new Subject<any>(), name: _name });
  }

  emit(_name: string) {
    this.getSubClearByName(_name).next(undefined);
  }

  getSubClearByName(_name: string): Subject<any> {
    if (_name) return this.clearSubjects.find((x) => x.name == _name).sub;
    else return undefined;
  }
}

import { ObjectMapper } from "json-object-mapper";

export abstract class Model {
  public enable: boolean | undefined = undefined;
  public creationDate: string | undefined = undefined;
  public changeDate: string | undefined = undefined;
  public id: string | undefined = undefined;

  public toJSON(): string {
    Object.keys(this).forEach((e) => {
      const isDate = this[e] instanceof Date;
      if (isDate) {
        this[e] = this[e].toISOString();
      }
    });
    return ObjectMapper.serialize(this) as string;
  }

  public toOption(): OptionModel {
    return { label: this["name"], value: this.id } as OptionModel;
  }

  updateModel(fieldsToUpdate: Partial<Model>) {
    const keys = Object.keys(this);
    Object.entries(fieldsToUpdate).forEach(([key, value]) =>
      keys.includes(key) ? (this[key] = value) : ""
    );
    return this;
  }
}

export class OptionModel {
  label: string;
  value: string;
}

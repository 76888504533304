import { Injectable } from "@angular/core";

import { ServiceBase } from "../servicebase";
import { HttpService } from "../http.service";
import { DocumentTypeModel } from "../models/hiring/document-type.model";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { map } from "rxjs/operators";
import { QueryParams } from "services/models/odata/queryParams";
import { StandardDocumentModel } from "services/models/hiring/standardDocument.model";
import { ObjectMapper } from "json-object-mapper";
import { JobTitleDocumentModel } from "services/models/hiring/jobTitleDocument.model";

@Injectable()
export class DocumentTypeService extends ServiceBase<DocumentTypeModel> {
  constructor(http: HttpService) {
    super("documenttype", http);
  }

  createEntity(input: any): DocumentTypeModel {
    return DocumentTypeModel.create(input);
  }

  getAdditionalDocuments(): Observable<Response<DocumentTypeModel[]>> {
    return this.http
    .get(`${this.endpoint}/additional-documents`)
    .pipe(
      map((res) => {
        const response = new Response<DocumentTypeModel[]>();
        response.data = res.body.map((pp) => DocumentTypeModel.create(pp) as DocumentTypeModel);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getDefaultDocuments(): Observable<Response<StandardDocumentModel[]>> {
    return this.http
    .get(`${this.endpoint}/default-documents`)
    .pipe(
      map((res) => {
        const response = new Response<StandardDocumentModel[]>();
        response.data = res.body.map((pp) => StandardDocumentModel.create(pp) as StandardDocumentModel);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  saveStandardDocuments(documents: StandardDocumentModel[]): Observable<any> {
    let body = ObjectMapper.serialize(documents);
    body = body.length > 1 ? body : JSON.stringify(documents);
    return this.http.post(`${this.endpoint}/standard-documents`, body, null, null, true);
  }

  saveJobTitleDocuments(documents: JobTitleDocumentModel[]): Observable<any> {
    let body = ObjectMapper.serialize(documents);
    body = body.length > 1 ? body : JSON.stringify(documents);
    return this.http.post(`${this.endpoint}/jobtitle-documents`, body, null, null, true);
  }

  getAvailableStandardDocuments(): Observable<Response<DocumentTypeModel[]>> {
    return this.http.
    get(`${this.endpoint}/available-standard-documents`).
    pipe(
      map((res) => {
        const response = new Response<DocumentTypeModel[]>();
        response.data = res.body.map((doc) => DocumentTypeModel.create(doc)).sort((a, b) => a.title.localeCompare(b.title));
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getJobTitlesByDocument(documentTypeId: string): Observable<Response<JobTitleDocumentModel[]>> {
    return this.http.
    get(`${this.endpoint}/jobtitle-documents/list-by-documentType/${documentTypeId}`)
    .pipe(
      map((res) => {
        const response = new Response<JobTitleDocumentModel[]>();
        response.data = res.body.map((doc) => JobTitleDocumentModel.create(doc));
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  getRegisteredStandardDocuments(): Observable<Response<StandardDocumentModel[]>> {
    return this.http.
    get(`${this.endpoint}/standard-documents`)
    .pipe(
      map((res) => {
        const response = new Response<StandardDocumentModel[]>();
        response.data = res.body.map((doc) => StandardDocumentModel.create(doc)).sort((a, b) => a.order - b.order);
        response.count = res.headers.get('x-count');
        return response;
      })
    );
  }

  removeStandardDocument(id: string) {
    return this.http.remove(`${this.endpoint}/standard-document/${id}`);
  }

  removeJobTitleDocument(id: string) {
    return this.http.remove(`${this.endpoint}/jobtitle-document/${id}`);
  }

}

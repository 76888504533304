import { Injectable } from "@angular/core";
import { AuthService } from "services/auth.service";

import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreMenu } from "@core/types";

import { DomainService } from "services/domain.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public menu: CoreMenu[] = [];
	public domainName: string = "";

  constructor(
    private _coreMenuService: CoreMenuService,
    private _domainService: DomainService,
    private _authService: AuthService
  ) {
    this._domainService.domainSubject.subscribe((domain) => {
      if (domain) {
				this.domainName = domain.domainName;
        this.makeMenu();
        this.hideMenuIfChildrenAreHidden();
      }
      this.subscribeCurrentUserSubject();
    });
  }

  subscribeCurrentUserSubject() {
    this._authService.currentUserSubject.subscribe((user) => {
      if (user) this.makeMenu();
    });
  }

  hideMenuIfChildrenAreHidden() {
    this.menu
      .filter((m) => m.children?.every((c) => c.hidden))
      .map((r) => (r.hidden = true));
  }

  private makeMenu() {
    this.menu = this._domainService.getMenu();

		if (this._authService.isOutsourcedLiableUser && this.domainName) {
			const urlOutsourcedHome = `${this.domainName}/corporate/outsourced/home`;

			this.menu = [
				{
					id: "home",
					icon: "feather-home",
					type: "item",
					title: "Inicio",
					url: urlOutsourcedHome,
				}
			]
		}

    this._coreMenuService.unregister("main");

    // Register the menu to the menu service
    this._coreMenuService.register("main", this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu("main");
  }
}

import { ObjectMapper } from 'json-object-mapper';
import { IModel } from '../IModel';
import { Model, OptionModel } from '../model';

export class HourlyBasisTypeModel extends Model implements IModel<HourlyBasisTypeModel> {
  companyGroupId: string | undefined = undefined;
  title: string = '';
  description: string = '';
  action: HourlyBasisActionType = HourlyBasisActionType.CREDIT;

  static create(input: any): HourlyBasisTypeModel {
    return ObjectMapper.deserialize<HourlyBasisTypeModel>(HourlyBasisTypeModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  toOption(): OptionModel {
    return { label: `${this['title']}`, value: this.id } as OptionModel;
  }
}

export enum HourlyBasisActionType {
  CREDIT = 1,
  DEBIT = 2,
}

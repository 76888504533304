import { ObjectMapper } from "json-object-mapper";
import { EmployeeModel } from "../employee.model";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";
import { HierarchyLevelModel } from "./hierarchyLevel.model";
import { HierarchyLeaderOptionModel } from "./hierarchyLeaderOption.model";

export class HierarchyLeaderModel
  extends Model
  implements IModel<HierarchyLeaderModel>
{
  companyName: string = "";
  companyRootId: string = "";
  companyGroupId: string = "";
  leaderId: string = "";
  leaderName: string = "";
  leader: EmployeeModel = new EmployeeModel();
  description: string = "";
  name: string = "";
  levelId: string | undefined = undefined;
  level: HierarchyLevelModel = new HierarchyLevelModel();
  parentId: string | undefined = undefined;
  deactivationDate: string | undefined = undefined;
  options: HierarchyLeaderOptionModel[] = [];
  organogramView: boolean = true;

  static create(input: any): HierarchyLeaderModel {
    return ObjectMapper.deserialize<HierarchyLeaderModel>(
      HierarchyLeaderModel,
      input
    );
  }

  toOption(): OptionModel {
    return { label: `${this.leaderName} - ${this.name}`, value: this.id } as OptionModel;
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

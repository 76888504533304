import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	constructor() { }

	public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
		b64Data = b64Data.replace(/\s/g, '');
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	public saveFile(blob: Blob, fileName: string) {
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `RHGestao-${fileName}`;
			link.click();
		}
	}

	public openFile(res: Blob) {
		const downloadURL = window.URL.createObjectURL(res);
		window.open(downloadURL);
	}

	public async fileToBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}
}

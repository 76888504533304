import { Model, OptionModel } from 'services/models/model';
import { IModel } from './../IModel';
import { ObjectMapper } from 'json-object-mapper';
import { EmployeeModel } from '../employee.model';

export class TimeOccurrenceModel extends Model implements IModel<TimeOccurrenceModel> {
  companyGroupId: string | undefined = undefined;
  amountTime: string = '';
  day: string = '';
  week: string = '';
  treatmentDate: string = '';
  authorized: boolean | undefined = undefined;
  pendingApproval: boolean | undefined = undefined;
  currentTimeShift: string = '';
  currentCostCenter: string = '';
  defaultEntry1: string = '';
  defaultDeparture1: string = '';
  defaultEntry2: string = '';
  defaultDeparture2: string = '';
  realEntry1: string = '';
  realDeparture1: string = '';
  realEntry2: string = '';
  realDeparture2: string = '';
  realEntry3: string = '';
  realDeparture3: string = '';
  realEntry4: string = '';
  realDeparture4: string = '';
  timeOccurrenceTypeId: string | undefined = undefined;
  occurrenceTypeName: string = '';
  bonusReasonId: string | undefined = undefined;
  allowedBonusReasons: any[] = [];
  leaderId: string | undefined = undefined;
  employeeId: string | undefined = undefined;
  employee: EmployeeModel = new EmployeeModel();
  subType: string = '';

  static create(input: any): TimeOccurrenceModel {
    return ObjectMapper.deserialize<TimeOccurrenceModel>(TimeOccurrenceModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

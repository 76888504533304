import { Model } from "services/models/model";
import { IModel } from "../IModel";
import { ObjectMapper } from "json-object-mapper";

export class BenefitsEmployeeDependentsModel
  extends Model
  implements IModel<BenefitsEmployeeDependentsModel>
{
  benefitEmployeeId: string | undefined = undefined;
  benefitPlanId: string | undefined = undefined;
  dependentId: string | undefined = undefined;
  dependentName: string = "";
  dependentCpf: string = "";
  dateBegin: string = "";
  dateEnd: string = "";

  static create(input: any): BenefitsEmployeeDependentsModel {
    return ObjectMapper.deserialize<BenefitsEmployeeDependentsModel>(
      BenefitsEmployeeDependentsModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

<div class="drawer-app-custom {{ isDrawerSRequestWorkforcePlanDetails ? 'drawer-app-request-wfp' : '' }}" *ngIf="isDrawerOpened">
	<div class="drawer-overlay"></div>

	<div class="drawer-content">
		<div class="drawer-header px-2 py-1 d-flex align-items-center justify-content-between">
			<div>
				<ng-container *ngIf="data?.mode === 'add'">
					<span class="text-md">{{ drawerTitle }}</span>
				</ng-container>
				<ng-container *ngIf="data?.mode === 'details'">
					<span class="text-md">Detalhes da Solicitação</span>
					<span class="text-xs">{{ drawerTitle }}</span>
				</ng-container>
			</div>
			<button class="btn btn-icon" (click)="handleCancel()" [disabled]="isSubmittingForm">
				<i class="feather icon-x"></i>
			</button>
		</div>

		<hr class="m-0" />

		<form [formGroup]="form" class="form p-2">
			<ng-container *ngIf="drawerStatus === 'loading'">
				<div class="spinner-container flex align-items-center justify-content-center">
					<span class="spinner-border text-primary"></span>
				</div>
			</ng-container>

			<ng-container *ngIf="drawerStatus === 'fetched'">
				<ng-container *ngIf="data?.mode === 'add'">
					<div *ngIf="data?.type === DrawerTypes.EMPLOYEE_DETAILS">
						<ng-container *ngTemplateOutlet="employeeDetails; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_TRAINING">
						<ng-container *ngTemplateOutlet="requestTrainingForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.FUNCTION_CHANGE">
						<ng-container *ngTemplateOutlet="functionChangeForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.CENTER_COST_CHANGE">
						<ng-container *ngTemplateOutlet="centerCostChangeForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.SHIFT_CHANGE">
						<ng-container *ngTemplateOutlet="shiftChangeForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_DISMISSAL">
						<ng-container *ngTemplateOutlet="requestDismissalForm;context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_HIRING_EXPANSION">
						<ng-container *ngTemplateOutlet="requestHiringForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_HIRING_REPLACEMENT">
						<ng-container *ngTemplateOutlet="requestHiringReplacementForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_HIRING_REPLACEMENT_POSITION">
						<ng-container
							*ngTemplateOutlet="requestHiringReplacementPositionForm; context: { form: form }"></ng-container>
					</div>

					<div *ngIf="data?.type === DrawerTypes.REQUEST_WORK_FORCEPLAN">
						<ng-container *ngTemplateOutlet="requestWorkFoceplanForm; context: { form: form }"></ng-container>
					</div>

					<div class="drawer-actions d-flex gap-4 justify-content-end">
						<button class="btn btn-light" (click)="handleCancel()" [disabled]="isSubmittingForm">Cancelar</button>

						<ng-container *ngIf="data?.type !== DrawerTypes.EMPLOYEE_DETAILS">
							<button class="btn btn-primary" (click)="handleSubmit()" [disabled]="isSubmittingForm">
								<span *ngIf="isSubmittingForm" class="spinner-border spinner-border-sm"></span> Solicitar
							</button>
						</ng-container>
					</div>
				</ng-container>

				<ng-container *ngIf="data?.mode === 'details'">
					<ng-container *ngIf="!isDrawerSRequestWorkforcePlanDetails">
						<div class=" d-flex flex-column">
							<span>Solicitante</span>

							<div class="mt-1 d-flex gap-2 align-items-center">
								<img alt="avatar" class="avatar" [width]="36" [height]="36"
									[src]="data?.solicitationDetails?.requesterImage || 'assets/images/logo/avatar-profile.png'" />

								<div class="d-flex">
									<span class="font-weight-bolder">{{ data?.solicitationDetails?.requesterName }}</span>
								</div>
							</div>
						</div>

						<ng-container *ngIf="data?.solicitation?.employeeId">
							<div class="mt-2 d-flex flex-column">
								<span>Colaborador</span>

								<div class="mt-1 d-flex gap-3 align-items-center">
									<img alt="avatar" class="avatar" [width]="36" [height]="36"
										[src]="data?.solicitation?.employeeImage || 'assets/images/logo/avatar-profile.png'" />

									<div class="d-flex flex-column">
										<span class="font-weight-bolder">{{ data?.solicitation?.employeeName }}</span>
										<span class="text-xs">MAT: {{ data?.employee?.registration }}</span>
									</div>
								</div>
							</div>
						</ng-container>

						<ng-container *ngIf="data?.solicitation?.steps">
							<div class="mt-2 d-flex flex-column">
								<span>Fluxo de aprovação</span>

								<ul class="timeline timeline-approvers mt-2">
									<ng-container *ngFor="let step of data?.solicitation?.steps.slice(1)">
										<li class="timeline-item">
											<span class="timeline-point timeline-point-indicator {{ getColorIndicator(step?.status) }} "></span>
											<div class="timeline-event">
												<span class="font-weight-bolder"> {{ step?.stepName }}</span>
												<span class="align-self-center ml-1 cursor-pointer" *ngIf="step?.note" [ngbTooltip]="stepNote">
													<i class="custom-icon-comment"></i>
												</span>
												<ng-template #stepNote>
													<p class="card-text font-small-3 mb-0 text-wrap">{{step?.note}}</p>
												</ng-template>

												<div class="timeline-event__infos mt-1">
													<div class="avatar avatar-md mr-1"
														*ngIf="step.status === 'APPROVED' || step.status === 'REJECTED'">
														<img class="avatar" [width]="34" [height]="34"
															[src]="step.approverImage || 'assets/images/logo/avatar-profile.png'" />
													</div>

													<span>{{ getApprovalResponsible(step)}}</span>
												</div>
											</div>
										</li>
									</ng-container>
								</ul>
							</div>
						</ng-container>

						<div class="mt-2 d-flex flex-column form-group">
							<label>Tipo de solicitação</label>
							<input class="form-control" value="{{ data?.solicitation?.movementTypeLabel }}" disabled />
						</div>

						<ng-container *ngIf="data?.solicitation?.movementType === MovementType.TRAINNING.value">
							<ng-container *ngTemplateOutlet="requestTrainingForm; context: { form: form }"></ng-container>
						</ng-container>

						<ng-container *ngIf="data?.solicitation?.movementType === MovementType.DISMISSAL.value">
							<ng-container *ngTemplateOutlet="requestDismissalForm; context: { form: form }"></ng-container>
						</ng-container>

						<ng-container *ngIf="data?.solicitation?.movementType === MovementType.NEW_HIRING_REPLACEMENT.value">
							<ng-container *ngTemplateOutlet="requestHiringReplacementForm; context: { form: form }"></ng-container>
						</ng-container>

						<!-- <ng-container *ngIf="data?.solicitation?.movementType === MovementType.NEW_HIRING_REPLACEMENT_POSITION.value">
							<ng-container *ngTemplateOutlet="requestHiringReplacementPositionForm;"></ng-container>
						</ng-container> -->

						<ng-container *ngIf="data?.solicitation?.movementType === MovementType.NEW_HIRING_INCREASE.value">
							<ng-container *ngTemplateOutlet="requestHiringForm; context: { form: form }"></ng-container>
						</ng-container>

						<ng-container *ngIf="data?.solicitation?.movementType === MovementType.COST_CENTER_CHANGE.value">
							<ng-container *ngTemplateOutlet="centerCostChangeForm; context: { form: form }"></ng-container>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="isDrawerSRequestWorkforcePlanDetails">
						<app-drawer-workforceplan-solicitation-planning
							[data]="data"
							[source]="source"
						></app-drawer-workforceplan-solicitation-planning>
					</ng-container>

					<div class="form-group">
						<label>Ressalvas / Observações</label>
						<textarea type="text" class="form-control" [(ngModel)]="justification" placeholder="Justificativa" rows="4"
							[disabled]="!canExecute" [ngModelOptions]="{standalone: true}"></textarea>
					</div>

					<div class="drawer-actions d-flex gap-4 justify-content-between mb-2">
						<button type="button" class="btn btn-light" (click)="handleCancel()"
							[disabled]="statusDrawer === 'submitting'">Fechar</button>

						<ng-container *ngIf="canExecute">
							<div ngbDropdown container="body" dropdownClass="dropdown-menu-customized">
								<button *ngIf="isSubmittingForm == true" [disabled]="isSubmittingForm" class="btn btn-primary" type="button">
									<span class="spinner-border spinner-border-xs text-white"></span>
								</button>
								<button *ngIf="isSubmittingForm == false" class="btn btn-primary no-dropdown-toggle-icon no-boxshadow" type="button" ngbDropdownToggle>
									<span>Ações</span>
									<i data-feather="chevron-down" appFeatherIcon></i>
								</button>
								<div ngbDropdownMenu class="dropdown-menu p-0">
									<ng-container *ngIf="canUpdate">
										<a [disabled]="statusDrawer === 'submitting'" rippleEffect type="button" class="dropdown-item"
											(click)="handleSubmit()">
											Atualizar
										</a>
									</ng-container>
									<ng-container *ngIf="data?.solicitationDetails?.permission?.canExecute">
										<a [disabled]="statusDrawer === 'submitting'" rippleEffect type="button" class="dropdown-item"
											(click)="handleSubmitApprove()">
											Aprovar
										</a>
										<a [disabled]="statusDrawer === 'submitting'" rippleEffect type="button" class="dropdown-item"
											(click)="handleSubmitDecline()">
											Recusar
										</a>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
		</form>
	</div>
</div>

<ng-template #requestTrainingForm let-form="form">
	<div [formGroup]="form">
		<div class="form-group" *ngIf="data?.mode === 'add'">
			<label for="">Colaborador</label>

			<div class="employee-infos-container">
				<img alt="avatar" [src]="
            data?.employee.profileImagePath ||
            '/assets/images/logo/avatar-profile.png'
          " class="round avatar-img" height="40" width="40" />

				<div class="employee-name">
					<span>{{ data?.employee.name }}</span>
					<span>MAT: {{ data?.employee.registration || "" }}</span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label for="group">Tipo de treinamento</label>
			<input type="text" class="form-control" formControlName="trainingType" />
			<app-error-msg [control]="form.controls['trainingType']" [label]="'trainingType'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Centro de custo para o treinamento</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Centro de custo para o treinamento"
				[items]="lists.centerCostsOptions" bindValue="value" bindLabel="label"
				placeholder="Centro de custo para o treinamento" formControlName="costCenterId">
			</ng-select>
			<app-error-msg [control]="form.controls['costCenterId']" [label]="'costCenterId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Cargo para o treinamento</label>
			<ng-select class="auto-grow-options" title="Cargo para o treinamento" [items]="lists.jobTitleGroupOptions"
				bindValue="value" bindLabel="label" placeholder="Cargo para o treinamento" formControlName="jobTitleGroupId"
				(change)="onJobTitleGroupChanged($event)">
			</ng-select>
			<app-error-msg [control]="form.controls['jobTitleGroupId']" [label]="'jobTitleGroupId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Função para o treinamento</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Função para o treinamento"
				[items]="lists.jobTitleOptions" bindValue="value" bindLabel="label" placeholder="Função para o treinamento"
				formControlName="jobTitleId">
			</ng-select>
			<app-error-msg [control]="form.controls['jobTitleId']" [label]="'jobTitleId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Data para o início</label>
			<input type="date" class="form-control" formControlName="effectiveDate"
				(change)="onEffectiveDateChange($event)" />
			<app-error-msg [control]="form.controls['effectiveDate']" [label]="'effectiveDate'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Data para o fim</label>
			<input type="date" class="form-control" formControlName="endTrainingDate" />
			<app-error-msg [control]="form.controls['endTrainingDate']" [label]="'endTrainingDate'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Observações</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #centerCostChangeForm let-form="form">
	<div [formGroup]="form">
		<ng-container *ngIf="data?.mode === 'add'">
			<div class="form-group">
				<label for="">Colaborador</label>

				<div class="employee-infos-container">
					<img alt="avatar" [src]="
							data?.employee.profileImagePath ||
							'/assets/images/logo/avatar-profile.png'
						" class="round avatar-img" height="40" width="40" />

					<div class="employee-name">
						<span>{{ data?.employee.name }}</span>
						<span>MAT: {{ data?.employee.registration || "" }}</span>
					</div>
				</div>
			</div>
		</ng-container>

		<div class="form-group">
			<label for="group">Centro de custo atual</label>
			<select class="form-control cost-centers-options auto-grow-options" [disabled]="true">
				<option selected value="{{ data?.employee?.costCenterName }}">{{ data?.employee?.costCenterName }}</option>
			</select>
		</div>

		<div class="form-group">
			<label for="group">Função atual</label>
			<select class="form-control cost-centers-options auto-grow-options" [disabled]="true">
				<option selected value="{{ data?.employee?.jobTitleName }}">{{ data?.employee?.jobTitleName }}</option>
			</select>
		</div>

		<div class="title">
			<span>Novo Centro de Custo</span>
		</div>

		<div class="form-group">
			<label for="group">Centro de Custo</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Centro de Custo"
				[items]="lists.centerCostsOptions" bindValue="value" bindLabel="label" placeholder="Centro de Custo"
				formControlName="newCostCenterId" (change)="onCenterCostChanged($event)">
			</ng-select>
			<app-error-msg [control]="form.controls['newCostCenterId']" [label]="'newCostCenterId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Nova Função</label>
			<ng-select container="body" class="cost-centers-options auto-grow-options" title="Nova função"
				[items]="lists.jobTitleOptions" bindValue="value" bindLabel="label" placeholder="Nova função"
				formControlName="newJobTitleId" (change)="onJobTitleChange($event)">
			</ng-select>
			<app-error-msg [control]="form.controls['newJobTitleId']" [label]="'newJobTitleId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Justificativa</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #requestDismissalForm let-form="form">
	<div [formGroup]="form">
		<ng-container *ngIf="data?.mode === 'add'">
			<div class="form-group">
				<label for="">Colaborador</label>

				<div class="employee-infos-container">
					<img alt="avatar" [src]="
							data?.employee.profileImagePath ||
							'/assets/images/logo/avatar-profile.png'
						" class="round avatar-img" height="40" width="40" />

					<div class="employee-name">
						<span>{{ data?.employee.name }}</span>
						<span>MAT: {{ data?.employee.registration || "" }}</span>
					</div>
				</div>
			</div>
		</ng-container>

		<div class="form-group">
			<label for="group">Turno</label>
			<select class="form-control cost-centers-options auto-grow-options" [disabled]="true">
				<option selected value="Turno {{ data?.employee?.timeShiftTitle }}">Turno {{ data?.employee?.timeShiftTitle }}
				</option>
			</select>
		</div>

		<div class="form-group">
			<label for="group">Motivo de desligamento</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Motivo de desligamento"
				[items]="lists.resignationTypesOptions" bindValue="label" bindLabel="label" placeholder="Motivo de desligamento"
				formControlName="resignationReason">
			</ng-select>
			<app-error-msg [control]="form.controls['resignationReason']" [label]="'resignationReason'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Data prevista para o desligamento</label>
			<input type="date" class="form-control" formControlName="effectiveDate"
				placeholder="Data prevista para o desligamento" />
			<app-error-msg [control]="form.controls['effectiveDate']" [label]="'effectiveDate'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<div class="d-flex gap-2 mb-small">
				<label class="form-control-label">Iniciativa do colaborador:</label>
				<div class="custom-control custom-switch custom-switch-success">
					<input type="checkbox" class="custom-control-input" id="enableSwitchWasEmployeeInitiative"
						formControlName="wasEmployeeInitiative" />
					<label class="custom-control-label" for="enableSwitchWasEmployeeInitiative">
						<span class="switch-icon-left"><i data-feather="check"></i></span>
						<span class="switch-icon-right"><i data-feather="x"></i></span>
					</label>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label for="">Justificativa</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #requestHiringForm let-form="form">
	<div [formGroup]="form">
		<div class="form-group">
			<label for="group">Turno</label>
			<ng-select class="auto-grow-options" title="Turno" [items]="lists.timeShiftsOptions" bindValue="id"
				bindLabel="title" placeholder="Turno" formControlName="timeShiftId">
			</ng-select>
			<app-error-msg [control]="form.controls['timeShiftId']" [label]="'timeShiftId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Cargo</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Cargo" [items]="lists.jobTitleGroupOptions"
				bindValue="value" bindLabel="label" placeholder="Cargo" formControlName="jobTitleGroupId">
			</ng-select>
			<app-error-msg [control]="form.controls['jobTitleGroupId']" [label]="'jobTitleGroupId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Função</label>
			<ng-select class="cost-centers-options auto-grow-options" title="Cargo" [items]="lists.jobTitleOptions"
				bindValue="value" bindLabel="label" placeholder="Função" formControlName="jobTitleId">
			</ng-select>
			<app-error-msg [control]="form.controls['jobTitleId']" [label]="'jobTitleId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Tipo de aumento</label>
			<ng-select class="auto-grow-options" title="Tipo de aumento" [items]="lists.hiringTypeOptions" bindValue="value"
				bindLabel="label" placeholder="Tipo de aumento" formControlName="raiseType" [readonly]="true">
			</ng-select>
			<app-error-msg [control]="form.controls['raiseType']" [label]="'raiseType'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Prazo para admissão</label>
			<input type="date" class="form-control" formControlName="effectiveDate" placeholder="Prazo para admissão" />
			<app-error-msg [control]="form.controls['effectiveDate']" [label]="'Prazo para admissão'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Justificativa</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #requestHiringReplacementForm let-form="form">
	<div [formGroup]="form">

		<div class="form-group">
			<label for="">Colaborador</label>
			<ng-select class="auto-grow-options" title="Colaborador" [items]="lists.employeesResignationsOptions"
				bindValue="id" bindLabel="name" placeholder="Colaborador" formControlName="employeeId"
				(change)="onEmplooyeeChanged($event)">
			</ng-select>
			<app-error-msg [control]="form.controls['employeeId']" [label]="'Colaborador'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Data do desligamento</label>
			<input type="date" class="form-control" formControlName="deadline" [readonly]="true" />
		</div>

		<div class="title">
			<span>Dados para a nova contratação</span>
		</div>

		<div class="form-group">
			<label for="group">Turno</label>
			<input type="text" class="form-control" [value]="employeeSelected?.timeshiftTitle" [readonly]="true" />
		</div>

		<div class="form-group">
			<label for="">Função</label>
			<input type="text" class="form-control" [value]="employeeSelected?.jobTitleName" [readonly]="true" />
		</div>

		<div class="form-group">
			<label for="">Prazo para admissão</label>
			<input type="date" class="form-control" formControlName="effectiveDate" placeholder="Prazo para admissão" />
			<app-error-msg [control]="form.controls['effectiveDate']" [label]="'effectiveDate'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Justificativa</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #requestHiringReplacementPositionForm let-form="form">
	<div [formGroup]="form">
		<div class="form-group">
			<label for="group">Turno</label>
			<ng-select class="auto-grow-options" title="Turno" [items]="lists.timeShiftsOptions" bindValue="id"
				bindLabel="title" placeholder="Turno" formControlName="timeShiftId">
			</ng-select>
			<app-error-msg [control]="form.controls['timeShiftId']" [label]="'timeShiftId'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="group">Cargo</label>
			<input type="text" class="form-control" [value]="getEmployeeJoTitleName()" disabled="true" />
		</div>

		<div class="form-group">
			<label for="group">Tipo de aumento</label>
			<ng-select class="auto-grow-options" title="Tipo de aumento" [items]="lists.hiringTypeOptions" bindValue="value"
				bindLabel="label" placeholder="Tipo de aumento" formControlName="raiseType" [readonly]="true">
			</ng-select>
			<app-error-msg [control]="form.controls['raiseType']" [label]="'raiseType'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Prazo para admissão</label>
			<input type="date" class="form-control" formControlName="effectiveDate" placeholder="Prazo para admissão" />
			<app-error-msg [control]="form.controls['effectiveDate']" [label]="'Prazo para admissão'">
			</app-error-msg>
		</div>

		<div class="form-group">
			<label for="">Justificativa</label>
			<textarea type="text" class="form-control" formControlName="note"></textarea>
			<app-error-msg [control]="form.controls['note']" [label]="'note'">
			</app-error-msg>
		</div>
	</div>
</ng-template>

<ng-template #employeeDetails let-form="form">
	<div [formGroup]="form">
		<div class="form-group">
			<label for="">Colaborador</label>

			<div class="employee-infos-container">
				<img alt="avatar" [src]="
            data?.employee.profileImagePath ||
            '/assets/images/logo/avatar-profile.png'
          " class="round avatar-img" height="40" width="40" />

				<div class="employee-name">
					<span>{{ data?.employee.name }}</span>
					<span>MAT: {{ data?.employee.registration || "" }}</span>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label for="">Data de admissão</label>
			<input type="text" class="form-control" [value]="data?.employee?.admissionDate | date : 'dd/MM/yyyy'" readonly />
		</div>

		<div class="form-group">
			<label for="">Função</label>
			<input type="text" class="form-control" [value]="data?.employee?.jobTitleName || ''" readonly />
		</div>

		<div class="form-group">
			<label for="">Turno</label>
			<input type="text" class="form-control" [value]="data?.employee?.workScaleGroup || ''" readonly />
		</div>

		<div class="form-group">
			<label for="">Contato</label>
			<input type="text" class="form-control" [value]="data?.employee?.cellPhoneNumber || ''" [mask]="'(00) 00000-0000'"
				readonly />
		</div>

		<div class="form-group">
			<label for="">E-mail</label>
			<input type="text" class="form-control" [value]="data?.employee?.email || ''" readonly />
		</div>

		<div class="form-group">
			<label for="">Situação</label>
			<input type="text" class="form-control" [value]="data.employee?.statusLabel || ''" readonly />
		</div>

		<div class="form-group">
			<label for="">Tabela de folga</label>
			<input type="text" class="form-control" [value]="data.employee?.shiftSchedule || ''" readonly />
		</div>
	</div>
</ng-template>
import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { ReferencePeriodStatusType } from "services/enums/ReferencePeriodStatusType.enum";
import { ReferencePeriodVacationScheduleModel } from "./referencePeriodVacationSchedule.model";

export class ReferencePeriodVacationManagerModel extends Model implements IModel<ReferencePeriodVacationManagerModel> {
  employeeId: string | undefined = undefined;
  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  daysOfLaw: number = 0;
  paidDays: number = 0;
  daysToPay: number = 0;
  dateBegin: Date = null;
  dateEnd: Date = null;
  currentStatus: any = ReferencePeriodStatusType.Overdue.value;
  periodStatus: any = ReferencePeriodStatusType.Overdue.value;
  scheduleStatus: any = ReferencePeriodStatusType.Overdue.value;
  status: any = ReferencePeriodStatusType.Overdue.value;
  scheduledDays: number = 0;
  scheduledAllowanceDays: number = 0;
  dateLimit: Date = null;
  employeeName: string = '';
  employeeRegistration: string = '';
  timeShiftTitle: string = '';
  costCenterCode: string = '';
  costCenterName: string = '';
  jobTitleName: string = '';
  companyName: string = '';
  schedules: ReferencePeriodVacationScheduleModel[] = [];
  currentPaidDays: number = 0;
  timeShiftId: string | undefined = undefined;
  costCenterId: string | undefined = undefined;
  jobTitleId: string | undefined = undefined;

  get statusBadge(): string {
    const status = ReferencePeriodStatusType.getBy(this.scheduleStatus);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  get periodStatusBadge(): string {
    const status = ReferencePeriodStatusType.getBy(this.periodStatus);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  static create(input: any): ReferencePeriodVacationManagerModel {
    return ObjectMapper.deserialize<ReferencePeriodVacationManagerModel>(ReferencePeriodVacationManagerModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

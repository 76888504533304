import { ObjectMapper } from 'json-object-mapper';
import { IModel } from '../IModel';
import { Model } from '../model';

export interface ExitInterviewDashThermometerQuestionType {
  questionLabel: string,
  percent: number,
  count: number,
  options: { name: string, data: number[] }[]
}

export interface ExitInterviewDashThermometerType {
  section: string;
  sectionAverage: number;
  yearMonth: string;
  questions?: ExitInterviewDashThermometerQuestionType[];
}

export interface ExitInterviewCommentsType {
  sectionLabel: string,
  questionLabel: string,
  answer: string,
  // employeeId: string
}

export interface ExitInterviewDashSectionType {
  name: string;
  charts: ExitInterviewDashSectionChartType[];
}

export interface ExitInterviewDashSectionChartType {
  data: any,
  title: string,
  type: string,
  id: string
}

export interface AvailableOptionsType {
  costCenterIds: string[],
  timeShiftIds: string[],
  jobTitleIds: string[],
  classifications: string[],
}

export class ExitInterviewDashModel extends Model implements IModel<ExitInterviewDashModel> {
  comments: ExitInterviewCommentsType[] = [];
  thermometers: ExitInterviewDashThermometerType[] = [];
  thermometersAnnual: ExitInterviewDashThermometerType[] = [];
  binaryThermometers: any[] = [];
  answersCharts: ExitInterviewDashSectionType[] = [];
  corporateCharts: ExitInterviewDashSectionType[] = [];
  resignationMeasure: string = '';
  thermometersAverage: string = '';
  exitInterviewCount: number = 0;
  exitInterviewNotAnsweredCount: number = 0;
  resignationCount: number = 0;
  options: AvailableOptionsType = { costCenterIds: [], jobTitleIds: [], timeShiftIds: [], classifications: [] };

  static create(input: any): ExitInterviewDashModel {
    return ObjectMapper.deserialize<ExitInterviewDashModel>(ExitInterviewDashModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "./IModel";
import { EmployeeModel } from "./employee.model";
import { UserModel } from "./corporate/user.model";

export class HomeModel implements IModel<HomeModel> {
  employee: EmployeeModel = new EmployeeModel();
  features: any = undefined;
  banners: any = undefined;
  messages: any = undefined;
  people: EmployeeModel[] = [];

  static create(input: any): HomeModel {
    return ObjectMapper.deserialize<HomeModel>(HomeModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

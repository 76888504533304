import { Injectable } from "@angular/core";
import { ApplicantService } from "../../../../services/hiring/applicant.service";

import { BehaviorSubject } from "rxjs";
import { HiringStatusType } from "services/enums/HiringStatusType.enum";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public apiData: any;
  public onApiDataChange: BehaviorSubject<any>;

  constructor(public applicantService: ApplicantService) {
    this.onApiDataChange = new BehaviorSubject("");
    // this.getNotificationsData();
  }

  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.applicantService
        .listByStatus(HiringStatusType.WaitingSesmt)
        .subscribe((response: any) => {
          const messages = response.map((x) => {
            return {
              text: `${x.name}`,
              title: "👷 Aguardando validação do Sesmet",
              type: HiringStatusType.WaitingSesmt.id,
              image:
                "https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/demo-1/assets/images/portrait/small/avatar-s-15.jpg",
            };
          });
          this.apiData = {
            messages: messages,
            systemMessages: [],
            system: Boolean,
          };
          this.onApiDataChange.next(this.apiData);
          resolve(this.apiData);
        }, reject);
    });
  }
}

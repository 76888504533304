import { ObjectMapper } from "json-object-mapper";
import { DigitalSignatureStatus, DigitalSignatureStatusEnum } from "services/enums/digitalSignatureStatus.enum";
import { IModel } from "../IModel";
import { Model } from "../model";
import { DocumentSignatureSignerModel } from "./document-signature-signer.model";

export class DocumentSignatureGroupDocumentModel extends Model implements IModel<DocumentSignatureGroupDocumentModel> {
	title: string = "";
	hashChecksumSHA256: string = "";
	status: DigitalSignatureStatus = DigitalSignatureStatusEnum.PENDING.value as DigitalSignatureStatus;
	limitDate: string = "";
	companyGroupId: string = "";
	signatureGroupId: string = "";
	creatorEmployeeId: string = "";
	creatorEmployeeName: string | null = null
	creatorEmployeeImageUrl: string | null = null
	originalFileS3Path: string = "";
	originalFileExtension: "pdf" | string = "";
	signedFileS3Path: string | null = null;
	fileSize: number = 0;
	fileName: string | null = null;
	signedFileExtension: string | null = null;

	static create(input): DocumentSignatureGroupDocumentModel {
		return ObjectMapper.deserialize<DocumentSignatureGroupDocumentModel>(
			DocumentSignatureGroupDocumentModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

export class DocumentSignatureGroupModel extends Model implements IModel<DocumentSignatureGroupModel> {
	id: string = "";
	title: string = "";
	type: "general" | "hiring" = "general";
	companyGroupId: string = "";
	countDocuments: number = 0;
	countDocumentsSigned: number = 0;
	documents: DocumentSignatureGroupDocumentModel[] = [];
	signers: DocumentSignatureSignerModel[] = [];

	static create(input): DocumentSignatureGroupModel {
		return ObjectMapper.deserialize<DocumentSignatureGroupModel>(
			DocumentSignatureGroupModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

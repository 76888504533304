import { BaseEnum } from "./baseEnum";

const listDigitalSignatureStatusEnum: Array<DigitalSignatureStatusEnum> = [];

export type DigitalSignatureStatus =
	| "CANCELLED"
	| "PENDING"
	| "OPEN"
	| "PARTIAL_SIGNED"
	| "SIGNED";

export class DigitalSignatureStatusEnum extends BaseEnum {
	static CANCELLED = new DigitalSignatureStatusEnum(
		-1,
		"CANCELLED",
		"Cancelado"
	);
	static PENDING = new DigitalSignatureStatusEnum(0, "PENDING", "Pendente");
	static OPEN = new DigitalSignatureStatusEnum(1, "OPEN", "Aberto");
	static PARTIAL_SIGNED = new DigitalSignatureStatusEnum(
		2,
		"PARTIAL_SIGNED",
		"Parcialmente assinado"
	);
	static SIGNED = new DigitalSignatureStatusEnum(3, "SIGNED", "Assinado");
	constructor(public id: number, public value: string, public label: string) {
		super(id, value, label);
		listDigitalSignatureStatusEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listDigitalSignatureStatusEnum;
	}
}

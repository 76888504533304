import { ObjectMapper } from "json-object-mapper";
import { Model } from "../model";
import { IModel } from "../IModel";
import { BenefitType, BenefitTypeEnum } from "services/enums/benefitsType.enum";
import { FileContentModel } from "../fileContent";
import { BenefitModel } from "./benefit.model";

export class BenefitProviderModel
  extends Model
  implements IModel<BenefitProviderModel>
{
  companyRootId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  name: string = "";
  businessName: string = "";
  cnpj: string = "";
  zipCode: string = "";
  state: string = "";
  city: string = "";
  district: string = "";
  address: string = "";
  number: string = "";
  complement: string = "";
  email: string = "";
  contact: string = "";
  type: BenefitType = "FoodVoucher";
  logoFile: string = "";
  logoFileName: string = "";
  logoFileContent: FileContentModel = new FileContentModel();
  benefits: BenefitModel[] = [];

  static create(input: any): BenefitProviderModel {
    return ObjectMapper.deserialize<BenefitProviderModel>(
      BenefitProviderModel,
      input
    );
  }

  get typeLabel(): string {
    return BenefitTypeEnum.getBy(this.type).label;
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import {
	HttpClient,
	HttpContext
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BYPASS_LOG } from '@core/interceptors/loading-screen.interceptor';
import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class HttpService {
	protected baseUrl: URL = new URL(environment.apiUrl);

	constructor(private http: HttpClient) { }

	public changBaseUrl(url: string) {
		if (url) this.baseUrl = new URL(url);
	}

	public get(
		uri: string,
		options?: any,
		baseUrl?: string,
		bodyParams?: any,
		disableLoanding = false
	): Observable<any> {
		const opt = options
			? {
				...options,
				observe: 'response',
				context: new HttpContext().set(BYPASS_LOG, disableLoanding),
			}
			: {
				observe: 'response',
				context: new HttpContext().set(BYPASS_LOG, disableLoanding),
			};

		if (bodyParams) opt['params'] = ObjectMapper.serialize(bodyParams);

		return new Observable<any>((observer) => {
			this.http.get(this.getEndpoint(uri, baseUrl), opt).subscribe(
				(response) => {
					observer.next(response);
					observer.complete();
				},
				(err) => observer.error(err)
			);
		});
	}

	public post(
		uri: string,
		params: any,
		options?: any,
		baseUrl?: string,
		disableLoanding = false
	): Observable<any> {
		let body: any = null;
		if (typeof params === 'string') {
			body = params;
		} else {
			this.convertTimes(params);
			body = ObjectMapper.serialize(params);
			body = body.length > 1 ? body : params.toJSON();
		}
		return new Observable<any>((observer) => {
			const url = baseUrl ? `${baseUrl}${uri}` : this.getEndpoint(uri);

			const opt = options
				? {
					...options,
					context: new HttpContext().set(BYPASS_LOG, disableLoanding),
				}
				: {
					context: new HttpContext().set(BYPASS_LOG, disableLoanding),
				};

			this.http.post(url, body, opt).subscribe(
				(response) => {
					observer.next(response);
					observer.complete();
				},
				(err) => {
					observer.error(err);
				}
			);
		});
	}

	public put(uri: string, params: any): Observable<Response> {
		return new Observable<any>((observer) => {
			this.http
				.put(this.getEndpoint(uri), JSON.stringify(params), {})
				.pipe(map((response) => response))
				.subscribe((response) => {
					observer.next(response);
					observer.complete();
				},
					(err) => {
						observer.error(err);
					});
		});
	}

	public remove(id: string): Observable<Response> {
		return new Observable<any>((observer) => {
			this.http
				.delete(this.getEndpoint(id))
				.pipe(map((response) => response))
				.subscribe((response) => {
					observer.next(response);
					observer.complete();
				},
					(err) => {
						observer.error(err);
					});
		});
	}

	public convertTimes(object: any) {
		Object.keys(object).forEach((e) => {
			const isDate = object[e] instanceof Date;
			if (isDate) {
				object[e] = object[e].toLocaleString();
			}
		});
	}

	private getEndpoint(path: string, baseUrl?: string): string {
		return `${baseUrl ? baseUrl : this.baseUrl}/${path}`;
	}
}

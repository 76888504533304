import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";

export class StreetTypeModel
  extends Model
  implements IModel<StreetTypeModel> {

  title: string = '';
  abreviation: string = '';
  companyGroupId: string | undefined = undefined;
  value: string = '';
  description: string = '';

  static create(input: any): StreetTypeModel {
    return ObjectMapper.deserialize<StreetTypeModel>(
      StreetTypeModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  toOption(): OptionModel {
    return {
      label: this.description,
      value: this.value,
    } as OptionModel;
  }
}
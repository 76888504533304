import {ObjectMapper} from "json-object-mapper";
import {IModel} from "../IModel";
import {Model} from "../model";
import {ActionRequiredSource} from "services/enums/ActionRequiredSource.enum";

export class ActionRequiredModel extends Model implements IModel<ActionRequiredModel> {
	companyGroupId: string = "";
	source: string = "";
	type: number = 0;
	title: string = "";
	description: string = "";
	employeeIds: string[] = [];
	accessProfilesIds: string[] = [];
	status: string = "PENDING";
	getUrl: string = "";

	get sourceData(): ActionRequiredSource {
		return ActionRequiredSource.getBy(this.source);
	}

	static create(input: any): ActionRequiredModel {
		return ObjectMapper.deserialize<ActionRequiredModel>(ActionRequiredModel, input);
	}
}

import { Injectable } from "@angular/core";
import { FileService } from "@core/services/file.service";
import { environment } from "environments/environment";
import { HttpService } from "../http.service";
import { ServiceBase } from "../servicebase";
import { CompanyRootModel } from "services/models/companyRoot.model";
import { Observable } from "rxjs";
import { Response } from "services/models/response";
import { QueryParams } from "services/models/odata/queryParams";
import { map } from "rxjs/operators";
import { ObjectMapper } from "json-object-mapper";
import { OrganizationModel } from "services/models/organization.model";

@Injectable()
export class CompanyRootService extends ServiceBase<CompanyRootModel> {
  constructor(http: HttpService, private file: FileService) {
    super("company", http, environment.apiUrl);
  }

  createEntity(input: any): CompanyRootModel {
    return CompanyRootModel.create(input);
  }

  getCompaniesRootOptions(
    params: QueryParams
  ): Observable<Response<CompanyRootModel[]>> {
    const defaultSelect = ["id", "enable", "creationDate"];
    params.select = [
      ...params.select.filter((field) => !defaultSelect.includes(field)),
      ...defaultSelect,
    ];

    const query = this.createBuildQuery(params);
    return this.http
      .get(
        `${this.endpoint}/list-companyroots${query}`,
        undefined,
        this.baseUrl
      )
      .pipe(
        map((res) => {
          const response = new Response<CompanyRootModel[]>();
          response.data = res.body.map(
            (pp) => this.createEntity(pp) as CompanyRootModel
          );
          response.count = res.headers.get("x-count");
          return response;
        })
      );
  }

  saveCompanyRoot(id: string | undefined, obj: any) {
    let _object = ObjectMapper.serialize(obj);
    return id == undefined
      ? this.http.post(`${this.endpoint}/company-root`, _object)
      : this.http.put(
          `${this.endpoint}/company-root`,
          JSON.parse(_object.toString())
        );
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/company-root/${id}`);
  }
}

import { Injectable } from "@angular/core";
import { ObjectMapper } from "json-object-mapper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { HierarchyLeaderModel } from "services/models/corporate/hierarchyLeader.model";
import { HierarchyLevelModel } from "services/models/corporate/hierarchyLevel.model";
import type { HierarchyTreeModel } from "services/models/corporate/hierarchyTree.model";
import { Filter } from "services/models/odata/filter";
import { QueryParams } from "services/models/odata/queryParams";
import { ServiceBase } from "services/servicebase";
import { environment } from "../../../environments/environment";

@Injectable()
export class HierarchyLeaderService extends ServiceBase<HierarchyLeaderModel> {
  constructor(http: HttpService) {
    super("hierarchy", http, environment.apiUrl);
  }

  createEntity(input: any): HierarchyLeaderModel {
    return HierarchyLeaderModel.create(input);
  }

  getOptionsByParent(parentId: string): Observable<any> {
    return this.http.get(`${this.endpoint}/options-by-parent/${parentId}`, null, null, null, true).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getLevel(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/level/${id}`).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getHierarchyLevel(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/level/${id}`).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  saveLevel(id: string | undefined, obj: any) {
    let _object = ObjectMapper.serialize(obj);
    return id == undefined
      ? this.http.post(`${this.endpoint}/level`, _object)
      : this.http.put(`${this.endpoint}/level`, JSON.parse(_object.toString()));
  }

  getHierarchyLevels(companyGroupId: string): Observable<HierarchyLevelModel[]> {
    return new Observable<any>((observer) => {
      this.customList<HierarchyLevelModel>(
        {
          select: ["id", "title", "description", "level", "companyGroupId"],
          orderBy: "level",
          orderByOrder: "asc",
          filter: Filter.equalToGuid("companyGroupId", companyGroupId),
        } as QueryParams,
        "list-levels",
        HierarchyLevelModel
      ).subscribe((res) => {
        observer.next(res.data);
      }),
        (err) => {
          observer.error(err);
        };
    });
  }

  getLeaderLevels(): Observable<HierarchyLevelModel[]> {
    return this.http.get(`${this.endpoint}/list-leader-levels`, null, null, null, true).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getHierarchyTree(companyRootId: string): Observable<HierarchyTreeModel> {
    return this.http.get(`${this.endpoint}/search-hierarchy-tree?companyRootId=${companyRootId}`).pipe(
      map((res) => {
        return res.body;
      })
    );
  }
}
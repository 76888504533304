import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { ExitInterviewStatusType } from "services/enums/ExitInterviewStatusType.enum";

export class ExitInterviewEventModel
  extends Model
  implements IModel<ExitInterviewEventModel>
{
  exitInterviewId: string | undefined = undefined;
  responsibleId: string | undefined = undefined;
  employeeName: string = "";
  employeeRegistration: string = "";
  status: any = ExitInterviewStatusType.CREATED;

  static create(input: any): ExitInterviewEventModel {
    return ObjectMapper.deserialize<ExitInterviewEventModel>(
      ExitInterviewEventModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }

  get statusLabel(): string {
    const status = ExitInterviewStatusType.getBy(this.status);

    return status?.label;
  }

  get statusClassName(): string {
    const status = ExitInterviewStatusType.getBy(this.status);

    return status?.className;
  }
}

import { ObjectMapper } from "json-object-mapper";
import { BenefitDiscountType } from "services/enums/benefitDiscountType.enum";
import { BenefitPricingType } from "services/enums/benefitPricingType.enum";
import { FileContentModel } from "../fileContent";
import { IModel } from "../IModel";
import { Model } from "../model";
import { BenefitType, BenefitTypeEnum } from "services/enums/benefitsType.enum";

export class BenefitModel extends Model implements IModel<BenefitModel> {
  providerId: string | undefined = undefined;
  documentMockupId: string | undefined = undefined;
  companyRootId: string | undefined = undefined;
  description: string = "";
  pricingType: BenefitPricingType = "UNSET";
  discountType: BenefitDiscountType = "PERCENTAGEM";
  benefitType: BenefitType = "MealVoucher";
  detailsFile: string = "";
  detailsFileName: string = "";
  logoFile: string = "";
  businessName: string = "";
  name: string = "";
  detailsFileContent: FileContentModel = new FileContentModel();

  get benefitTypeLabel(): string {
    return BenefitTypeEnum.getBy(this.benefitType).label;
  }

  static create(input: any): BenefitModel {
    return ObjectMapper.deserialize<BenefitModel>(BenefitModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CoreMenuItem } from "@core/types";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "./http.service";
import { CompanygroupSettingsModel } from "./models/companygroupSettings.model";
import { TradeUnionModel } from "./models/corporate/tradeUnion.model";
import { DomainModel } from "./models/domain.model";
import { FeatureFlagModel } from "./models/featureFlag.model";
import { OptionModel } from "./models/model";
import { Response } from "./models/response";
import { ServiceBase } from "./servicebase";
import { StreetTypeModel } from "./models/hiring/streetType.model";

@Injectable()
export class DomainService extends ServiceBase<DomainModel> {
	domainSubject = new BehaviorSubject(null);

	constructor(http: HttpService) {
		super("companygroup", http);
	}

	createEntity(input: any): DomainModel {
		return DomainModel.create(input);
	}

	setDomain(domain: DomainModel) {
		if (domain) {
			this.domainSubject.next(domain);
		}
	}

	getDomain(): DomainModel {
		return this.domainSubject.value;
	}

	getMenu(): CoreMenuItem[] {
		let menus = this.getDomain()?.menu;

		if (menus) {
			const defaultMenu = {
				icon: "feather-home",
				id: "home",
				title: "Inicio",
				type: "item",
				url: `${this.getDomain().domainName}/home`,
			} as CoreMenuItem;

			return [defaultMenu, ...menus];
		}

		return [];
	}

	getAllowedUrls(): string[] {
		return this.getDomain()?.allowedUrls.concat([
			`${this.getDomain().domainName}/actionrequired`,
			`${this.getDomain().domainName}/outsourced`,
		]);
	}

	get CompanyImageUrl(): string {
		const domain = this.getDomain();

		if (domain && domain?.image) {
			try {
				return domain.image;
			} catch (_) {
				return `${this.BASE_COMPANIES_IMAGE_URL}/${domain.image}`;
			}
		}

		return null;
	}

	checkDomainName(
		domainName: string,
		noCache: boolean = false
	): Observable<DomainModel> {
		const options = noCache
			? {
				headers: new HttpHeaders({
					"Cache-Control": "no-cache",
				}),
			}
			: undefined;
		return this.http
			.get(`${this.endpoint}/check.domainname/${domainName}`, options)
			.pipe(
				map(({ body }) => {
					return DomainModel.create(body);
				})
			);
	}

	checkModule(module: string): boolean {
		const modules = [...this.getMenu().filter((f) => !f.children)];
		this.getMenu().forEach((item) => {
			if (item.children) {
				modules.push(...item.children);
			}
		});
		const isModuleFound = modules.find((f) => f.id === module) !== undefined;

		return isModuleFound;
	}

	getTradeUnionOptions(): Observable<Response<OptionModel[]>> {
		return this.http.get("companygroup/list-trade-unions").pipe(
			map((res: any) => {
				const response = new Response<OptionModel[]>();
				response.data = res.body.map((e: TradeUnionModel) =>
					TradeUnionModel.create(e).toOption()
				);
				return response;
			})
		);
	}

	get featureFlag(): FeatureFlagModel {
		return FeatureFlagModel.create(this.getDomain().featureFlag);
	}

	getCompanyGroupSettingsWeb(): Observable<CompanygroupSettingsModel> {
		return this.http
			.get("companygroup/settings-web")
			.pipe(map((res: any) => CompanygroupSettingsModel.create(res.body)));
	}

	getCompanyGroupSettings(): Observable<CompanygroupSettingsModel> {
		return this.http
			.get("companygroup/settings")
			.pipe(map((res: any) => CompanygroupSettingsModel.create(res.body)));
	}

	saveSettings(settings: any): Observable<Response<any>> {
		return this.http.put(`${this.endpoint}/settings`, settings).pipe(
			map((res) => {
				const response = new Response<any>();
				response.data = res;
				console.log(settings);
				return response;
			})
		);
	}

	getStreetTypeOptions(): Observable<Response<OptionModel[]>> {
		return this.http.get("companygroup/list-street-types").pipe(
			map((res: any) => {
				const response = new Response<OptionModel[]>();
				response.data = res.body.map((st: StreetTypeModel) =>
					StreetTypeModel.create(st).toOption()
				);
				return response;
			})
		);
	}
}

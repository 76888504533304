import { Component, OnInit } from '@angular/core';
import { HomeService } from 'services/home.service';
import { WeatherModel } from 'services/models/weather.model';

@Component({
  selector: "app-weather",
  templateUrl: "./weather.component.html",
  styleUrls: ["./weather.component.scss"],
})
export class WeatherComponent implements OnInit {
  weather: WeatherModel = new WeatherModel();
  locationGranted: boolean = false;
  isLoading: boolean = false;
  iconMap = {
    1: 'assets/images/icons/1-cloudy-day.svg',
    2: 'assets/images/icons/2-partial-cloudy-day.svg',
    3: 'assets/images/icons/3-only-cloudy-day.svg',
    4: 'assets/images/icons/4-sunny.svg',
    5: 'assets/images/icons/5-rainy-day.svg',
    6: 'assets/images/icons/6-cloudy-night.svg',
    7: 'assets/images/icons/7-partial-cloudy-night.svg',
    8: 'assets/images/icons/8-only-cloudy-night.svg',
    9: 'assets/images/icons/9-moon.svg',
    10: 'assets/images/icons/10-rainy-night.svg',
  };

  constructor(private _service: HomeService) { }

  ngOnInit(): void {
    this.getUserLocation();
  }

  getIcon() {
    return this.iconMap[this.weather.icon] || 'assets/images/icons/4-sunny.svg';
  }

  getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.locationGranted = true;
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          this.isLoading = true;
          this._service.getWeatherConditions(lat, lon).subscribe(res => {
            this.weather = res;
            this.isLoading = false;
          }, () => this.isLoading = false )
        },
        error => {
          console.error('Error', error);
        }
      );
    } else {
      console.error('Geolocalização não é suportada por esse navegador.');
    }
  }
}

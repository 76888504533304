import { BaseEnum } from "./baseEnum"

const listEnum: Array<HourlyBasisStatusType> = [];

export class HourlyBasisStatusType extends BaseEnum {
  static Rejected = new HourlyBasisStatusType(0, 'REJECTED', 'Rejeitado', 'danger');
  static Pending = new HourlyBasisStatusType(1, 'PENDING', 'Pendente', 'warning');
  static Approved = new HourlyBasisStatusType(2, 'APPROVED', 'Aprovado', 'success');
  static Finished = new HourlyBasisStatusType(3, 'FINISHED', 'Encerrado', 'primary');

  constructor(public id: number, public value: string, public label: string, public className: string) {
    super(id, value, label, className)
    listEnum.push(this);
  }

  public static buildListEnum(): Array<BaseEnum> {
    return listEnum;
  }
}
import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";
import { ReferencePeriodStatusType } from "services/enums/ReferencePeriodStatusType.enum";

export class ReferencePeriodVacationModel extends Model implements IModel<ReferencePeriodVacationModel> {
  employeeId: string | undefined = undefined;
  companyId: string | undefined = undefined;
  companyGroupId: string | undefined = undefined;
  daysOfLaw: number = 0;
  paidDays: number = 0;
  daysToPay: number = 0;
  currentPaidDays: number = 0;
  dateBegin: string = '';
  dateEnd: string = '';
  status: any = ReferencePeriodStatusType.Overdue.value;
  currentStatus: any = ReferencePeriodStatusType.Overdue.value;
  periodStatus: any = ReferencePeriodStatusType.Overdue.value;
  scheduleStatus: any = ReferencePeriodStatusType.Overdue.value;
  scheduledDays: number = 0;
  requestedDays: number = 0;
  scheduledAllowanceDays: number = 0;
  requestedAllowanceDays: number = 0;
  dateLimit: string = '';

  get statusBadge(): string {
    const status = ReferencePeriodStatusType.getBy(this.scheduleStatus);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  get periodStatusBadge(): string {
    const status = ReferencePeriodStatusType.getBy(this.periodStatus);

    return `<span class="badge bg-${status?.className} rounded-pill text-wrap">${status?.label}</span>`;
  }

  static create(input: any): ReferencePeriodVacationModel {
    return ObjectMapper.deserialize<ReferencePeriodVacationModel>(ReferencePeriodVacationModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
import { BaseEnum } from "./baseEnum";

const listPetitionsStatusEnum: Array<PetitionsStatusEnum> = [];

export class PetitionsStatusEnum extends BaseEnum {
	static PENDING = new PetitionsStatusEnum(0, "PENDING", "Pendente", "warning");
	static APPROVED = new PetitionsStatusEnum(
		1,
		"APPROVED",
		"Aprovado",
		"success"
	);
	static REFUSED = new PetitionsStatusEnum(2, "REFUSED", "Recusado", "danger");
	static CANCELED = new PetitionsStatusEnum(
		3,
		"CANCELED",
		"Cancelado",
		"secondary"
	);

	constructor(
		public id: number,
		public value: string,
		public label: string,
		public className: string
	) {
		super(id, value, label, className);
		listPetitionsStatusEnum.push(this);
	}

	public static buildListEnum(): Array<BaseEnum> {
		return listPetitionsStatusEnum;
	}
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BenefitTypeEnum } from "services/enums/benefitsType.enum";
import { HttpService } from "services/http.service";
import { ActiveBenefitDetailsModel } from "services/models/corporate/activeBenefitDetails.model";
import { BenefitModel } from "services/models/corporate/benefit.model";
import { BenefitEnableModel } from "services/models/corporate/benefitEnable.model";
import { BenefitPetitionDetailsModel } from "services/models/corporate/benefitPetitionDetails.model";
import { BenefitPlanModel } from "services/models/corporate/benefitPlan.model";
import { BenefitsEmployeeModel } from "services/models/corporate/benefitsEmployee";
import { PetitionDetailsModel } from "services/models/corporate/petitionDetails.model";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { ServiceBase } from "services/servicebase";

type BenefitsAvailablesOutput = {
	enables: BenefitEnableModel[];
	availables: BenefitModel[];
	inProgress: BenefitEnableModel[];
	availablesTypes: BenefitTypeEnum[];
};
@Injectable()
export class BenefitsEmployeeService extends ServiceBase<BenefitsEmployeeModel> {
	constructor(http: HttpService) {
		super("benefit", http, environment.apiUrl);
	}

	createEntity(input: any): BenefitsEmployeeModel {
		return BenefitsEmployeeModel.create(input);
	}

	getBenefits(
		params: QueryParams
	): Observable<Response<BenefitsEmployeeModel[]>> {
		const query = this.createBuildQuery(params);
		return this.http.get(`${this.endpoint}/employee/odata${query}`).pipe(
			map((res) => {
				const response = new Response<BenefitsEmployeeModel[]>();
				response.data = res.body.map((pp) => BenefitsEmployeeModel.create(pp));
				response.count = res.headers.get("x-count");
				return response;
			})
		);
	}

	postBenefits(data: any): Observable<any> {
		return this.http.post(`${this.endpoint}/petitions`, data);
	}

	getBenefitsAvailables(): Observable<BenefitsAvailablesOutput> {
		return this.http.get(`${this.endpoint}/employee/availables`).pipe(
			map((res) => {
				res.body.availables = res.body.availables.map((pp) =>
					BenefitModel.create({
						...pp,
						logo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
						logoFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
					})
				);

				res.body.enables = res.body.enables.map((pp) =>
					BenefitEnableModel.create({
						...pp,
						logo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
						logoFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
					})
				);

				res.body.inProgress = res.body.inProgress.map((pp) =>
					BenefitEnableModel.create({
						...pp,
						logo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
						logoFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logo}`,
					})
				);

				res.body.availablesTypes = res.body.availablesTypes.map((item) =>
					BenefitTypeEnum.getBy(item)
				) as BenefitTypeEnum[];

				return res.body as any;
			})
		);
	}

	getDetails(id: string): Observable<any> {
		return this.http
			.get(
				`${this.endpoint}/${id}/details`,
				undefined,
				undefined,
				undefined,
				true
			)
			.pipe(
				map((res) => {
					return res;
				})
			);
	}

	getBenefitsOfProviders(providersIds: string[]): Observable<BenefitModel[]> {
		const filterValues = providersIds.map((id) => `id eq ${id}`).join(" or ");

		const params = {
			select: [
				"id",
				"name",
				"businessName",
				"logoFile",
				"logoFileName",
				"description",
				"detailsFile",
				"detailsFileName",
				"type",
			],
			filter: filterValues ? `&$filter=${filterValues}` : undefined,
		} as QueryParams;

		const query = this.createBuildQuery(params);

		return this.http.get(`benefitprovider/odata${query}`).pipe(
			map((res) => {
				return res.body.map((pp) =>
					BenefitModel.create({
						...pp,
						logo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logoFile}`,
						logoFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.logoFile}`,
					})
				);
			})
		);
	}

	getPlansForEmployee(
		providerId: string,
		benefitType: number
	): Observable<BenefitPlanModel[]> {
		return this.http
			.get(
				`${this.endpoint}/plans/for-employee?providerId=${providerId}&type=${benefitType}`
			)
			.pipe(
				map((res) => {
					return res.body.map((pp) =>
						BenefitPlanModel.create({
							...pp,
							detailsFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.detailsFile}`,
						})
					);
				})
			);
	}

	getPlansForDependent(
		dependentId: string,
		providerId: string,
		benefitType: number
	): Observable<BenefitPlanModel[]> {
		return this.http
			.get(
				`${this.endpoint}/plans/for-dependent/${dependentId}?providerId=${providerId}&type=${benefitType}`
			)
			.pipe(
				map((res) => {
					return res.body.map((pp) =>
						BenefitPlanModel.create({
							...pp,
							detailsFile: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${pp.detailsFile}`,
						})
					);
				})
			);
	}

	getPetitionDetails(id: string): Observable<BenefitPetitionDetailsModel> {
		return this.http.get(`${this.endpoint}/petitions/${id}`).pipe(
			map((res) => {
				const data = res.body;

				return BenefitPetitionDetailsModel.create({
					...data,
					providerLogo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${data.providerLogo}`,
				});
			})
		);
	}

	getBenefitPetitionDetails(id: string): Observable<PetitionDetailsModel> {
		return this.http.get(`${this.endpoint}/petitions/${id}/details`).pipe(
			map((res) => {
				const data = res.body;

				return PetitionDetailsModel.create({
					...data,
					providerLogo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${data.providerLogo}`,
				});
			})
		);
	}

	getPetitionActivedDetails(
		benefitEmployeeId: string
	): Observable<BenefitPetitionDetailsModel> {
		return this.http
			.get(`${this.endpoint}/employee/${benefitEmployeeId}/details`)
			.pipe(
				map((res) => {
					const data = res.body;

					return BenefitPetitionDetailsModel.create({
						...data,
						providerLogo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${data.providerLogo}`,
					});
				})
			);
	}

	getActivedBenefitDetails(
		benefitEmployeeId: string
	): Observable<ActiveBenefitDetailsModel> {
		return this.http
			.get(`${this.endpoint}/employee/${benefitEmployeeId}/details`)
			.pipe(
				map((res) => {
					const data = res.body;

					return ActiveBenefitDetailsModel.create({
						...data,
						providerLogo: `https://rhgestao-public-files.s3.us-west-2.amazonaws.com/${data.providerLogo}`,
					});
				})
			);
	}

	updateStatusBenefit(data: any): Observable<any> {
		return this.http.post(`${this.endpoint}/petitions`, data);
	}

	cancelSolicitationPetition(id: string): Observable<any> {
		return this.http.put(`${this.endpoint}/petitions/cancel`, { id });
	}

	openDetailsFileOfPlan(id: string) {
		this.http.get(`${this.endpoint}/plans/${id}/details-file`).subscribe(
			({ body }) => {
				window.open(body, "_blank");
			},
			(error) => {
				console.log("error service", error);
			}
		);
	}
}

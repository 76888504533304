import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { HomeModel } from "./models/home.model";
import { CommunicationModel } from "./models/corporate/communication.model";
import { map } from "rxjs/operators";
import { ServiceBase } from "./servicebase";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Response } from "./models/response";
import { QueryParams } from "./models/odata/queryParams";
import { ObjectMapper } from "json-object-mapper";
import { WeatherModel } from "./models/weather.model";

@Injectable({
  providedIn: "root",
})
export class HomeService extends ServiceBase<CommunicationModel> {
  constructor(http: HttpService) {
    super("home", http, environment.apiUrl);
  }

  createEntity(input: any): CommunicationModel {
    return CommunicationModel.create(input);
  }

  getMy() {
    return this.http
      .get(`home/my`)
      .pipe(map((data: any) => HomeModel.create(data.body)));
  }

  getWeatherConditions(lat: number, lon: number) {
    return this.http
      .get(`home/weather-conditions/${lat}/${lon}`, null, null, null, true)
      .pipe(map((data: any) => WeatherModel.create(data.body)));
  }

  getNotice(id: string): Observable<any> {
    return this.http.get(`${this.endpoint}/notice/${id}`).pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  changeNoticeStatus(notice: CommunicationModel): Observable<Response<any>> {
    let _object = ObjectMapper.serialize(notice);
    return this.http.put(`${this.endpoint}/status.notice`, JSON.parse(_object.toString())).pipe(
      map((res) => {
        const response = new Response<any>();
        response.data = res;
        return response;
      })
    );
  }

  listNoticies(
    params: QueryParams = null
  ): Observable<Response<CommunicationModel[]>> {
    return this.customList<CommunicationModel>(
      params,
      `list.noticies`,
      CommunicationModel
    );
  }
}

import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "services/http.service";
import { OptionModel } from "services/models/model";
import { WorkforcePlanTeamEmployeeModel } from "services/models/workforceplan";
import { ServiceBase } from "services/servicebase";

@Injectable()
export class WorkforcePlanDrawerEmployeeService extends ServiceBase<WorkforcePlanTeamEmployeeModel> {
	constructor(http: HttpService) {
		super("WorkForcePlan", http, environment.apiUrl);
	}

	createEntity(input: any): WorkforcePlanTeamEmployeeModel {
		return WorkforcePlanTeamEmployeeModel.create(input);
	}

	getListResignationTypes(): Observable<OptionModel[]> {
		return this.http.get(`${this.endpoint}/list-resignation-reason`, null, null, null, true).pipe(
			map((res: any) => {
				return res.body.map(item => ({ value: item.id, label: item.description } as OptionModel));
			})
		);
	}

	createMovementRequest(id: string, payload: any): Observable<any> {
		return new Observable<any>((observer) => {
			this.http.post(`${this.endpoint}/${id}/movement-request`, payload, null, null, true).subscribe(
				(res) => {
					observer.next(res);
					observer.complete();
				},
				(err) => observer.error(err)
			);
		});
	}

	getListEmployeesInResignation(id: string): Observable<any[]> {
		return this.http.get(`${this.endpoint}/${id}/movement-request/employees-in-resignation`, null, null, null, true).pipe(
			map((res: any) => {
				return res.body;
			})
		);
	}

	updateMovementRequest(id: string, payload: any): Observable<any> {
		return new Observable<any>((observer) => {
			this.http.put(`${this.endpoint}/${id}/movement-request`, payload).subscribe(
				(res) => {
					observer.next(res);
					observer.complete();
				},
				(err) => observer.error(err)
			);
		});
	}
}

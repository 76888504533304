import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model, OptionModel } from "../model";
import { DocumentMockupTypeEnum } from "./document-mockup-type.model";
import type { DocumentMockupVariableModel } from "./documentmockupVariable.model";

export class DocumentMockupModel
  extends Model
  implements IModel<DocumentMockupModel>
{
  companyGroupId: string = "";
  companyId: string = "";
  content: string = "";
  title: string = "";
  variables: DocumentMockupVariableModel[] = [];
  jobTitleIds: any[] = [];
  costCenterIds: any[] = [];
  type: string = "";
  isDraft: string = "";

  public toOption(): OptionModel {
    return {
      label: this.title,
      value: this.id,
    } as OptionModel;
  }

  get typeLabel(): string {
    return DocumentMockupTypeEnum.getBy(this.type).label;
  }

  get statusLabel(): string {
    if (this.isDraft) {
      return `<i class="feather icon-file-text" title="Rascunho" ngbTooltip="Rascunho"></i>`;
    }

    return `<i class="feather icon-check-square text-success" title="Pronto" ngbTooltip="Pronto"></i>`;
  }

  static create(input): DocumentMockupModel {
    return ObjectMapper.deserialize<DocumentMockupModel>(
      DocumentMockupModel,
      input
    );
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}

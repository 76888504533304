import { ObjectMapper } from 'json-object-mapper';

export class BankModel {
  ispb: string = '';
  name: string = '';
  code: number = 0;
  fullName: string = '';

  static create(input: any): BankModel {
    return ObjectMapper.deserialize<BankModel>(BankModel, input);
  }
}

import { Injectable } from "@angular/core";
import f from "odata-filter-builder";
import buildQuery from "odata-query";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JobTitleDocumentModel } from "services/models/hiring/jobTitleDocument.model";
import type { OptionModel } from "services/models/model";
import { QueryParams } from "services/models/odata/queryParams";
import { Response } from "services/models/response";
import { WorkforcePlanJobTitleGroupModel } from "services/models/workforceplan/workforceplan-shift.model";
import { environment } from "../../../environments/environment";
import { HttpService } from "../http.service";
import { JobTitleModel } from "../models/hiring/jobTitle.model";
import { ServiceBase } from "../servicebase";

@Injectable()
export class JobTitleService extends ServiceBase<JobTitleModel> {
	constructor(http: HttpService) {
		super("jobtitle", http, environment.apiUrl);
	}

	createEntity(input: any): JobTitleModel {
		return JobTitleModel.create(input);
	}

	loadJobTitlesByTitle(
		companyId: string,
		char: string,
		disabledLoading = false
	): Observable<JobTitleModel[]> {
		const search = char || "";
		const filter = f()
			.and(f().eq("companyId", companyId).toString().replace(/'/g, ""))
			.and(f().contains("title", search).or(f().contains("code", search)))
			.toString();

		return new Observable<any>((observer) => {
			const params = {
				select: [
					"title",
					"description",
					"code",
					"salary",
					"companyId",
					"companyGroupId",
				],
				orderBy: "title",
				orderByOrder: "asc",
				filter: buildQuery({ filter }).replace("?", "&"),
			} as QueryParams;

			this.list(params, disabledLoading).subscribe((res) => {
				observer.next(res.data.filter((x) => x.enable));
			}),
				(err) => {
					observer.error(err);
				};
		});
	}

	loadJobTitlesByCompanyGroup(
		companyGroupId: string,
		char: string
	): Observable<JobTitleModel[]> {
		const search = char || "";
		const filter = f()
			.and(
				f().eq("companyGroupId", companyGroupId).toString().replace(/'/g, "")
			)
			.and(f().contains("title", search).or(f().contains("code", search)))
			.toString();

		return new Observable<any>((observer) => {
			this.list({
				select: [
					"title",
					"description",
					"code",
					"salary",
					"companyId",
					"companyName",
					"companyGroupId",
				],
				orderBy: "title",
				orderByOrder: "asc",
				filter: buildQuery({ filter }).replace("?", "&"),
			} as QueryParams).subscribe((res) => {
				observer.next(res.data.filter((x) => x.enable));
			}),
				(err) => {
					observer.error(err);
				};
		});
	}

	getFormDocuments(
		jobtitleId: string
	): Observable<Response<JobTitleDocumentModel[]>> {
		return this.http.get(`${this.endpoint}/${jobtitleId}/form-documents`).pipe(
			map((res) => {
				const response = new Response<JobTitleDocumentModel[]>();
				response.data = res.body.map(
					(pp) => JobTitleDocumentModel.create(pp) as JobTitleDocumentModel
				);
				response.count = res.headers.get("x-count");
				return response;
			})
		);
	}

	loadJobTitlesNotIn(
		jobTitlesId: string[],
		params: QueryParams,
		disableLoading: boolean = false
	): Observable<Response<JobTitleModel[]>> {
		const query = this.createBuildQuery(params);
		return this.http
			.post(
				`/${this.endpoint}/load-jobtitles-excluding-listeds${query}`,
				jobTitlesId,
				undefined,
				this.baseUrl,
				disableLoading
			)
			.pipe(
				map((res) => {
					const response = new Response<JobTitleModel[]>();
					response.data = res.map(
						(pp) => JobTitleModel.create(pp) as JobTitleModel
					);
					response.count = res.length;
					return response;
				})
			);
	}

	getJobTitleGroups() {
		return this.http
			.get(`${this.endpoint}/groups/odata`)
			.pipe(
				map((res) => {
					const response = new Response<OptionModel[]>();

					response.data = res.body.map(
						item => WorkforcePlanJobTitleGroupModel.create(item).toOption()
					)

					response.count = res.headers.get('x-count');

					return response;
				})
			);
	}

	getJobTitlesByTitleAndGroup(
		companyId: string,
		jobTitleGroupId: string,
		char: string = "",
		disabledLoading = true
	): Observable<JobTitleModel[]> {
		const search = char || "";
		const filter = f()
			.and(f().eq("companyId", companyId).toString().replace(/'/g, ""))
			.and(f().eq("jobTitleGroupId", jobTitleGroupId).toString().replace(/'/g, ""))
			.and(f().contains("title", search).or(f().contains("code", search)))
			.toString();

		return new Observable<any>((observer) => {
			const params = {
				select: [
					"title",
					"description",
					"code",
					"salary",
					"companyId",
					"companyGroupId",
				],
				orderBy: "title",
				orderByOrder: "asc",
				filter: buildQuery({ filter }).replace("?", "&"),
			} as QueryParams;

			this.list(params, disabledLoading).subscribe((res) => {
				observer.next(res.data.filter((x) => x.enable));
			}),
				(err) => {
					observer.error(err);
				};
		});
	}
}

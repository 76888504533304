import { ObjectMapper } from "json-object-mapper";
import { IModel } from "services/models/IModel";
import { Model } from "services/models/model";

export class WorkForcePlanJobTitleCountDTO {
	name: string = "";
	count: number = 0;
}

export class WorkforcePlanSummaryScaleModel extends Model implements IModel<WorkforcePlanSummaryScaleModel> {
	name: string = "";
	amountCurrent: WorkForcePlanJobTitleCountDTO[]
	amountVariance: WorkForcePlanJobTitleCountDTO[]
}

export class WorkforcePlanSummaryModel extends Model implements IModel<WorkforcePlanSummaryModel> {
	totalAllocated: number = 0;
	totalOnLeave: number = 0;
	totalInResignation: number = 0;
	maxCapacity: number = 0;
	usedCapacity: number = 0;
	capacityPercentage: number = 0;
	workScales: WorkforcePlanSummaryScaleModel[] = []
	workScalesGroups: WorkforcePlanSummaryScaleModel[] = []

	static create(input: any): WorkforcePlanSummaryModel {
		input.workScales = input.workScalesGroups;

		return ObjectMapper.deserialize<WorkforcePlanSummaryModel>(
			WorkforcePlanSummaryModel,
			input
		);
	}

	public toJSON(): string {
		return ObjectMapper.serialize(this) as string;
	}
}

import { ObjectMapper } from "json-object-mapper";
import { IModel } from "../IModel";
import { Model } from "../model";

export class BatimentoModel extends Model implements IModel<BatimentoModel> {
  name = "";
  dateTime: Date = new Date();
  sequential = 0;
  cpf = "";

  static create(input: any): BatimentoModel {
    return ObjectMapper.deserialize<BatimentoModel>(BatimentoModel, input);
  }

  public toJSON(): string {
    return ObjectMapper.serialize(this) as string;
  }
}
